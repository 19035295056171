<template>
  <!-- <PageHeader
        title="Templates"
        breadcrumb
        :items="[
            { label: 'Templates', to: '/settings' },
            { label: 'Breadcrumb 1', to: '/settings/users' },
            { label: 'Setting', to: '/settings/users/4' }
        ]"
    > -->
  <PageHeader title="Templates">
    <template #right>
      <ButtonGroup class="mr-5">
        <Button variant="outline-secondary">Button 1</Button>
        <Button variant="outline-secondary">Button 2</Button>
        <Button variant="outline-secondary">Button 3</Button>
      </ButtonGroup>
      <Button variant="primary">New</Button>
    </template>
  </PageHeader>
  <div class="content-wrapper">
    <div class="grid md:grid-cols-2 gap-8">
      <div>
        <Card>
          <CardBody>
            <CardTitle title="Spacing" subtitle="Sizes (overriding tailwind css)" />
            <!-- <div v-for="scale in [1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48]" class="flex">
                            <div class="h-5 bg-secondary mr-3" :class="'w-' + scale"></div>
                            <div>Width: {{ scale }}</div>
                        </div> -->
            <table width="100%">
              <thead class="text-secondary">
                <tr>
                  <td>Name</td>
                  <td>Size (14px Base)</td>
                  <td>Pixels</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>0.25rem</td>
                  <td>4px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-1"></div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>0.5rem</td>
                  <td>8px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-2"></div>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>0.75rem</td>
                  <td>12px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-3"></div>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>1rem</td>
                  <td>16px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-4"></div>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>1.25rem</td>
                  <td>20px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-5"></div>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>1.5rem</td>
                  <td>24px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-6"></div>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>1.75rem</td>
                  <td>28px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-7"></div>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>2rem</td>
                  <td>32px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-8"></div>
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>2.25rem</td>
                  <td>36px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-9"></div>
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>2.5rem</td>
                  <td>40px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-10"></div>
                  </td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>2.75rem</td>
                  <td>44px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-11"></div>
                  </td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>3rem</td>
                  <td>48px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-12"></div>
                  </td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>4rem</td>
                  <td>64px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-16"></div>
                  </td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>5rem</td>
                  <td>80px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-20"></div>
                  </td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>6rem</td>
                  <td>96px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-24"></div>
                  </td>
                </tr>
                <tr>
                  <td>32</td>
                  <td>8rem</td>
                  <td>128px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-32"></div>
                  </td>
                </tr>
                <tr>
                  <td>40</td>
                  <td>10rem</td>
                  <td>160px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-40"></div>
                  </td>
                </tr>
                <tr>
                  <td>48</td>
                  <td>12rem</td>
                  <td>192px</td>
                  <td>
                    <div class="h-5 bg-secondary mr-3 w-48"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle title="Sweet Alert" />
            <Button variant="danger" @click="showSweetAlert()">Delete Shift</Button>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle title="Buttons" />
            <div class="mb-8">
              <Button variant="primary" size="sm" class="mr-3">Small Button</Button>
              <Button variant="primary" size="md" class="mr-3">Medium Button</Button>
              <Button variant="primary" size="lg" class="mr-3">Large Button</Button>
            </div>
            <div>
              <div>
                <Button variant="success" class="mr-3">Success</Button>
                <Button variant="danger" class="mr-3">Danger</Button>
                <Button variant="primary" class="mr-3">Primary</Button>
                <Button variant="warning" class="mr-3">Warning</Button>
                <Button variant="info" class="mr-3">Info</Button>
                <Button variant="secondary" class="mr-3">Secondary</Button>
              </div>
              <div class="mt-5">
                <Button variant="outline-secondary" has-dropdown>Dropdown</Button>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle title="Notifications / Toasts" />
            <Button class="mr-3" @click="showAlertToast(1)">Success</Button>
            <Button class="mr-3" @click="showAlertToast(2)">Info</Button>
            <Button class="mr-3" @click="showAlertToast(3)">Danger</Button>
            <Button class="mr-3" @click="showAlertToast(4)">Warning</Button>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <FormGroup label="Vue MultiSelect - Multiple" label-for="select-multiple">
              <Multiselect
                v-model="multiselectValue"
                mode="single"
                label="name"
                :close-on-select="true"
                :searchable="true"
                :hide-selected="false"
                :options="multiselectOptions"
              >
                <template #singlelabel="{ value }">
                  <div class="multiselect-single-label"><Avatar :url="value.avatar" size="sm" class="mr-3" /> {{ value.name }}</div>
                </template>

                <template #option="{ option }"> <Avatar :url="option.avatar" size="sm" class="mr-3" /> {{ option.name }} </template>
              </Multiselect>
            </FormGroup>
            <pre class="language-json"><code>{{ multiselectValue }}</code></pre>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle title="Tooltip" />
            <button
              v-tippy="{
                content: 'Hello there!',
                placement: 'top',
                animation: 'perspective',
                delay: 500,
                theme: 'calendar-popout'
              }"
            >
              Hover me!
            </button>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle title="Text Sizes" />
            <div class="text-xs">Text XS</div>
            <div class="text-sm">Text SM</div>
            <div class="text-md text-primary">Text Base</div>
            <div class="text-lg">Text LG</div>
            <div class="text-xl">Text XL</div>
            <div class="text-2xl">Text 2XL</div>
            <div class="text-3xl">Text 3XL</div>
            <div class="text-4xl">Text 4XL</div>
          </CardBody>
        </Card>
      </div>
      <Card>
        <CardBody>
          <CardTitle title="Text Area" no-margin />
        </CardBody>
        <CardBody>
          <Label>Textarea with icon</Label>
          <TextArea icon="note" />
        </CardBody>
        <CardBody>
          <Label>Speech-To-Text</Label>
          <TextArea v-model="sttValue" has-speech-to-text />
        </CardBody>
        <CardBody>
          <SpeechToText @transcript-change="v => (sttValue = v)" />
          {{ sttValue }}
        </CardBody>
      </Card>
    </div>

    <div class="grid md:grid-cols-2 gap-8">
      <Card>
        <CardBody>
          <CardTitle title="Form" />
          <form class="md:w-80">
            <FormGroup label="Email" label-for="email">
              <Input id="email" v-model="emailInput" type="email" :has-error="false" />
              <InputError :has-error="false" error-text="Enter a valid Email Address" />
            </FormGroup>
            <FormGroup label="Password" label-for="password">
              <Input id="password" v-model="passwordInput" type="password" icon="lock" />
              <InputError :has-error="false" error-text="Password invalid" />
            </FormGroup>
            <FormGroup label="Description" label-for="description">
              <TextArea id="description" v-model="descriptionInput" :has-error="false" />
              <InputError :has-error="false" error-text="Enter a valid Description" />
            </FormGroup>
            <FormGroup label="Vue Select" label-for="select">
              <v-select id="select" v-model="selectInput" :options="['Canada', 'United States']"></v-select>
            </FormGroup>
            <FormGroup label="Vue Select - Multiple" label-for="select-multiple">
              <v-select
                id="select-multiple"
                v-model="multipleInput"
                :options="['Canada', 'United States', 'Mexico', 'United Kingdom']"
                multiple
              ></v-select>
            </FormGroup>
          </form>
        </CardBody>
      </Card>

      <div>
        <div class="relative">
          <Avatar size="lg" badge-content="34" :url="$cdnBaseUrl + '/img/profile-pics/Max_Resnikoff.jpg'" />
        </div>
        <br />
        <br />
        <Icon type="user" class="text-xl" badge-content="5" />
        <br />
        <br />
        <div class="relative">
          <Button variant="secondary">
            To Do list
            <Badge rounded>121</Badge>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import ButtonGroup from '@/components/button/ButtonGroup.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Badge from '@/components/badge/Badge.vue'
import Icon from '@/components/icon/Icon.vue'

import FormGroup from '@/components/forms/FormGroup.vue'
import TextArea from '@/components/forms/TextArea.vue'
import InputError from '@/components/forms/InputError.vue'
import Multiselect from '@vueform/multiselect'

import SpeechToText from '@/components/speech-to-text/SpeechToText.vue'

import { ref } from 'vue'
import notification from '../utilities/notification'
import Swal from 'sweetalert2'

export default {
  components: {
    PageHeader,
    Card,
    CardBody,
    CardTitle,
    ButtonGroup,
    FormGroup,
    TextArea,
    InputError,
    Multiselect,
    Avatar,
    Badge,
    Icon,
    SpeechToText
  },
  setup() {
    //Modal function
    const modalIsVisible = ref(false)

    const sttValue = ref('test default')

    const emailInput = ref('')
    const passwordInput = ref('')
    const descriptionInput = ref('')
    const selectInput = ref('')
    const multipleInput = ref('')

    const multiselectValue = ref([])
    const multiselectOptions = [
      {
        value: '1',
        name: 'Max Resnikoff',
        avatar: 'https://cdn.retainauto.com/img/profile-pics/Max-Resnikoff-1638268244-9997.jpg'
      },
      { value: '2', name: 'Emily Aves', avatar: 'https://cdn.retainauto.com/img/profile-pics/Max-Resnikoff-1638268244-9997.jpg' },
      { value: '3', name: 'Emma Taylor', avatar: 'https://cdn.retainauto.com/img/profile-pics/Max-Resnikoff-1638268244-9997.jpg' }
    ]
    function toggleModal() {
      // console.log('Modal open?: ' + modalIsVisible.value)
      modalIsVisible.value = !modalIsVisible.value
    }
    function submitModal() {
      // console.log('Modal was submitted')
      toggleModal()
    }

    function showAlertToast(example) {
      switch (example) {
        case 1:
          alertToast('Success Title', 'Success message goes here', 'success')
          break
        case 2:
          alertToast('Info Title', 'Info message goes here', 'info')
          break
        case 3:
          alertToast('Error Title', 'Error message goes here', 'error')
          break
        case 4:
          alertToast('Warning Title', 'Warning message goes here', 'warning')
          break

        default:
          break
      }
    }

    function showSweetAlert() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this shift!',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fa fa-trash"></i> Delete',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'var(--bg-tertiary)'
      }).then(function (response) {
        // console.log(response.isConfirmed)
        if (response.isConfirmed) {
          // console.log();
        }
      })
    }
    return {
      modalIsVisible,
      toggleModal,
      submitModal,
      emailInput,
      passwordInput,
      descriptionInput,
      selectInput,
      multipleInput,
      multiselectValue,
      multiselectOptions,
      showAlertToast: showAlertToast,
      showSweetAlert,
      sttValue
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.multiselect-user-option-avatar {
  margin: 0 6px 0 0;
  height: 22px;
}

.multiselect-user-label-avatar {
  margin: 0 6px 0 0;
  height: 26px;
}
</style>

<style></style>
