<template>
  <PageHeader :title="$t('ui.entities.campaign', 2)">
    <template #right>
      <!-- v-if="$can('create-campaigns')" -->
      <Button
        :label="$t('ui.crud.new', { item: $t('ui.entities.campaign') })"
        icon="plus"
        class="mr-3"
        @click="newCampaignModalIsOpen = true"
      />
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Campaigns"
      :value="campaignsData"
      removable-sort
      sort-mode="single"
      sort-field="name"
      :sort-order="1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['id', 'name', 'status']"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-campaigns-list"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="campaignsIsLoading"
    >
      <template #empty>
        <Empty title="No campaigns found" description="Select one or more locations">
          <template #buttons>
            <Button label="Select Locations" outlined @click="$router.push('/access')" />
          </template>
        </Empty>
      </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilter()"
            />
          </span>
        </div>
      </template>

      <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->
      <!-- <Column field="id" header="#" :sortable="true">
        <template #body="{ data }">
          <router-link class="link" :to="'/customers/campaigns/' + data.id">{{ data.id }}</router-link>
        </template>
      </Column> -->
      <Column field="name" header="Campaign Name" :sortable="true">
        <template #body="{ data }">
          <router-link class="font-semibold link-hover flex items-center" :to="'/customers/campaigns/' + data.id">
            {{ data.name }}
          </router-link>
          <!-- <div>
            <Chip v-for="dealership in data.dealerships" :key="dealership.id" size="sm">
              {{ dealership.name }}
            </Chip>
          </div> -->
        </template>
      </Column>
      <Column field="startDate" header="Start Date" :sortable="true">
        <template #body="{ data }">
          {{ dayjs(data.startDate).format('ll') }}
        </template>
      </Column>
      <Column field="endDate" header="End Date" :sortable="true">
        <template #body="{ data }">
          {{ dayjs(data.endDate).format('ll') }}
        </template>
      </Column>
      <Column
        field="status"
        header="Status"
        :sortable="true"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
        :filter-menu-style="{ 'max-width': '24rem' }"
      >
        <template #body="{ data }">
          <Chip v-if="data.status === 'Active'" label outlined size="sm" dot severity="success">Active</Chip>
          <Chip v-else label outlined size="sm" dot severity="danger">Ended</Chip>
        </template>
        <template #filter="{ filterModel }">
          <div class="mb-3 font-bold">Status</div>
          <MultiSelect v-model="filterModel.value" :options="['Active', 'Ended']" placeholder="Any" class="p-column-filter">
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="kpiData.totalCustomers" header="Customers" :sortable="true">
        <template #body="{ data }">
          <div class="flex">
            <div class="mr-3"><Icon type="users" class="mr-1" />{{ data.kpiData.totalCustomers }}</div>
            <!-- <div class="mr-3"><Icon type="phone" class="mr-1" />{{ data.kpiData.contacted }}</div>
            <div><Icon type="calendar" class="mr-2" />{{ data.kpiData.appointed }}</div> -->
          </div>
          <!-- <div>View More</div> -->
        </template>
      </Column>
      <Column field="usersAssigned" header="Assigned" :sortable="false">
        <template #body="{ data }">
          <AvatarGroup v-if="data.usersAssigned.length > 0">
            <Avatar v-for="user in data.usersAssigned" :key="user.id" :url="user.profilePicUrl" :tooltip="user.fullName" />
          </AvatarGroup>
        </template>
      </Column>
    </DataTable>
  </Card>

  <teleport to="#modals-container">
    <Modal
      :is-open="newCampaignModalIsOpen"
      title="New Campaign"
      icon="bullhorn"
      :buttons="[]"
      :has-unsaved-changes="newCampaignHasUnsavedChanges"
      :show-loader-on-confirm="true"
      :is-confirming="newCampaignModalIsSubmitting"
      max-width="500"
      overflow-behaviour="visible"
      @close-modal="(newCampaignModalIsOpen = false), (newCampaignHasUnsavedChanges = false)"
    >
      <NewCampaignForm
        @form-updated="newCampaignHasUnsavedChanges = true"
        @is-submitting="
          value => {
            newCampaignModalIsSubmitting = value
          }
        "
        @completed="onNewCampaignCreated"
      />
    </Modal>
  </teleport>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Card from '@/components/card/Card.vue'
import Chip from '@/components/chip/Chip.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import AvatarGroup from '@/components/avatar/AvatarGroup.vue'
import Modal from '@/components/modal/Modal.vue'
import Empty from '@/components/empty/Empty.vue'
import NewCampaignForm from '@/components/unique/forms/NewCampaign.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

// import alertToast from '@/utilities/alertToast'

import useApiRequest from '@/composables/useApiRequest'

const dt = ref({ processedData: [] })
const newCampaignModalIsOpen = ref(false)
const newCampaignModalIsSubmitting = ref(false)
const newCampaignHasUnsavedChanges = ref(false)

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.IN },
  status: { value: ['Active'], matchMode: FilterMatchMode.IN }
})

const clearFilter = () => {
  // console.log('Clearing Filter')
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.IN }
  }
}

const { isLoading: campaignsIsLoading, data: campaignsData, send: campaignsFetchData } = useApiRequest()

const dealershipIds = ref([])

onMounted(() => {
  dealershipIds.value = JSON.parse(localStorage.getItem('accessSelected')).dealerships.join()
  if (dealershipIds.value.length > 0) {
    campaignsFetchData({ endpoint: '/v1/campaigns?status=all&dealerships=' + dealershipIds.value })
  }
})

const onNewCampaignCreated = () => {
  newCampaignModalIsOpen.value = false
  newCampaignHasUnsavedChanges.value = false
  campaignsFetchData({ endpoint: '/v1/campaigns?status=all&dealerships=' + dealershipIds.value })
}
</script>

<style></style>
