<template>
  <img v-if="imageToRender" :src="imageToRender" :class="imageClass" @error="imgError()" />
  <Icon v-else type="question" class="text-quaternary" />
</template>

<script setup>
import { ref, watch } from 'vue'
import Icon from '@/components/icon/Icon.vue'

const props = defineProps({
  src: {
    type: String,
    default: null
  },
  fallbackSrc: {
    type: String,
    default: null
  },
  imageClass: {
    type: String,
    default: null
  }
})

const validImgUrl = ref(false)
const imageToRender = ref(null)

function validateImgUrl(src) {
  let validImgTypes = ['.jpg', '.jpeg', '.png', '.svg']

  if (src != null && validImgTypes.find(type => src.includes(type))) {
    validImgUrl.value = true
  } else {
    validImgUrl.value = false
  }
}

function imgError() {
  if (imageToRender.value == props.src) {
    imageToRender.value = props.fallbackSrc
  } else if (imageToRender.value == props.fallbackSrc) {
    console.error('Failed to load image with url: ' + imageToRender.value)
    imageToRender.value = null
  }
}

function checkImageToRender() {
  imageToRender.value = props.src
  validateImgUrl(props.src)

  if (!validImgUrl.value) {
    imageToRender.value = props.fallbackSrc
  }
}

checkImageToRender()

// Check img url when props change
watch(props, () => {
  validImgUrl.value = false
  checkImageToRender()
})
</script>

<style></style>
