<template>
  <div class="radio-boxed select-none" :class="[{ 'has-error': hasError }, size]">
    <div
      v-for="option in options"
      :key="option.value"
      v-tippy="option.disabled ? option.disabledTooltip || 'Unavailable' : ''"
      :for="option.value"
      class="option"
      :class="[{ active: option.value == modelValue, disabled: option.disabled }, option.classes]"
      @click="!option.disabled && optionClicked(option)"
    >
      <Icon v-if="option.icon" :type="option.icon" :fa-style="iconStyle || undefined" class="icon" />
      <div class="label">{{ option.label }}</div>
      <div v-if="option.description" class="description">{{ option.description }}</div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
export default {
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    selectedOption: {
      type: String,
      default: null
    },
    iconStyle: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  emits: ['optionChange', 'update:modelValue'],
  setup(props, { emit }) {
    function optionClicked(option) {
      emit('optionChange', option.value)
      emit('update:modelValue', option.value)
    }

    return { optionClicked }
  }
}
</script>

<style scoped>
.radio-boxed {
  display: flex;
}

.radio-boxed > .option {
  border-radius: var(--rounded-lg);
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-primary);
  position: relative;
}

.radio-boxed > .option > .description {
  font-size: var(--text-xs);
  color: var(--text-secondary);
  text-align: center;
  text-wrap: balance;
}

.radio-boxed.sm {
  gap: var(--s-2);
}
.radio-boxed.sm > .option {
  padding: 7px;
}
.radio-boxed.sm > .option > .label {
  font-size: var(--text-md);
}
.radio-boxed.sm > .option > .icon {
  font-size: 1.25rem;
}

.radio-boxed.md {
  gap: var(--s-4);
}
.radio-boxed.md > .option {
  padding: 10px;
}
.radio-boxed.md > .option > .label {
  font-size: var(--text-md);
}

.radio-boxed.md > .option > .icon {
  font-size: 1.5rem;
}

.radio-boxed.lg {
  gap: var(--s-5);
}

.radio-boxed.lg > .option {
  padding: 15px;
}

.radio-boxed.lg > .option > .label {
  font-size: var(--text-lg);
}
.radio-boxed.lg > .option > .icon {
  font-size: 1.75rem;
}

.option.active {
  background-color: white;
  border-color: transparent;
  outline: 2px solid var(--border-brand);
}
.option.active::after {
  content: '';
  position: absolute;
  top: var(--s-2);
  left: var(--s-2);
  border-radius: 100%;
  width: var(--s-5);
  height: var(--s-5);
  background-color: var(--bg-primary);
  border: var(--s-2) solid var(--border-brand-solid);
}
.dark .option.active {
  background-color: var(--darkmode-3);
}
.option.active > .label {
  color: var(--primary);
}
.option.active > .icon {
  color: var(--primary);
}
.option.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.radio-boxed.has-error > .option {
  color: var(--danger);
  border-color: var(--danger);
}

/*
.dark .option.active {
  background-color: var(--darkmode-3);
} */
</style>
