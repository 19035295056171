<template>
  <div v-click-away="clickAway" class="select-wrapper" @click="clickWrapper">
    <div
      class="form-control select-input !flex items-center justify-between whitespace-nowrap w-full"
      :class="{ expanded: dropdownIsExpanded, 'cursor-text': search, 'cursor-pointer': !search, 'has-error': hasError }"
      @click="toggleDropdown(true)"
    >
      <div v-if="search" class="relative">
        <div v-if="searchTerm == '' && modelValue != null" class="absolute left-0">
          <template v-if="$slots.value">
            <!-- Render the named slot if it exists -->
            <slot name="value" :value="modelValue"></slot>
          </template>
          <template v-else>
            <!-- Render default content if the named slot does not exist -->
            {{ modelValue[labelKey] }}
          </template>
        </div>
        <input ref="searchInput" v-model="localSearchTerm" class="w-full mr-4 h-full bg-transparent" />
      </div>
      <div v-else>&nbsp;</div>
      <div class="flex items-center">
        <Icon v-if="canClear && modelValue != null" type="xmark" class="mr-1 cursor-pointer" @click="clearSelection()" />
        <Icon type="chevron-down" />
      </div>
    </div>
    <div v-if="dropdownIsExpanded" class="select-dropdown-wrapper">
      <div v-if="items.length === 0" class="select-dropdown-placeholder">Type to search</div>
      <div
        v-for="(item, index) in items"
        :key="item"
        class="select-dropdown-item"
        :class="{ 'focused-item': index === focusedItemIndex }"
        @click="selectItem(item)"
      >
        <template v-if="$slots.option">
          <!-- Render the named slot if it exists -->
          <slot name="option" :option="item"></slot>
        </template>
        <template v-else>
          <!-- Render default content if the named slot does not exist -->
          {{ item.label }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import { ref, watch, computed } from 'vue'
import { onKeyStroke } from '@vueuse/core'

export default {
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    isObject: {
      //If the modelValue (v-model) is of type; object
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    search: {
      type: Boolean,
      default: false
    },
    searchTerm: {
      type: String,
      default: ''
    },
    handleSearch: {
      type: Boolean,
      default: true // if false, the search logic is expected to happen on the invoking parent
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    canClear: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:searchTerm', 'select'],
  setup(props, context) {
    const dropdownIsExpanded = ref(false)
    const localSearchTerm = ref(props.searchTerm)
    const selectedItemObject = ref(null)
    const searchInput = ref(null)

    const focusedItemIndex = ref(-1) // initialize with -1, no item is focused initially

    // Create a computed property for easier access to items array
    const computedItems = computed(() => props.items)

    // Focus the first item when the dropdown is opened
    watch(dropdownIsExpanded, isExpanded => {
      if (isExpanded && computedItems.value.length > 0) {
        focusedItemIndex.value = 0
      }
    })

    function toggleDropdown(shouldBeOpen) {
      dropdownIsExpanded.value = shouldBeOpen
    }

    function clickAway() {
      toggleDropdown(false)
    }

    function selectItem(item) {
      if (props.returnObject || props.isObject) {
        context.emit('update:modelValue', item)
      } else {
        context.emit('update:modelValue', item[props.valueKey])
      }

      context.emit('select', item[props.valueKey])
      toggleDropdown(false)

      if (props.search) {
        localSearchTerm.value = ''
        searchInput.value.blur()
      }
    }

    function clearSelection() {
      context.emit('update:modelValue', null)
    }

    watch(localSearchTerm, newSearchTerm => {
      context.emit('update:searchTerm', newSearchTerm)
    })

    function clickWrapper() {
      if (props.search) {
        searchInput.value.focus()
      }
    }

    // ArrowDown logic
    onKeyStroke('ArrowDown', e => {
      if (!dropdownIsExpanded.value) {
        return
      }
      e.preventDefault()
      if (focusedItemIndex.value < computedItems.value.length - 1) {
        focusedItemIndex.value++
      }
    })

    // ArrowUp logic
    onKeyStroke('ArrowUp', e => {
      if (!dropdownIsExpanded.value) {
        return
      }
      e.preventDefault()
      if (focusedItemIndex.value > 0) {
        focusedItemIndex.value--
      }
    })

    // Enter logic
    onKeyStroke('Enter', e => {
      if (!dropdownIsExpanded.value) {
        return
      }
      e.preventDefault()
      selectItem(computedItems.value[focusedItemIndex.value])
      focusedItemIndex.value = -1 // reset focus
    })

    // Escape logic
    onKeyStroke('Escape', e => {
      if (!dropdownIsExpanded.value) {
        return
      }
      e.preventDefault()
      toggleDropdown(false)
      focusedItemIndex.value = -1 // reset focus
    })

    return {
      focusedItemIndex,
      searchInput,
      clickWrapper,
      dropdownIsExpanded,
      localSearchTerm,
      toggleDropdown,
      clickAway,
      selectItem,
      clearSelection,
      selectedItemObject
    }
  }
}
</script>

<style>
.select-wrapper {
  position: relative;
}

.select-input.expanded {
  border: 1px solid var(--border-brand);
  box-shadow: var(--focus-ring-brand);
}

.select-dropdown-wrapper {
  background-color: var(--bg-primary_alt);
  border-radius: var(--rounded-md);
  box-shadow: var(--shadow-lg);
  margin-top: 10px;
  border: 1px solid var(--border-secondary);
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  /* top: 2rem; */
  left: 0;
  right: 0;
}

.select-dropdown-item {
  padding: var(--s-2) var(--s-3);
}

.select-dropdown-placeholder {
  padding: var(--s-2) var(--s-3);
  color: var(--text-secondary);
}

.select-dropdown-item:hover {
  background-color: var(--bg-primary_hover);
  cursor: pointer;
}

.focused-item {
  background-color: var(--bg-primary);
}
</style>
