<template>
  <PageHeader :title="'Valuation'" breadcrumb :items="[{ label: 'Valuations', to: '/vehicles/valuations' }, { label: '#' + state.id }]">
    <template #right>
      <router-link :to="'/agreement/' + state.agreementId + '/value'">
        <Button label="View Agreement" outlined severity="secondary" />
      </router-link>
    </template>
  </PageHeader>
  <Card>
    <CardBody class="flex items-center justify-between">
      <div>
        <span class="text-2xl">{{ statusText.title }}</span>
        <span class="text-secondary ml-2">{{ statusText.subTitle }}</span>
      </div>
      <div>
        <span v-if="state.updatedAt" class="text-secondary text-sm mr-4"
          >Last Updated: {{ toReadableDateTime(state.updatedAt, 'date') }}</span
        >
        <Button :label="state.partExchange.valuation ? 'Revalue Vehicle' : 'Value Vehicle'" @click="valueModalIsOpen = true" />
        <Button
          v-if="!state.partExchange.isDeclined"
          class="ml-2"
          label="Decline"
          outlined
          severity="danger"
          icon="xmark-large"
          @click="onDeclineClick"
        />
      </div>
    </CardBody>
  </Card>

  <div class="grid grid-cols-2 gap-8">
    <div>
      <Card>
        <CardBody>
          <div class="flex items-center justify-between gap-4">
            <div class="flex items-center gap-2 w-full">
              <div class="w-14 h-12 flex items-center">
                <img :src="state.vehicle.manufacturerLogoUrl" alt="Logo" class="max-h-full max-w-full" />
              </div>
              <div>
                <div class="text-xl font-medium">{{ state.vehicle.make }} {{ state.vehicle.model }}</div>
                <div class="text-secondary">{{ state.vehicle.derivative }}</div>
              </div>
            </div>
            <div>
              <LicensePlate :plate="state.vehicle.vrm" :country="'uk'" />
            </div>
          </div>
          <ListGroup class="mt-4">
            <ListGroupItem class="flex justify-between">
              <div>Registration Date</div>
              <div>{{ state.vehicle.registrationDate ? toReadableDateTime(state.vehicle.registrationDate, 'date') : '-' }}</div>
            </ListGroupItem>
            <ListGroupItem class="flex justify-between">
              <div>Imported</div>
              <div>{{ state.vehicle.isImported ?? '-' }}</div>
            </ListGroupItem>
            <ListGroupItem class="flex justify-between">
              <div>VIN</div>
              <div>{{ state.vehicle.vin ?? '-' }}</div>
            </ListGroupItem>
            <ListGroupItem v-if="state.vehicle.category != 'motorcycle'" class="flex justify-between">
              <div>Doors</div>
              <div>{{ state.vehicle.doors ?? '-' }}</div>
            </ListGroupItem>
            <ListGroupItem class="flex justify-between">
              <div>Previous Owners</div>
              <div>{{ state.vehicle.previousOwners ?? '-' }}</div>
            </ListGroupItem>
            <ListGroupItem class="flex justify-between">
              <div>Mileage</div>
              <div>{{ state.vehicle.odometer ? numberFormatter(state.vehicle.odometer) : '-' }}</div>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle title="Part-Exchange Images">
            <template #right>
              <Button label="Upload" outlined severity="secondary" icon="cloud-arrow-up" @click="openUploadImagesModal" />
            </template>
          </CardTitle>
          <Galleria
            v-model:activeIndex="activeIndex"
            v-model:visible="displayCustom"
            :value="state.images"
            :num-visible="7"
            container-style="max-width: 850px"
            :circular="true"
            :full-screen="true"
            :show-item-navigators="true"
            :show-thumbnails="true"
          >
            <template #item="slotProps">
              <img :src="slotProps.item" class="w-full max-h-screen block rounded-xl" />
            </template>
            <template #thumbnail="slotProps">
              <img :src="slotProps.item" style="display: block" />
            </template>
          </Galleria>

          <div v-if="state.images" class="grid grid-cols-4">
            <div
              v-for="(image, index) of state.images"
              :key="index"
              class="max-h-[100px] overflow-hidden m-1 rounded-lg hover:shadow-2xl hover:scale-[103%] transition duration-150 ease-in-out"
            >
              <img :src="image" class="cursor-pointer" @click="imageClick(index)" />
            </div>
          </div>
          <div v-else class="text-secondary">No images uploaded yet</div>
        </CardBody>
      </Card>
    </div>

    <div>
      <Card>
        <CardBody>
          <CardTitle title="Service" />
          <div class="grid grid-cols-2 gap-4 items-center">
            <div class="border border-light p-6 rounded flex flex-col items-center">
              <Icon type="book-open" class="text-3xl" />
              <div>Has Service History</div>
              <Icon v-if="state.vehicle.maintenance.hasServiceBook === null" type="circle-question" class="text-grey text-2xl" />
              <Icon v-else-if="state.vehicle.maintenance.hasServiceBook" type="circle-check" class="text-success text-2xl" />
              <Icon v-else type="circle-xmark" class="text-warning text-2xl" />
            </div>
            <div class="border border-light p-6 rounded flex flex-col items-center">
              <Icon type="car-wrench" class="text-3xl" />
              <div>Full Franchised Service</div>
              <Icon v-if="state.vehicle.maintenance.hasFullFranchisedService === null" type="circle-question" class="text-grey text-2xl" />
              <Icon v-else-if="state.vehicle.maintenance.hasFullFranchisedService" type="circle-check" class="text-success text-2xl" />
              <Icon v-else type="circle-xmark" class="text-warning text-2xl" />
            </div>
          </div>
          <ListGroup class="mt-4">
            <ListGroupItem class="flex justify-between">
              <div>Last Service</div>
              <div>{{ state.lastServiceDate ? toReadableDateTime(state.lastServiceDate, 'date') : '-' }}</div>
            </ListGroupItem>
            <ListGroupItem class="flex justify-between">
              <div>Last Service Mileage MOT</div>
              <div>{{ state.lastServiceMileage || '-' }}</div>
            </ListGroupItem>
          </ListGroup>

          <div v-if="state.countryCode === 'GB'" class="mt-6">
            <div class="flex items-center justify-between">
              <CardTitle title="MOT" />
              <div class="text-secondary text-sm">
                Last Synced:
                {{ state.vehicle.maintenance.motFetchedAt ? toReadableDateTime(state.vehicle.maintenance.motFetchedAt, 'date') : 'never' }}
              </div>
            </div>

            <ListGroup class="mt-0">
              <ListGroupItem class="flex justify-between">
                <div>Last MOT</div>
                <div>
                  {{ state.vehicle.maintenance.lastMotDate ? toReadableDateTime(state.vehicle.maintenance.lastMotDate, 'date') : '-' }}
                </div>
              </ListGroupItem>
              <ListGroupItem class="flex justify-between">
                <div>Next MOT</div>
                <div>
                  {{
                    state.vehicle.maintenance.nextMotDueDate ? toReadableDateTime(state.vehicle.maintenance.nextMotDueDate, 'date') : '-'
                  }}
                </div>
              </ListGroupItem>
            </ListGroup>

            <div v-for="test in state.vehicle.maintenance.motHistory" :key="test" class="my-6">
              <div class="flex">
                <Icon v-if="test.TestResult" type="circle-check" class="text-success text-2xl" />
                <div class="ml-2">
                  <div>
                    <div class="text-xl font-medium">Pass</div>
                    <div class="text-secondary text-sm">
                      {{ toReadableDateTime(dayjs(test.TestDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'date') }} |
                      {{ numberFormatter(test.OdometerReading) }}{{ test.OdometerUnit }}
                    </div>
                  </div>
                  <div v-for="advisory in test.AdvisoryNoticeList" :key="advisory" class="mt-1 flex">
                    <Icon type="circle" class="text-[0.4rem] mr-2 mt-[0.35rem] text-secondary" />{{ advisory }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  </div>

  <teleport to="#modals-container">
    <Modal
      icon="car"
      title="Value Vehicle"
      description="The value entered is not shared with the customer."
      :is-open="valueModalIsOpen"
      ok-text="Submit"
      close-text="Cancel"
      show-loader-on-confirm
      :is-confirming="submitValuationRequest.isLoading.value"
      @close-modal="onValueModalClose"
      @ok-modal="submitValuation()"
    >
      <FormGroup label="Value">
        <Input v-model="state.partExchange.valuation" />
      </FormGroup>
    </Modal>

    <Modal
      icon="camera"
      title="Upload Images"
      description="Upload images of the vehicle for valuation."
      :is-open="uploadImageModalIsOpen"
      ok-text="Submit"
      close-text="Cancel"
      show-loader-on-confirm
      :buttons="[]"
      :is-confirming="submitValuationRequest.isLoading.value"
      @close-modal="uploadImageModalIsOpen = false"
    >
      <FileUpload
        :header-title="null"
        :api-endpoint="uploadImagesApiEndpoint"
        :api-post-data="{ uploadedBy: userStore.details.id, uploadedByType: userStore.details.type == 1 ? 'retain' : 'dealer' }"
        :api-headers="{ Authorization: `Bearer ${sessionToken}` }"
        button-position="full-width"
        @cancel-all="uploadImageModalIsOpen = false"
        @uploaded="$nextTick(() => fetchValuation())"
      />
    </Modal>
  </teleport>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import Button from '@/components/button/Button.vue'

import Galleria from 'primevue/galleria'
import PageHeader from '@/components/page/PageHeader.vue'
import LicensePlate from '@/components/license-plate/LicensePlate.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
import Icon from '@/components/icon/Icon.vue'
import Modal from '@/components/modal/Modal.vue'

import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'

import { toReadableDateTime } from '@/utilities/timeFormatterUtil'
import numberFormatter from '@/scripts/numberFormatter'
import { computed } from 'vue'
import useApiRequest from '@/composables/useApiRequest'

import Swal from 'sweetalert2'
import notification from '@/utilities/notification.js'

import { useUserStore } from '@/stores/UserStore'
import FileUpload from '@/components/file-upload/FileUpload.vue'

export default {
  components: {
    Card,
    CardBody,
    CardTitle,
    Galleria,
    PageHeader,
    Button,
    LicensePlate,
    ListGroup,
    ListGroupItem,
    Icon,
    Modal,
    FormGroup,
    Input,
    FileUpload
  },
  setup() {
    const sessionToken = localStorage.getItem('token')
    const route = useRoute()
    const userStore = useUserStore()

    const apiRequest = useApiRequest()
    const declineApiRequest = useApiRequest()
    const submitValuationRequest = useApiRequest()

    const uploadImagesApiEndpoint = ref(null)

    const stateDefault = {
      id: null,
      type: 'part-exchange',
      agreementId: null,
      status: null,
      vehicle: {
        category: null,
        manufacturerLogoUrl: null,
        make: null,
        model: null,
        derivative: null,
        vrm: null,
        vin: null,
        doors: null,
        previousOwners: null,
        isImported: null,
        registrationDate: null,
        odometer: null,
        maintenance: {
          motFetchedAt: null,
          lastMotDate: null,
          nextMotDueDate: null,
          motHistory: null,
          taxDueDate: null,
          hasServiceBook: null,
          hasFullFranchisedService: null,
          lastServiceDate: null,
          lastServiceMileage: null
        }
      },
      isImageUploaded: false,
      images: null,
      capValuation: null,
      partExchange: {
        valuation: null,
        valuedBy: null,
        valuedByName: null,
        valuedAt: null,
        isDeclined: false,
        declinedBy: null,
        declinedByName: null
      },
      sale: null,
      settlementAmount: null,
      settlementUpdatedAt: null,
      countryCode: null,
      currencyCode: null,
      createdBy: null,
      createdByName: null,
      createdAt: null,
      updatedAt: null
    }

    const stateInit = ref({ ...stateDefault })
    const state = ref({ ...stateDefault })

    // Fetch valuation data from API
    function fetchValuation() {
      apiRequest
        .send({
          endpoint: `/v1/valuations/${route.params.id}`
        })
        .then(response => {
          stateInit.value = { ...response.data }
          state.value = { ...response.data }
          uploadImagesApiEndpoint.value = `${import.meta.env.VITE_API_BASE_URL}/v1/valuations/${state.value.id}/upload-images`
        })
    }
    fetchValuation()

    const statusText = computed(() => {
      if (state.value.partExchange.isDeclined) {
        return { title: 'Declined', subTitle: 'by ' + state.value.partExchange.declinedByName }
      }
      if (state.value.partExchange.valuation) {
        return {
          title: 'Valued at ' + numberFormatter(state.value.partExchange.valuation, 'currency', 0, state.value.currencyCode),
          subTitle: state.value.partExchange.valuedBy ? 'by ' + state.value.partExchange.valuedByName : null
        }
      }
      if (state.value.isImageUploaded) {
        return { title: 'Awaiting Valuation', subTitle: null }
      } else {
        return { title: 'Awaiting Images from Customer', subTitle: null }
      }
    })

    const activeIndex = ref(0)
    const displayCustom = ref(false)

    const imageClick = index => {
      activeIndex.value = index
      displayCustom.value = true
    }

    function onDeclineClick() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The customer may have spent time taking and uploading the images of their vehicle.',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Decline',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel'
      }).then(function (response) {
        if (response.isConfirmed) {
          declineApiRequest
            .send({
              endpoint: '/v1/valuations/' + route.params.id,
              method: 'PATCH',
              data: {
                isDeclined: true,
                declinedBy: userStore.details.id
              }
            })
            .then(response => {
              if (!response.success) {
                alertToast('Failed to decline', response.message, 'error')
              }
              alertToast('Valuation declined', null, 'success')
              //Re-fetch valuation data from API
              apiRequest
                .send({
                  endpoint: `/v1/valuations/${route.params.id}`
                })
                .then(response => {
                  stateInit.value = { ...response.data }
                  state.value = { ...response.data }
                })
            })
        }
      })
    }

    const valueModalIsOpen = ref(false)

    function onValueModalClose() {
      valueModalIsOpen.value = false
      state.value.partExchange.valuation = stateInit.value.partExchange.valuation
    }

    function submitValuation() {
      if (isNaN(state.value.partExchange.valuation)) {
        alertToast('Invalid value', 'Please enter a valid number', 'warning')
        return
      }

      submitValuationRequest
        .send({
          endpoint: '/v1/valuations/' + route.params.id,
          method: 'PATCH',
          data: {
            isDeclined: false,
            valuation: state.value.partExchange.valuation,
            valuedBy: userStore.details.id
          }
        })
        .then(response => {
          if (!response.success) {
            alertToast('Failed to submit', response.message, 'error')
          }
          alertToast('Valuation submitted', null, 'success')

          valueModalIsOpen.value = false

          //Re-fetch valuation data from API
          apiRequest
            .send({
              endpoint: `/v1/valuations/${route.params.id}`
            })
            .then(response => {
              stateInit.value = { ...response.data }
              state.value = { ...response.data }
            })
        })
    }

    const uploadImageModalIsOpen = ref(false)
    function openUploadImagesModal() {
      uploadImageModalIsOpen.value = true
    }

    return {
      sessionToken,
      userStore,
      state,
      statusText,
      uploadImagesApiEndpoint,
      activeIndex,
      displayCustom,
      imageClick,
      toReadableDateTime,
      onDeclineClick,
      valueModalIsOpen,
      submitValuation,
      submitValuationRequest,
      onValueModalClose,
      stateInit,
      uploadImageModalIsOpen,
      openUploadImagesModal,
      fetchValuation
    }
  }
}
</script>

<style></style>
