<template>
  <!-- {{ activeQueues }} -->
  <div class="p-5">
    <Card class="overflow-hidden">
      <TableBasic rounded-bottom rounded-top>
        <tbody>
          <tr class="text-left">
            <th>Queue ID</th>
            <th>Calls</th>
          </tr>
          <tr v-for="queue in activeQueues" :key="queue.queueID">
            <td>{{ queue.queueID }}</td>
            <td>{{ queue.calls }}</td>
          </tr>
        </tbody>
      </TableBasic>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/card/Card.vue'
import Icon from '@/components/icon/Icon.vue'
import TableBasic from '@/components/tables/TableBasic.vue'

import socket from '@/services/socket.service.js'

import { ref } from 'vue'

export default {
  components: {
    Card,
    Icon,
    TableBasic
  },
  setup() {
    let activeQueues = ref([])

    socket.on('phone-queues', queues => {
      activeQueues.value = queues
      // console.log(JSON.stringify(queues))
      // console.log(queues)
    })

    return { activeQueues }
  }
}
</script>

<style scoped></style>
