<template>
  <div class="audio-player">
    <audio ref="audio" :src="audioSrc" @timeupdate="updateProgress" @loadedmetadata="updateDuration" @ended="resetAudio"></audio>

    <div
      v-if="compact"
      v-tippy="formatTime(currentTime) + ' / ' + formatTime(duration)"
      class="audio-player--compact w-12 h-12 border-[1.5px] border-border-secondary rounded-full flex justify-center items-center"
      :style="[
        progressWidth > 0
          ? `background: conic-gradient(var(--fg-brand) 0% ${progressWidth}%, var(--bg-secondary) ${progressWidth + 1}%)`
          : ''
      ]"
    >
      <div class="w-9 h-9 inner-circle rounded-full flex justify-center items-center">
        <div class="flex w-full justify-center items-center hover:cursor-pointer" @click="togglePlay">
          <Icon v-if="!isPlaying" type="play" />
          <Icon v-else type="pause" />
        </div>
      </div>
    </div>

    <div v-else class="audio-player--full">
      <button @click="togglePlay">
        <Icon v-if="!isPlaying" type="play" />
        <Icon v-else type="pause" />
      </button>
      <div
        ref="progressContainer"
        class="progress-container"
        @click="scrub"
        @mousedown="startScrubbing"
        @mouseup="stopScrubbing"
        @mouseleave="stopScrubbing"
        @mousemove="mousemoveScrub"
      >
        <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
        <div class="progress-circle" :style="{ left: progressWidth + '%' }"></div>
      </div>
      <div class="time-info">
        <span>{{ formatTime(currentTime) }}</span>
        <span> / </span>
        <span>{{ formatTime(duration) }}</span>
      </div>
      <a :href="audioSrc" download target="_blank" rel="noopener noreferrer">
        <button v-if="canDownload" class="download-button">
          <Icon type="download" />
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import Icon from '@/components/icon/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    audioSrc: {
      type: String,
      required: true
    },
    canDownload: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const audio = ref(null)
    const isPlaying = ref(false)
    const currentTime = ref(0)
    const duration = ref(0)
    const progressContainer = ref(null)
    const isScrubbing = ref(false)

    const progressWidth = computed(() => (currentTime.value / duration.value) * 100)

    function togglePlay() {
      if (isPlaying.value) {
        audio.value.pause()
      } else {
        audio.value.play()
      }
      isPlaying.value = !isPlaying.value
    }

    function updateProgress() {
      if (!isScrubbing.value) {
        currentTime.value = audio.value?.currentTime
      }
    }

    function updateDuration() {
      duration.value = audio.value?.duration
    }

    function resetAudio() {
      currentTime.value = 0
      isPlaying.value = false
    }

    function formatTime(time) {
      const minutes = Math.floor(time / 60)
      const seconds = Math.floor(time % 60)
      return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    }

    function scrub(event) {
      const rect = progressContainer.value.getBoundingClientRect()
      const x = event.clientX - rect.left
      const scrubTime = (x / rect.width) * duration.value
      currentTime.value = scrubTime // updates progressWidth in real time
      audio.value.currentTime = scrubTime
    }

    function startScrubbing() {
      isScrubbing.value = true
      audio.value.pause()
    }

    function stopScrubbing(event) {
      if (isScrubbing.value) {
        isScrubbing.value = false
        if (isPlaying.value) {
          audio.value.play()
        }
      }
    }

    function mousemoveScrub(event) {
      if (isScrubbing.value) {
        scrub(event)
      }
    }

    return {
      audio,
      isPlaying,
      currentTime,
      duration,
      progressWidth,
      togglePlay,
      updateProgress,
      updateDuration,
      resetAudio,
      formatTime,
      progressContainer,
      scrub,
      startScrubbing,
      stopScrubbing,
      mousemoveScrub
    }
  }
}
</script>

<style scoped>
.audio-player--full {
  display: flex;
  align-items: center;
  background-color: var(--bg-primary);
  padding: 0.5rem;
  border-radius: var(--rounded-md);
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 0.5rem;
}

.progress-container {
  flex-grow: 1;
  height: 0.25rem;
  background-color: var(--utility-gray-200);
  margin-right: 0.5rem;
  position: relative;
  cursor: pointer;
}
.dark .progress-container {
  background-color: var(--darkmode-1);
}

.progress-bar {
  height: 100%;
  background-color: var(--fg-brand);
  position: absolute;
  left: 0;
}

.progress-circle {
  width: 1rem;
  height: 1rem;
  background-color: var(--fg-brand);
  position: absolute;
  top: -0.375rem;
  transform: translateX(-50%);
  border-radius: 50%;
  cursor: pointer;
}

.time-info {
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.download-button {
  padding: 0.25rem 0.5rem;
}

.audio-player--compact .inner-circle {
  background-color: var(--bg-primary);
}

.dark .audio-player--compact .inner-circle {
  background-color: var(--bg-tertiary);
}
</style>
