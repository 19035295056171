<template>
  <PageHeader
    :title="$t('ui.entities.report.dealership-target', 2)"
    breadcrumb
    :items="[{ label: $t('ui.entities.setting', 2), to: '/settings' }, { label: $t('ui.entities.report.dealership-target', 2) }]"
  >
    <template #right>
      <span>
        <DatePicker v-model="periodSelected" :config="periodSelectionConfig" :can-clear="false" />
      </span>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Dealership Targets"
      :value="targets"
      removable-sort
      :rows="100"
      data-key="dealershipId"
      column-resize-mode="fit"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-dealership-targets"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      edit-mode="cell"
      :loading="getTargetsApiRequest.isLoading.value"
      @cell-edit-complete="onCellEditComplete"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilter()"
            />
          </span>
        </div>
      </template>

      <Column
        field="dealershipName"
        header="Dealership"
        :sortable="true"
        filter-field="dealershipName"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
      >
        <template #body="{ data }">
          <div class="font-medium">{{ data.dealershipName }}</div>
        </template>
      </Column>

      <Column field="periodTargetsSet.activeCustomers" header="Book Size" :sortable="true" class="w-[15%] cursor-cell editable-cell">
        <template #body="{ data }">
          <div
            v-tippy="{
              content: data.periodTargetsSet.activeCustomers === null ? 'Default Target' : 'Default: ' + data.defaultTargets.activeCustomers
            }"
            :class="{ 'is-default-target': data.periodTargetsSet.activeCustomers === null }"
            class="inline-block p-2"
          >
            {{ data.periodTargets.activeCustomers }}
          </div>
          <Icon
            v-if="data.periodTargets.activeCustomers === null"
            v-tippy="'No targets'"
            type="triangle-exclamation"
            class="text-warning cursor-help"
          />
        </template>
        <template #editor="slotProps">
          <Input v-model="slotProps.data.periodTargetsSet.activeCustomers" />
        </template>
      </Column>

      <Column field="periodTargetsSet.calls" header="Calls" :sortable="true" class="w-[15%] cursor-cell editable-cell">
        <template #body="{ data }">
          <div
            v-tippy="{
              content: data.periodTargetsSet.calls === null ? 'Default Target' : 'Default: ' + data.defaultTargets.calls
            }"
            :class="{ 'is-default-target': data.periodTargetsSet.calls === null }"
            class="inline-block p-2"
          >
            {{ data.periodTargets.calls }}
          </div>
          <Icon
            v-if="data.periodTargets.calls === null"
            v-tippy="'No targets'"
            type="triangle-exclamation"
            class="text-warning cursor-help"
          />
        </template>
        <template #editor="slotProps">
          <Input v-model="slotProps.data.periodTargetsSet.calls" />
        </template>
      </Column>

      <Column field="periodTargetsSet.appointments" header="Appointments" :sortable="true" class="w-[15%] cursor-cell editable-cell">
        <template #body="{ data }">
          <div
            v-tippy="{
              content: data.periodTargetsSet.appointments === null ? 'Default Target' : 'Default: ' + data.defaultTargets.appointments
            }"
            :class="{ 'is-default-target': data.periodTargetsSet.appointments === null }"
            class="inline-block p-2"
          >
            {{ data.periodTargets.appointments }}
          </div>
          <Icon
            v-if="data.periodTargets.appointments === null"
            v-tippy="'No targets'"
            type="triangle-exclamation"
            class="text-warning cursor-help"
          />
        </template>
        <template #editor="slotProps">
          <Input v-model="slotProps.data.periodTargetsSet.appointments" />
        </template>
      </Column>

      <Column field="periodTargetsSet.attendances" header="Attended" :sortable="true" class="w-[15%] cursor-cell editable-cell">
        <template #body="{ data }">
          <div
            v-tippy="{
              content: data.periodTargetsSet.appointments === null ? 'Default Target' : 'Default: ' + data.defaultTargets.appointments
            }"
            :class="{ 'is-default-target': data.periodTargetsSet.appointments === null }"
            class="inline-block p-2"
          >
            {{ data.periodTargets.attendances }}
          </div>
          <Icon
            v-if="data.periodTargets.attendances === null"
            v-tippy="'No targets'"
            type="triangle-exclamation"
            class="text-warning cursor-help"
          />
        </template>
        <template #editor="slotProps">
          <Input v-model="slotProps.data.periodTargetsSet.attendances" />
        </template>
      </Column>

      <Column field="defaultTargets" header="" :sortable="false" class="py-0">
        <template #body="{ data }">
          <Button
            v-tippy="{ content: 'Edit Default Targets' }"
            severity="secondary"
            outlined
            icon="pencil"
            label="Defaults"
            @click="openModal(data)"
          />
        </template>
      </Column>
    </DataTable>
  </Card>

  <teleport to="#modals-container">
    <Modal
      title="Default Targets"
      description="These are populated into each month by default"
      icon="bullseye"
      :is-open="modalVisible"
      min-width="300"
      close-text="Cancel"
      ok-text="Save"
      @ok-modal="saveDefaultTargets"
      @close-modal="closeModal"
    >
      <div class="mt-5 flex gap-4">
        <FormGroup label="Book Size">
          <Input v-model="modalData.defaultTargets.activeCustomers" class="w-[100px]" />
        </FormGroup>
        <FormGroup label="Calls">
          <Input v-model="modalData.defaultTargets.calls" class="w-[100px]" />
        </FormGroup>
        <FormGroup label="Appointments">
          <Input v-model="modalData.defaultTargets.appointments" class="w-[100px]" />
        </FormGroup>
        <FormGroup label="Attendances">
          <Input v-model="modalData.defaultTargets.attendances" class="w-[100px]" />
        </FormGroup>
      </div>
    </Modal>
  </teleport>
</template>

<script setup>
import { ref, watch } from 'vue'

import dayjs from 'dayjs'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'

import useApiRequest from '@/composables/useApiRequest'
import alertToast from '@/utilities/alertToast'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import Modal from '@/components/modal/Modal.vue'
import DatePicker from '@/components/forms/DatePicker.vue'
import Icon from '@/components/icon/Icon.vue'

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'

const periodSelected = ref(dayjs().startOf('month').format('YYYY-MM-DD'))
const periodEnd = ref(dayjs().endOf('month').format('YYYY-MM-DD'))

const getTargetsApiRequest = useApiRequest()
const updateTargetsApiRequest = useApiRequest()

const targets = ref([])

function getTargets() {
  getTargetsApiRequest
    .send({
      endpoint: '/v1/targets/dealerships',
      method: 'GET',
      params: { start: periodSelected.value, end: periodEnd.value }
    })
    .then(response => {
      targets.value = response.data
    })
}

getTargets()

const periodSelectionConfig = {
  enableTime: false,
  allowInput: true,
  altInput: true,
  plugins: [
    new monthSelectPlugin({
      shorthand: true, //defaults to false
      dateFormat: 'Y-m-d', //defaults to "F Y"
      altFormat: 'F Y' //defaults to "F Y"
    })
  ]
}

//Watch for changes in periodSelected
watch(periodSelected, () => {
  console.log('Period Selected Changed')
  periodEnd.value = dayjs(periodSelected.value).endOf('month').format('YYYY-MM-DD')
  getTargets()
})

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  dealershipName: { value: null, matchMode: FilterMatchMode.IN }
})

const clearFilter = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipName: { value: null, matchMode: FilterMatchMode.IN }
  }
}

const onCellEditComplete = event => {
  let jsonData = {
    dealershipId: event.data.dealershipId,
    period: periodSelected.value,
    activeCustomers: event.data.periodTargetsSet.activeCustomers === '' ? null : event.data.periodTargetsSet.activeCustomers,
    calls: event.data.periodTargetsSet.calls === '' ? null : event.data.periodTargetsSet.calls,
    appointments: event.data.periodTargetsSet.appointments === '' ? null : event.data.periodTargetsSet.appointments,
    attendances: event.data.periodTargetsSet.attendances === '' ? null : event.data.periodTargetsSet.attendances
  }

  console.log('Updating Target: ', jsonData)

  // Push new targets to API
  try {
    updateTargetsApiRequest
      .send({
        endpoint: '/v1/targets/dealerships',
        method: 'PATCH',
        data: JSON.stringify(jsonData)
      })
      .then(response => {
        if (response.success) {
          getTargets()
        }
      })
  } catch (err) {
    if (err.response) {
      console.log(err.response.status)
      console.log(err.response.data)
    }
  }
}

// MODAL
let modalVisible = ref(false)
let modalData = ref({})

function openModal(data) {
  // console.log('Opening Modal')
  // console.log(data)
  modalData.value = data
  modalVisible.value = true
}
function closeModal() {
  // console.log('Closing Modal')
  modalVisible.value = false
}

function saveDefaultTargets() {
  console.log('Saving Default Targets')
  console.log(modalData.value)

  let jsonData = {
    dealershipId: modalData.value.dealershipId,
    activeCustomers: modalData.value.defaultTargets.activeCustomers === '' ? null : modalData.value.defaultTargets.activeCustomers,
    calls: modalData.value.defaultTargets.calls === '' ? null : modalData.value.defaultTargets.calls,
    appointments: modalData.value.defaultTargets.appointments === '' ? null : modalData.value.defaultTargets.appointments,
    attendances: modalData.value.defaultTargets.attendances === '' ? null : modalData.value.defaultTargets.attendances
  }

  // Push new targets to API
  try {
    updateTargetsApiRequest
      .send({
        endpoint: '/v1/targets/dealerships/defaults',
        method: 'PATCH',
        data: JSON.stringify(jsonData)
      })
      .then(response => {
        console.log(response)
        if (response.success) {
          alertToast('Updated', null, 'success')
          getTargets()
        } else {
          alertToast('Failed to update', response.data?.message, 'error')
        }
      })
  } catch (err) {
    if (err.response) {
      console.log(err.response.status)
      console.log(err.response.data)
    }
  }

  closeModal()
}
</script>

<style>
.is-default-target {
  color: var(--text-disabled);
  font-style: italic;
}
.editable-cell {
  cursor: cell;
}
.editable-cell:hover {
  background-color: var(--bg-secondary);
}
</style>
