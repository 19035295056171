<template>
  <div class="grid grid-cols-2 gap-4">
    <div>
      <Card>
        <CardBody>
          <CardTitle title="My Abilities" />
          <TableBasic size="sm" bordered>
            <thead>
              <tr class="text-left">
                <th>Ability</th>
                <th>Code</th>
                <th>Can</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="possibleAbility in possibleAbilities" :key="possibleAbility.code">
                <td class="whitespace-nowrap pr-5">{{ possibleAbility.text }}</td>
                <td class="whitespace-nowrap pr-5">{{ possibleAbility.code }}</td>
                <td>
                  <Icon
                    :type="can(possibleAbility.code) ? 'check' : 'times'"
                    :class="can(possibleAbility.code) ? 'text-success' : 'text-danger'"
                  />
                </td>
              </tr>
            </tbody>
          </TableBasic>
        </CardBody>
      </Card>
    </div>

    <div>
      <Card>
        <CardBody>
          PINIA UserStore: <br />
          <pre><code>Outstanding Todos: {{ userStore.outstandingTodos }}</code></pre>
        </CardBody>
      </Card>
    </div>

    <div class="col-span-2">
      <Card>
        <CardBody>
          <CardTitle title="My Access" />
          <TableBasic size="sm" bordered>
            <thead>
              <tr class="text-left">
                <th>Dealership</th>
                <th>Selected</th>
                <th>DCA</th>
                <th>Leads</th>
                <th>PH Leads</th>
                <th>MOTAB Leads</th>
                <th>Adv. Reports</th>
                <th>Switch</th>
                <th>Used Stock</th>
                <th>Auction</th>
                <th>ReLoop</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dealership in userStore.accessAllowed.dealerships" :key="dealership.id">
                <td class="whitespace-nowrap pr-5">{{ dealership.name }}</td>
                <td>
                  <Icon
                    :type="isDealershipSelected(dealership.id) ? 'check' : 'times'"
                    :class="isDealershipSelected(dealership.id) ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.data_change_acknowledge ? 'check' : 'times'"
                    :class="dealership.features.data_change_acknowledge ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.leads ? 'check' : 'times'"
                    :class="dealership.features.leads ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.leads_post_handover ? 'check' : 'times'"
                    :class="dealership.features.leads_post_handover ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.leads_motability ? 'check' : 'times'"
                    :class="dealership.features.leads_motability ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.advanced_reporting ? 'check' : 'times'"
                    :class="dealership.features.advanced_reporting ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.switch ? 'check' : 'times'"
                    :class="dealership.features.switch ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.used_stock ? 'check' : 'times'"
                    :class="dealership.features.used_stock ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.auction ? 'check' : 'times'"
                    :class="dealership.features.auction ? 'text-success' : 'text-danger'"
                  />
                </td>
                <td>
                  <Icon
                    :type="dealership.features.reloop ? 'check' : 'times'"
                    :class="dealership.features.reloop ? 'text-success' : 'text-danger'"
                  />
                </td>
              </tr>
            </tbody>
          </TableBasic>
        </CardBody>
      </Card>
    </div>
    <!-- <Card>
      <CardBody>
        <Button label="In Call" severity="danger" class="ml-2" @click="setUserStatus('inCall')" />
        <Button label="On Hold" severity="danger" class="ml-2" @click="setUserStatus('onHold')" />
        <Button label="Wrapping Up" severity="info" class="ml-2" @click="setUserStatus('wrapUp')" />
        <Button label="On Break" severity="warning" class="ml-2" @click="setUserStatus('onBreak')" />
        <Button label="Busy" severity="danger" class="ml-2" @click="setUserStatus('busy')" />
        <Button label="Reset" severity="secondary" class="ml-5" @click="setUserStatus(null)" />
      </CardBody>
    </Card> -->
    <!-- <div>
            <Card>
                <div class="flex chip-demo p-5">
                    <Chip>Default</Chip>
                    <Chip severity="primary">Primary</Chip>
                    <Chip severity="secondary">Secondary</Chip>
                    <Chip severity="success">Success</Chip>
                    <Chip severity="danger">Danger</Chip>
                    <Chip severity="warning">Warning</Chip>
                    <Chip severity="info">Info</Chip>
                </div>
            </Card>
            <Card>
                <div class="flex chip-demo p-5">
                    <Chip outlined>Default</Chip>
                    <Chip severity="primary" outlined>Primary</Chip>
                    <Chip severity="secondary" outlined>Secondary</Chip>
                    <Chip severity="success" outlined>Success</Chip>
                    <Chip severity="danger" outlined>Danger</Chip>
                    <Chip severity="warning" outlined>Warning</Chip>
                    <Chip severity="info" outlined>Info</Chip>
                </div>
            </Card>
            <Card>
                <div class="flex chip-demo p-5">
                    <Chip label>Default</Chip>
                    <Chip severity="primary" label>Primary</Chip>
                    <Chip severity="secondary" label>Secondary</Chip>
                    <Chip severity="success" label>Success</Chip>
                    <Chip severity="danger" label>Danger</Chip>
                    <Chip severity="warning" label>Warning</Chip>
                    <Chip severity="info" label>Info</Chip>
                </div>
            </Card>
            <Card>
                <div class="flex chip-demo p-5">
                    <Chip icon="plus">Default</Chip>
                    <Chip severity="primary" icon="plus">Primary</Chip>
                    <Chip severity="secondary" icon="user">Secondary</Chip>
                    <Chip severity="success" icon="check">Success</Chip>
                    <Chip severity="danger" icon="exclamation">Danger</Chip>
                    <Chip severity="warning" icon="warning">Warning</Chip>
                    <Chip severity="info" icon="info">Info</Chip>
                </div>
                <div class="flex chip-demo p-5">
                    <Chip icon="plus" label>Default</Chip>
                    <Chip severity="primary" icon="plus" label>Primary</Chip>
                    <Chip severity="secondary" icon="user" label>Secondary</Chip>
                    <Chip severity="success" icon="check" label>Success</Chip>
                    <Chip severity="danger" icon="exclamation" label>Danger</Chip>
                    <Chip severity="warning" icon="warning" label>Warning</Chip>
                    <Chip severity="info" icon="info" label>Info</Chip>
                </div>
            </Card>
            <Card>
                <div class="flex chip-demo p-5">
                    <Chip :avatar="$cdnBaseUrl + '/img/profile-pics/Max_Resnikoff.jpg'">Max Resnikoff</Chip>
                    <Chip :avatar="$cdnBaseUrl + '/img/profile-pics/Emma_Taylor.jpg'">Emma Taylor</Chip>
                </div>
            </Card>
        </div>
        <Card class="p-6">
            <Avatar :url="$cdnBaseUrl + '/img/profile-pics/Max_Resnikoff.jpg'"></Avatar>
            <Avatar :url="$cdnBaseUrl + '/img/profile-pics/Max_Resnikoff.jpg'"></Avatar>
        </Card>
        <Card class="p-6">
            <AvatarGroup>
                <Avatar :url="$cdnBaseUrl + '/img/profile-pics/Max_Resnikoff.jpg'"></Avatar>
                <Avatar :url="$cdnBaseUrl + '/img/profile-pics/Max_Resnikoff.jpg'"></Avatar>
            </AvatarGroup>
        </Card> -->
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useUserStore } from '@/stores/UserStore'

import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import TableBasic from '@/components/tables/TableBasic.vue'

import { useAbility } from '@casl/vue'

import Icon from '@/components/icon/Icon.vue'
import useApiRequest from '@/composables/useApiRequest'

export default {
  components: {
    Card,
    CardTitle,
    CardBody,
    TableBasic,
    Icon
  },
  setup() {
    const { can } = useAbility()
    const userStore = useUserStore()

    const allAbilities = ref([])

    useApiRequest()
      .send({ endpoint: '/v1/casl' })
      .then(response => {
        // console.log(response.data.permissions)

        // Loop through all fetched abilities and add them to allAbilites
        response.data.permissions.forEach(ability => {
          if (!allAbilities.value.some(a => a.code === ability)) {
            allAbilities.value.push({ text: ability.id, code: ability.name })
          }
        })
      })

    const possibleAbilities = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return allAbilities.value.sort((a, b) => a.code.localeCompare(b.code))
    })

    //Access
    const accessAllowed = userStore.accessAllowed
    const accessSelected = JSON.parse(localStorage.getItem('accessSelected'))

    const isDealershipSelected = dealershipId => {
      return accessSelected.dealerships.includes(dealershipId)
    }
    return {
      can,
      possibleAbilities,
      userStore,
      accessAllowed,
      isDealershipSelected
    }
  }
}
</script>

<style>
.chip-demo > .chip {
  margin-inline-end: 16px;
}
</style>
