import notification from '@/utilities/notification'

/**
 * Displays a notification toast with the given parameters.
 *
 * @param {string} title - The title of the notification.
 * @param {string} text - The text of the notification.
 * @param {string} severity - The severity of the notification. (info, success, warning, danger)
 */
export default function alertToast(title, text, severity, icon) {
  if (severity) {
    switch (severity) {
      case 'success':
        icon = 'check'
        break
      case 'info':
        icon = 'info'
        break
      case 'error':
        icon = 'exclamation'
        break
      case 'warning':
        icon = 'triangle-exclamation'
        break
      default:
        icon = 'bell'
        break
    }
  }

  notification({
    design: 'alert',
    title: title,
    text: text,
    icon: icon,
    severity: severity,
    showDismissButton: false,
    showActionButton: false,
    showCloseButton: false,
    position: 'top-center',
    hideProgressBar: false,
    timeout: 3000
  })
}
