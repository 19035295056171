<template>
  <div class="p-8">
    <ul>
      <li v-for="check in checks" :key="check.name" class="flex gap-2 mb-4">
        <Icon :type="getIconType(check.status)" fa-style="fas" :class="getIconClass(check.status)" />
        <div>
          <p class="text-lg">{{ check.name }}</p>
          <p class="text-md text-quaternary">{{ check.message }}</p>
        </div>
      </li>
    </ul>
    <Button class="mt-4" label="Copy Diagnostics Data" icon="copy" @click="copyHealthCheck" />
    <p v-if="copySuccess" class="mt-2 text-green-500">Health check data copied to clipboard!</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'
import { io } from 'socket.io-client'
import * as Sentry from '@sentry/vue'

const checks = ref([
  { name: 'API Connectivity', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'CDN Accessibility', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'Cookies Enabled', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'Token Presence', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'WebSockets', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'JavaScript Execution', status: 'pending', failureReasons: [], message: 'Pending...' },
  // { name: 'CORS', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'Local Storage', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'Client IP', status: 'pending', failureReasons: [], message: 'Pending...' },
  { name: 'User Agent', status: 'pending', failureReasons: [], message: 'Pending...' }
])

const copySuccess = ref(false)

function updateCheck(name, status, failureReasons = [], message = '') {
  const check = checks.value.find(c => c.name === name)
  if (check) {
    check.status = status
    check.failureReasons = failureReasons
    check.message = message
  }
}

function getIconType(status) {
  if (status === 'pass') return 'check-circle'
  if (status === 'fail') return 'times-circle'
  if (status === 'pending') return 'hourglass-half'
  return 'question-circle'
}

function getIconClass(status) {
  if (status === 'pass') return 'text-green-500'
  if (status === 'fail') return 'text-red-500'
  if (status === 'pending') return 'text-yellow-500'
  return 'text-gray-500'
}

onMounted(async () => {
  await checkApiConnectivity()
  await checkCdnAccessibility()
  await checkCookiesEnabled()
  await checkTokenPresence()
  await checkWebSockets()
  await checkJsExecution()
  await checkCors()
  await checkLocalStorage()
  await getClientIp()
  await getUserAgent()

  checkForFailures() // Check for failures after all tests
})

async function checkApiConnectivity() {
  try {
    const response = await fetch(import.meta.env.VITE_API_BASE_URL + '/healthcheck.php')
    if (response.status !== 200) {
      updateCheck('API Connectivity', 'fail', [], 'Failed: API is not accessible')
    } else {
      updateCheck('API Connectivity', 'pass', [], 'Success')
    }
  } catch (error) {
    updateCheck('API Connectivity', 'fail', [error.message], `Failed: ${error.message}`)
  }
}

async function checkCdnAccessibility() {
  try {
    const response = await fetch(import.meta.env.VITE_CDN_BASE_URL + '/healthcheck.php')
    if (response.status !== 200) {
      updateCheck('CDN Accessibility', 'fail', [], 'Failed: CDN is not accessible')
    } else {
      updateCheck('CDN Accessibility', 'pass', [], 'Success')
    }
  } catch (error) {
    updateCheck('CDN Accessibility', 'fail', [error.message], `Failed: ${error.message}`)
  }
}

function checkCookiesEnabled() {
  document.cookie = 'testcookie=1'
  if (document.cookie.indexOf('testcookie') !== -1) {
    updateCheck('Cookies Enabled', 'pass', [], 'Yes')
  } else {
    updateCheck('Cookies Enabled', 'fail', [], 'No')
  }
}

function checkTokenPresence() {
  const token = localStorage.getItem('token')
  if (token) {
    updateCheck('Token Presence', 'pass', [], 'Present')
  } else {
    updateCheck('Token Presence', 'fail', [], 'Not Found')
  }
}

function checkWebSockets() {
  return new Promise(resolve => {
    try {
      const socket = io('https://rtn.onl', {
        transports: ['websocket'],
        reconnectionAttempts: 1,
        timeout: 5000
      })

      socket.on('connect', () => {
        updateCheck('WebSockets', 'pass', [], 'Connected')
        // socket.disconnect()
        resolve()
      })

      socket.on('connect_error', error => {
        updateCheck('WebSockets', 'fail', [error.message], `Failed: ${error.message}`)
        resolve()
      })

      socket.on('error', error => {
        updateCheck('WebSockets', 'fail', [error.message], `Failed: ${error.message}`)
        resolve()
      })

      // Handle potential timeout
      setTimeout(() => {
        if (!socket.connected) {
          updateCheck('WebSockets', 'fail', ['Connection timed out'], 'Failed: Connection timed out')
          socket.disconnect()
          resolve()
        }
      }, 5000)
    } catch (error) {
      updateCheck('WebSockets', 'fail', [error.message], `Failed: ${error.message}`)
      resolve()
    }
  })
}

function checkJsExecution() {
  updateCheck('JavaScript Execution', 'pass', [], 'JavaScript is executing properly')
}

async function checkCors() {
  try {
    const response = await fetch('https://api.retainauto.com/cors-test.php', {
      method: 'GET',
      mode: 'cors'
    })
    if (response.status === 200) {
      updateCheck('CORS', 'pass', [], 'Success')
    } else {
      updateCheck('CORS', 'fail', [], 'Failed: CORS request failed')
    }
  } catch (error) {
    updateCheck('CORS', 'fail', [error.message], `Failed: ${error.message}`)
  }
}

function checkLocalStorage() {
  try {
    localStorage.setItem('test', '1')
    localStorage.getItem('test')
    updateCheck('Local Storage', 'pass', [], 'Accessible')
  } catch (error) {
    updateCheck('Local Storage', 'fail', [error.message], `Failed: ${error.message}`)
  }
}

async function getClientIp() {
  try {
    const response = await fetch('https://api.ipify.org?format=json')
    const data = await response.json()
    updateCheck('Client IP', 'pass', [], data.ip)
  } catch (error) {
    updateCheck('Client IP', 'fail', [error.message], `Failed: ${error.message}`)
  }
}

function getUserAgent() {
  updateCheck('User Agent', 'pass', [], navigator.userAgent)
}

function getSanitizedStorage(storage) {
  const data = {}
  for (let key in storage) {
    if (storage.hasOwnProperty(key)) {
      if (key.toLowerCase().includes('token') || key.toLowerCase().includes('password')) {
        continue // Skip sensitive keys
      }
      data[key] = storage.getItem(key)
    }
  }
  return data
}

function getCookieNames() {
  return document.cookie
    .split(';')
    .map(cookie => cookie.split('=')[0].trim())
    .filter(name => name)
}

function checkForFailures() {
  const anyFailures = checks.value.some(check => check.status === 'fail')

  if (anyFailures) {
    console.error('Health Check failed:', checks.value)
    // Gather diagnostic data
    const diagnosticData = {
      timestamp: new Date().toISOString(),
      url: window.location.href,
      userAgent: navigator.userAgent,
      ipAddress: checks.value.find(c => c.name === 'Client IP')?.message,
      checks: checks.value,
      localStorage: getSanitizedStorage(localStorage),
      sessionStorage: getSanitizedStorage(sessionStorage),
      cookieNames: getCookieNames()
    }

    // Send error to Sentry
    Sentry.captureException(new Error('Healthcheck Failed'), {
      extra: diagnosticData
    })
  }
}

function copyHealthCheck() {
  // Gather diagnostic data
  const diagnosticData = {
    timestamp: new Date().toISOString(),
    url: window.location.href,
    userAgent: navigator.userAgent,
    ipAddress: checks.value.find(c => c.name === 'Client IP')?.message,
    checks: checks.value,
    localStorage: getSanitizedStorage(localStorage),
    sessionStorage: getSanitizedStorage(sessionStorage),
    cookieNames: getCookieNames()
  }

  // Convert data to JSON string
  const diagnosticJson = JSON.stringify(diagnosticData, null, 2)

  // Copy to clipboard
  navigator.clipboard
    .writeText(diagnosticJson)
    .then(() => {
      copySuccess.value = true
      // Reset the success message after a few seconds
      setTimeout(() => {
        copySuccess.value = false
      }, 5000)
    })
    .catch(error => {
      console.error('Failed to copy health check data:', error)
      alert('Failed to copy health check data. Please try again.')
    })
}
</script>
