<!-- eslint-disable vue/no-v-html -->
<template>
  <li :id="commItem.id" class="comm-wrapper" :class="['comm-type-' + commItem.type, 'comm-direction-' + commItem.direction]">
    <div class="relative">
      <div
        class="bg-quaternary rounded-full w-6 h-6 flex justify-center items-center text-xs"
        style="position: absolute; bottom: -8px; right: 4px; z-index: 1"
      >
        <Icon :type="commItem.type === 'sms' ? 'message' : commItem.type === 'call' ? 'phone' : 'envelope'" />
      </div>
      <Avatar
        v-if="commItem.direction == 'outbound' && !commItem.wasAutomated"
        v-tippy="{ content: commItem.userName }"
        class="mx-3"
        :url="commItem.userProfilePicUrl"
        :text="commItem.userInitials"
      />
      <Avatar
        v-else-if="commItem.direction == 'outbound' && commItem.wasAutomated"
        v-tippy="{ content: 'Automated' }"
        class="mx-3"
        icon="robot"
      />
      <Avatar v-else v-tippy="{ content: 'Customer' }" class="mx-3" icon="user" />
    </div>
    <div ref="contentDiv" class="comm-container">
      <!-- SMS -->
      <div v-if="commItem.type == 'sms'" class="comm-content" :class="{ bot: commItem.wasAutomated }">{{ commItem.smsMessage }}</div>
      <!-- CALL -->
      <div v-else-if="commItem.type == 'call'" class="comm-content flex flex-col">
        <div class="flex items-center cursor-pointer" @click="isExpanded = !isExpanded">
          <div
            v-tippy="{ content: commItem.callOutcome }"
            :class="{
              'text-danger': commItem.callOutcome == 'Failed',
              'text-danger': commItem.callOutcome == 'No Answer',
              'text-success': commItem.callOutcome == 'Answered',
              'text-warning': commItem.callOutcome == 'Busy'
            }"
          >
            <Icon v-if="commItem.callOutcome == 'Failed'" type="phone-xmark" />
            <Icon v-else-if="commItem.callOutcome == 'No Answer'" type="phone-missed" />
            <Icon v-else-if="commItem.direction == 'inbound'" type="phone-arrow-down-left" />
            <Icon v-else-if="commItem.direction == 'outbound' && commItem.callOutcome == 'Answered'" type="phone-arrow-up-right" />
            <Icon v-else-if="commItem.direction == 'outbound' && commItem.callOutcome == 'Busy'" type="phone-arrow-up-right" />
            <Icon v-else-if="commItem.direction == 'outbound' && commItem.callOutcome == 'Left Voicemail'" type="voicemail" />
          </div>
          <span class="ml-3">{{ commItem.callOutcome }}</span>
          <!-- <span class="ml-3 capitalize">{{ commItem.direction }} Call</span> -->
          <span v-if="commItem.callOutcome != 'Left Voicemail'" class="ml-3 text-secondary text-sm">{{
            $dayjs.duration(commItem.callLength, 'seconds').format('mm:ss')
          }}</span>
          <!-- {{ $can('listen_to_call_recordings') && commItem.callRecordingUrl != '' }}
          {{ commItem.callTags?.length > 0 }}
          {{ commItem.callEmotion != null }} -->
          <Icon
            v-if="
              ($can('listen_to_call_recordings') && commItem.callRecordingUrl != '') ||
              commItem.callTags?.length > 0 ||
              commItem.callEmotion != null
            "
            class="ml-3"
            :type="isExpanded ? 'chevron-up' : 'chevron-down'"
          />
        </div>

        <div
          v-if="
            isExpanded &&
            (($can('listen_to_call_recordings') && commItem.callRecordingUrl != '') ||
              commItem.callTags?.length > 0 ||
              commItem.callEmotion != null)
          "
          class="mt-2"
        >
          <AudioPlayer
            v-if="$can('listen_to_call_recordings') && commItem.callRecordingUrl != ''"
            :audio-src="commItem.callRecordingUrl"
            :can-download="$can('download_call_recordings')"
            class="mb-3"
            style="min-width: 350px"
          />
          <!-- <Icon v-else v-tippy="{ content: 'You don\'t have permission to listen to calls' }" type="volume-slash"></Icon> -->
          <div v-if="commItem.callTags?.length > 0 || commItem.callEmotion != null" class="flex justify-between">
            <div>
              <!-- <div v-tippy="{ content: 'Reason for Call' }">{{ commItem.callReason }}</div> -->
              <Chip v-for="tag in commItem.callTags" :key="tag" size="sm" class="mr-1">{{ tag }}</Chip>
            </div>
            <div>
              <Icon
                v-tippy="{ content: getEmotionEmoji(commItem.callEmotion)?.tooltip }"
                :type="getEmotionEmoji(commItem.callEmotion)?.icon"
                :class="['text-' + getEmotionEmoji(commItem.callEmotion)?.color]"
                class="text-xl"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- EMAIL -->
      <div
        v-else-if="commItem.type == 'email'"
        class="comm-content text-md"
        :class="{ 'mt-4': commItem.emailInReplyTo != '' }"
        style="min-width: 350px"
      >
        <div v-if="commItem.emailInReplyTo != ''" class="email-in-reply-to">
          <Icon type="reply" />
          Replying to:
          <span class="link cursor-pointer" @click="scrollToInReplyTo(commItem.emailInReplyTo)">{{ commItem.emailSubject }}</span>
        </div>
        <div v-if="commItem.emailInReplyTo == ''" class="text-quaternary font-medium mb-2">Subject: {{ commItem.emailSubject }}</div>

        <div class="overflow-hidden" :class="[!isExpanded ? 'max-h-[170px]' : '']">
          <span v-html="commItem.emailBody"></span>
        </div>

        <div v-if="isLongContent" class="email-show-more text-sm text-secondary" :class="{ expanded: isExpanded }">
          <div v-if="!isExpanded" class="cursor-pointer hover:text-primary" @click="isExpanded = true">
            Show More <Icon type="chevron-down" fa-style="fas" />
          </div>
          <div v-else class="cursor-pointer hover:text-primary" @click="isExpanded = false">
            Show Less <Icon type="chevron-up" fa-style="fas" />
          </div>
        </div>
        <Button
          v-if="commItem.direction == 'inbound'"
          label="Reply"
          severity="secondary"
          outlined
          class="mt-3"
          @click="replyTo(commItem)"
        />
      </div>
      <!-- FOOTER -->
      <div class="comm-footer">
        <span v-if="commItem.direction == 'inbound'">
          <Icon v-if="commItem.isRead" type="check" class="mr-2 text-brand-solid" />
          <!-- <span v-else class="link mr-2 font-medium cursor-pointer" @click="markAsRead(commItem)">Mark as Read</span> -->
          <span v-tippy="{ content: 'To: ' + commItem.to }" class="mr-2">{{ commItem.from }}</span>
        </span>
        <span v-else v-tippy="{ content: 'From: ' + commItem.from }" class="mr-2">{{ commItem.to }}</span>
        <span v-tippy="{ content: $dayjs(commItem.createdAt).format('DD/MM/YYYY H:mm') }">{{ $dayjs().to(commItem.createdAt) }}</span>
      </div>
    </div>
  </li>
</template>

<script>
import { ref, onMounted } from 'vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'

import AudioPlayer from '@/components/audio-player/AudioPlayer.vue'

export default {
  components: {
    Avatar,
    Icon,
    AudioPlayer,
    Button,
    Chip
  },
  props: {
    commItem: {
      type: Object,
      default: null
    }
  },
  emits: ['replyTo', 'scrollToInReplyTo'],
  setup(props, { emit }) {
    const contentDiv = ref(null)

    //Expanding of container (Phone)
    const isExpanded = ref(false)

    //Long content accordion (emails)
    const isLongContent = ref(false)

    onMounted(() => {
      if (contentDiv.value.offsetHeight > 200) {
        isLongContent.value = true
      }
    })

    function markAsRead(commItem) {
      // TODO: Add API call to mark as read
      commItem.isRead = true
    }

    function replyTo(item) {
      emit('replyTo', item)
    }

    function scrollToInReplyTo(inReplyTo) {
      emit('scrollToInReplyTo', inReplyTo)
    }

    const ratingItems = [
      {
        icon: 'face-angry',
        tooltip: 'Angry',
        color: 'danger',
        value: 1
      },
      {
        icon: 'face-frown-slight',
        tooltip: 'Unhappy',
        color: 'warning',
        value: 2
      },
      {
        icon: 'face-meh',
        tooltip: 'Neutral',
        color: 'secondary',
        value: 3
      },
      {
        icon: 'face-smile',
        tooltip: 'Content',
        color: 'success',
        value: 4
      },
      {
        icon: 'face-awesome',
        tooltip: 'Extatic',
        color: 'success',
        value: 5
      }
    ]

    function getEmotionEmoji(value) {
      const item = ratingItems.find(item => item.value === value)
      return item
    }

    return { contentDiv, isLongContent, isExpanded, markAsRead, replyTo, scrollToInReplyTo, getEmotionEmoji }
  }
}
</script>

<style>
.comm-wrapper {
  margin: var(--s-6) var(--s-4);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: var(--text-md);
}
.comm-wrapper.comm-direction-outbound {
  justify-content: end;
  flex-direction: row-reverse;
}
.comm-container {
  max-width: 80%;
  display: flex;
  flex-direction: column;
}
.comm-wrapper.comm-direction-inbound .comm-container {
  align-items: start;
}
.comm-wrapper.comm-direction-outbound .comm-container {
  align-items: end;
}
.comm-content {
  border: 1px solid var(--border-secondary);
  border-radius: var(--rounded-xl);
  padding: var(--s-2) var(--s-3);
  position: relative; /* Add this line */
  background-color: var(--bg-secondary);
  width: fit-content;
  white-space: pre-wrap;
}
.comm-footer {
  font-size: var(--text-sm);
  color: var(--text-quaternary);
  margin-top: var(--s-1);
}
.comm-direction-inbound .comm-footer {
  text-align: left;
}
.comm-direction-outbound .comm-footer {
  text-align: right;
}

/* Type-specific styling */
/* Call */
.comm-type-call .comm-content {
  background-color: var(--bg-primary);
}

/* SMS */
.comm-type-sms.comm-direction-inbound .comm-content {
  border-color: var(--border-secondary);
}
.comm-type-sms.comm-direction-outbound .comm-content {
  /* background-color: var(--bg-brand-solid); */
  /* color: white; */
}
.comm-type-sms.comm-direction-outbound .comm-content.bot {
  background-color: var(--bg-primary);
  /* color: var(--text-quaternary); */
  max-width: 400px;
}
.comm-type-sms.comm-direction-inbound .comm-content {
  border-top-left-radius: 0;
}
.comm-type-sms.comm-direction-outbound .comm-content {
  border-top-right-radius: 0;
}

/* Email */
.comm-type-email {
  position: relative;
}
.comm-type-email .email-in-reply-to {
  position: absolute;
  top: -20px;
  white-space: nowrap;
  color: var(--text-secondary);
}
.comm-type-email.comm-direction-inbound .email-in-reply-to {
  left: 0;
}
.comm-type-email.comm-direction-outbound .email-in-reply-to {
  right: 0;
}
.email-show-more {
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0) 0px, var(--bg-secondary) 68px);
  height: 90px;
  margin-top: -90px;
  display: flex;
  align-items: flex-end;
}
.email-show-more.expanded {
  height: 30px;
  margin-top: 0px;
}
</style>
