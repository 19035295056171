<template>
  <!-- <pre>{{ JSON.stringify(socketUsers.usersPresence, null, 2) }}</pre> -->
  <Card class="overflow-hidden m-10">
    <TableBasic rounded-bottom rounded-top>
      <tbody>
        <tr class="text-left">
          <th>Dealership</th>
          <th>Number</th>
          <th>Agents Able</th>
        </tr>
        <tr v-for="dealership in dealershipsList" :key="dealership.id">
          <td>{{ dealership.displayName }}</td>
          <td>
            <span v-if="dealership.assignedPhoneNumber">
              +{{ dealership.assignedPhoneNumber.countryCode }} {{ dealership.assignedPhoneNumber.number }}
            </span>
          </td>
          <td>{{ dealership.agentsAble }}</td>
          <!-- <td>{{ dealership }}</td> -->
        </tr>
      </tbody>
    </TableBasic>
  </Card>
</template>

<script>
import Card from '@/components/card/Card.vue'
import TableBasic from '@/components/tables/TableBasic.vue'

import { useUserStore } from '../../stores/UserStore'
import { computed } from 'vue'

import useApiRequest from '@/composables/useApiRequest'

export default {
  components: {
    Card,
    TableBasic
  },
  setup() {
    const socketUsers = useUserStore()
    const dealershipsRequest = useApiRequest()

    dealershipsRequest.send({
      endpoint: '/v1/dealerships',
      params: {
        showAll: true,
        showDisabled: true
      }
    })

    const dealershipsList = computed(() => {
      // loop through all dealerships, and find the users (from store), that have the looped dealershipId in the user store 'dealerships' array
      let dealerships = dealershipsRequest.data.value
      let users = socketUsers.usersPresence

      if (!dealerships || !users) return []

      // Loop through each dealership, and find the users that have the dealershipId in their 'dealerships' array. Each dealership should then have a 'agentsAble' property, which is an array of the userIds that have the dealershipId in their 'dealerships' array
      dealerships.forEach(dealership => {
        let agentsAble = []
        users.forEach(user => {
          if (user.dealerships?.includes(dealership.id)) {
            agentsAble.push(user.uid)
          }
        })
        dealership.agentsAble = agentsAble
      })
      return dealerships
    })

    return { dealershipsList }
  }
}
</script>

<style scoped></style>
