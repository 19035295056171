<template>
  <div class="flex" :class="'switch--' + size">
    <div
      :class="[
        'toggle-switch',
        'toggle-switch--' + size,
        { 'toggle-switch--on': modelValue, 'toggle-switch--disabled': disabled, 'toggle-switch--readonly': readonly }
      ]"
      @click="toggle"
    >
      <div :class="['toggle-switch__slider', { 'toggle-switch__slider--on': modelValue }]"></div>
    </div>
    <div v-if="label || description">
      <div v-if="label" class="flex items-center switch-label-spacing">
        <div class="font-medium leading-7">{{ label }}</div>
        <Chip v-if="chipLabel" :size="size == 'sm' ? 'xs' : 'sm'" :severity="chipSeverity">{{ chipLabel }}</Chip>
      </div>
      <slot v-else name="label"></slot>

      <div v-if="description" class="text-tertiary max-w-[500px]">{{ description }}</div>
      <slot v-else name="description"></slot>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'

import Chip from '@/components/chip/Chip.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: null
  },
  chipLabel: {
    type: String,
    default: null
  },
  chipSeverity: {
    type: String,
    default: 'secondary'
  },
  description: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'md'
  }
})

const emit = defineEmits(['update:modelValue', 'change'])

const toggle = () => {
  if (!props.disabled && !props.readonly) {
    emit('update:modelValue', !props.modelValue)
    emit('change', !props.modelValue)
  }
}

watch(
  () => props.modelValue,
  newValue => {
    emit('update:modelValue', newValue)
  }
)
</script>

<style>
.switch--md {
  gap: var(--s-4);
}
.switch--sm {
  gap: var(--s-3);
  font-size: var(--text-sm);
}
.switch--md .switch-label-spacing {
  gap: var(--s-4);
}
.switch--sm .switch-label-spacing {
  gap: var(--s-2);
}
.toggle-switch {
  position: relative;
  width: 44px;
  height: 24px;
  background-color: var(--bg-tertiary);
  border-radius: var(--rounded-full);
  cursor: pointer;
  transition: background-color 0.3s;
}
.toggle-switch--sm {
  width: 36px;
  height: 20px;
}

.toggle-switch--disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.toggle-switch--readonly {
  cursor: not-allowed;
  opacity: 0.6;
}

.toggle-switch__slider {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--fg-white);
  border-radius: var(--rounded-full);
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
  box-shadow: var(--shadow-sm);
}
.toggle-switch--sm .toggle-switch__slider {
  width: 16px;
  height: 16px;
}

.toggle-switch--on {
  background-color: var(--bg-brand-solid);
}

.toggle-switch__slider--on {
  transform: translateX(20px);
}
.toggle-switch--sm .toggle-switch__slider--on {
  transform: translateX(16px);
}
</style>
