<template>
  <PageHeader
    title="Cancelled & No-Show"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      {
        label: $t('ui.entities.appointment.status.cancelled') + ' & ' + $t('ui.entities.appointment.status.no-show'),
        to: '/reports/appointments/cancelled-and-no-show'
      }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <div class="px-4 border border-secondary rounded-md flex items-center gap-2 h-[35px] whitespace-nowrap">
          <div>Based on date</div>
          <Dropdown>
            <template #triggerContent>
              <div class="link">{{ dateBasis }} <Icon type="chevron-down" /></div>
            </template>
            <DropdownItem @click="dateBasisChange('occurring')">Occurring</DropdownItem>
            <DropdownItem @click="dateBasisChange('created')">Created</DropdownItem>
          </Dropdown>
        </div>
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          position="bottom-right"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Appointments Cancelled & No-Show"
      :value="appointments"
      removable-sort
      sort-field="dealershipId"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipId', 'dealershipName']"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-appointment-cancelled-and-no-show"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="appointmentsIsLoading"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div>{{ data.dealershipName }}</div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="dealershipName" hidden />

      <Column field="appointments.total" header="Total Appointments" :sortable="true">
        <template #body="{ data }">
          <a
            class="link"
            :href="`/reports/appointments/list?dateBasis=${dateBasis}&dateRange=${dateRange}&dealerships=${data.dealershipId}`"
            >{{ data.appointments.total }}</a
          >
        </template>
      </Column>

      <Column field="appointments.cancelled" header="Cancelled" :sortable="true">
        <template #body="{ data }">
          <a
            class="link"
            :href="`/reports/appointments/list?dateBasis=${dateBasis}&dateRange=${dateRange}&dealerships=${data.dealershipId}&status=cancelled`"
            >{{ data.appointments.cancelled }}</a
          >
        </template>
      </Column>

      <Column field="conversionRates.totalVsCancelled" :sortable="true">
        <template #header>
          <div>%</div>
          <Icon v-tippy="{ content: 'Cancelled rate' }" type="circle-info" fa-style="far" />
        </template>
        <template #body="{ data }">
          <Chip v-tippy="{ content: 'Cancelled rate' }" :severity="getCancelledScoreColour(data.conversionRates.totalVsCancelled * 100)">
            {{ numberFormatter(data.conversionRates.totalVsCancelled * 100, 'normal', 0) }}%
          </Chip>
        </template>
      </Column>

      <Column field="appointments.noShow" header="No-Show" :sortable="true">
        <template #body="{ data }">
          <a
            class="link"
            :href="`/reports/appointments/list?dateBasis=${dateBasis}&dateRange=${dateRange}&dealerships=${data.dealershipId}&outcome=no-show`"
            >{{ data.appointments.noShow }}</a
          >
        </template>
      </Column>

      <Column field="conversionRates.totalVsNoShow" :sortable="true">
        <template #header>
          <div>%</div>
          <Icon v-tippy="{ content: 'No-Show rate' }" type="circle-info" fa-style="far" />
        </template>
        <template #body="{ data }">
          <Chip v-tippy="{ content: 'No-Show rate' }" :severity="getNoShowScoreColour(data.conversionRates.totalVsNoShow * 100)"
            >{{ numberFormatter(data.conversionRates.totalVsNoShow * 100, 'normal', 0) }}%</Chip
          >
        </template>
      </Column>

      <ColumnGroup type="footer">
        <Row>
          <Column :colspan="1" footer-style="text-align:left; padding: 14px 21px;">
            <template #footer>
              {{ $t('ui.common.total', 1) }}
            </template>
          </Column>
          <Column :colspan="1" footer-style="text-align:left; padding: 14px 21px;">
            <template #footer>
              {{ reportTotals.appointmentsTotal }}
            </template>
          </Column>
          <Column :colspan="2" footer-style="text-align:left; padding: 14px 21px;">
            <template #footer>
              {{ reportTotals.cancelledTotal }}
            </template>
          </Column>
          <Column :colspan="2" footer-style="text-align:left; padding: 14px 21px;">
            <template #footer>
              {{ reportTotals.noShowTotal }}
            </template>
          </Column>
        </Row>
      </ColumnGroup>
    </DataTable>
  </Card>
</template>

<script>
import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup' //optional f
import Row from 'primevue/row' //or column grouping
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import dayjs from 'dayjs'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import { useBreakPoints } from '@/composables/useWindowSize'

import { ref, computed } from 'vue'

export default {
  components: {
    Card,
    PageHeader,
    DataTable,
    ColumnGroup,
    Row,
    Icon,
    Dropdown,
    DropdownItem,
    Column,
    MultiSelect,
    DatePicker,
    Input,
    Button,
    Chip
  },
  setup() {
    const dt = ref({ processedData: [] })

    const dateBasis = ref(localStorage.getItem('report-appointment-cancelled-and-no-show-datebasis') || 'occurring')
    const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
    const appointmentsApiRequest = useApiRequest()
    const userStore = useUserStore()

    const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

    const appointments = ref([])
    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dealershipId: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    initFilters()

    function clearFilters() {
      initFilters()
    }

    function fetchAppointments() {
      appointmentsApiRequest
        .send({
          endpoint: '/v1/reports/appointments/cancelledAndNoShow',
          params: {
            dateBasis: dateBasis.value,
            dealerships: dealershipsAllowed.value.map(d => d.id).join(','),
            start: dayjs(dateRange.value[0]).format('YYYY-MM-DD 00:00:00'),
            end: dayjs(dateRange.value[1]).format('YYYY-MM-DD 23:59:59')
          }
        })
        .then(response => {
          appointments.value = response.data
        })
    }
    fetchAppointments()

    function onDateRangeChange() {
      if (dateRange.value[0] && dateRange.value[1]) {
        fetchAppointments()
      }
    }

    function dateBasisChange(value) {
      dateBasis.value = value
      //set ls for persistence
      localStorage.setItem('report-appointment-cancelled-and-no-show-datebasis', value)
      fetchAppointments()
    }

    const dealershipsList = computed(() => {
      return dealershipsAllowed.value.map(d => {
        return {
          value: d.id,
          label: d.name
        }
      })
    })

    function getCancelledScoreColour(score) {
      if (score <= 7) {
        return 'success'
      } else if (score <= 15) {
        return 'warning'
      } else {
        return 'danger'
      }
    }
    function getNoShowScoreColour(score) {
      if (score <= 9) {
        return 'success'
      } else if (score <= 19) {
        return 'warning'
      } else {
        return 'danger'
      }
    }

    const reportTotals = computed(() => {
      let appointmentsTotal = 0
      let cancelledTotal = 0
      let noShowTotal = 0

      if (dt.value.processedData.length > 0) {
        dt.value.processedData.forEach(dealership => {
          appointmentsTotal += dealership.appointments.total
          cancelledTotal += dealership.appointments.cancelled
          noShowTotal += dealership.appointments.noShow
        })
      }

      return { appointmentsTotal, cancelledTotal, noShowTotal }
    })

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    return {
      dt,
      useBreakPoints,
      dateBasis,
      dateRange,
      dateBasisChange,
      appointmentsIsLoading: appointmentsApiRequest.isLoading,
      appointments,
      onDateRangeChange,
      filters,
      dealershipsList,
      exportCSV,
      clearFilters,
      reportTotals,
      getCancelledScoreColour,
      getNoShowScoreColour
    }
  }
}
</script>

<style></style>
