<template>
  <DataTable
    ref="dt"
    v-model:selection="selectedAccounts"
    v-model:filters="filters"
    v-model:expandedRows="expandedRows"
    export-filename="Billing Accounts"
    :value="billingAccountsComputed"
    removable-sort
    :rows="100"
    sort-field="name"
    :sort-order="1"
    data-key="id"
    column-resize-mode="fit"
    responsive-layout="scroll"
    :paginator="false"
    filter-display="menu"
    :global-filter-fields="['id', 'name']"
    :rows-per-page-options="[10, 20, 50]"
    state-key="dt-statements"
    state-storage="session"
    current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
  >
    <template #empty> No records found </template>
    <template #header>
      <div class="flex justify-between">
        <span class="flex justify-between gap-4">
          <span>
            <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
          </span>
          <Button
            :label="$t('ui.actions.clear', 1)"
            severity="secondary"
            type="button"
            outlined
            icon="filter-slash"
            @click="clearFilter()"
          />
        </span>
      </div>
      <div class="flex justify-between mt-4">
        <span>
          <ButtonGroup v-tippy="{ content: selectedAccounts.length == 0 ? 'Select accounts first' : '' }">
            <Button
              :label="$t('ui.actions.send', 1)"
              type="button"
              severity="secondary"
              outlined
              icon="envelope"
              :disabled="selectedAccounts.length == 0 && true"
              :is-loading="sendProgress != null"
              @click="sendSelectedInvoice()"
            />
            <Button
              label="PDF"
              type="button"
              severity="secondary"
              outlined
              icon="file-pdf"
              :disabled="selectedAccounts.length == 0"
              :is-loading="downloadProgress != null"
              @click="downloadSelectedPDF()"
            />
          </ButtonGroup>
        </span>
      </div>
    </template>

    <Column selection-mode="multiple" header-style="width: 3em"></Column>

    <Column field="id" header="#" :sortable="true"></Column>
    <Column field="name" header="Billing Account" :sortable="true">
      <template #body="{ data }">
        {{ data.name }}
      </template>
      <template #filter="{ filterModel }">
        <Input v-model="filterModel.value" type="text" placeholder="Search by BA Name" />
      </template>
    </Column>

    <Column field="outstandingInvoices" header="Outstanding" :sortable="true">
      <template #body="{ data }">
        <div v-if="data.outstandingInvoices > 0">{{ numberFormatter(data.outstandingInvoices, 'currency', 2, 'gbp') }}</div>
      </template>
    </Column>

    <Column field="overdueInvoices" header="Overdue" :sortable="true">
      <template #body="{ data }">
        <div v-if="data.overdueInvoices > 0" class="text-danger">
          {{ numberFormatter(data.overdueInvoices, 'currency', 2, 'gbp') }}
        </div>
      </template>
    </Column>

    <Column field="paymentMethod" header="Method" filter-field="paymentMethod" :sortable="true" :show-filter-match-modes="false">
      <template #body="{ data }">
        <Icon v-if="data.paymentMethod === 'Bank Transfer'" v-tippy="{ content: data.paymentMethod }" type="building-columns" />
        <img
          v-else-if="data.paymentMethod === 'Direct Debit'"
          v-tippy="{ content: data.paymentMethod }"
          :src="$cdnBaseUrl + '/img/logos/other/direct-debit.svg'"
          style="height: 16px"
        />
      </template>
      <template #filter="{ filterModel }">
        <!-- <div class="mb-3 font-bold">Payment Method</div> -->
        <MultiSelect v-model="filterModel.value" :options="paymentMethodOptions" placeholder="All" class="p-column-filter">
          <template #option="slotProps">
            <div class="p-multiselect-representative-option">
              <span class="image-text">{{ slotProps.option }}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>

    <Column field="isActive" filter-field="isActive" header="Archived" :sortable="true">
      <template #body="{ data }">
        <Icon v-if="!data.isActive" v-tippy="{ content: 'Archived' }" type="archive" />
      </template>
      <!-- <template #filter="{ filterModel }">
        <label class="font-bold"> Verified </label>
        <Checkbox v-model="filterModel.value" label="Archived" />
      </template> -->
    </Column>

    <Column field="contacts" header="Contacts" :sortable="false" :hidden="true">
      <template #body="{ data }">
        <div v-for="contact in data.contacts" :key="contact.id">
          {{ contact.email }}
        </div>
      </template>
    </Column>

    <Column expander style="width: 5rem" />

    <template #expansion="slotProps">
      <div class="grid grid-cols-4 gap-10">
        <div>
          <Label>Dealerships</Label>
          <div v-for="dealership in slotProps.data.dealerships" :key="dealership.id">
            {{ dealership.dealershipName }}
          </div>
        </div>
        <div>
          <Label>Contacts</Label>
          <div v-for="contact in slotProps.data.contacts" :key="contact.id">
            {{ contact.email }}
          </div>
        </div>
        <div>
          <Label>Payment</Label>
          <div>Method: Manual Bank Transfer</div>
          <div>Mandate: MD82NNA1SC29</div>
        </div>
        <div>
          <Label>Outstanding Invoices</Label>
        </div>
      </div>
    </template>

    <ColumnGroup type="footer">
      <Row>
        <Column :colspan="2" footer-style="text-align:left">
          <template #footer>
            <span v-if="selectedAccounts.length > 0"
              >{{ $t('ui.common.item', 2) }} <span>({{ $t('ui.common.selected', 2) }})</span>: {{ selectedAccounts.length }}
            </span>
            <span v-else-if="dtRendered.count > 0">{{ $t('ui.common.item', 2) }}: {{ dtRendered.count }} </span>
          </template>
        </Column>
        <Column></Column>
        <Column>
          <template #footer>
            <span v-if="selectedAccounts.length === 0">
              {{ numberFormatter(dtRendered.sumOutstanding, 'currency', 2) }}
            </span>
            <span v-else>
              {{ numberFormatter(accountTotals.sumOutstanding, 'currency', 2) }}
            </span>
          </template>
        </Column>
        <Column>
          <template #footer>
            <span v-if="selectedAccounts.length === 0">
              {{ numberFormatter(dtRendered.sumOverdue, 'currency', 2) }}
            </span>
            <span v-else>
              {{ numberFormatter(accountTotals.sumOverdue, 'currency', 2) }}
            </span>
          </template>
        </Column>
        <Column :colspan="1" footer-style="text-align:right">
          <template #footer>
            <!-- <span v-if="selectedAccounts.length > 0">({{ $t("ui.common.selected", 1) }})</span> -->
          </template>
        </Column>
        <Column></Column>
      </Row>
    </ColumnGroup>
  </DataTable>
</template>

<script>
import Button from '@/components/button/Button.vue'
import ButtonGroup from '@/components/button/ButtonGroup.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup' //optional for column grouping
import Row from 'primevue/row' //optional for row
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import { ref, computed } from 'vue'

import dayjs from 'dayjs'

import getBillingAccounts from '@/composables/getBillingAccounts'
import downloadStatement from '@/composables/downloadStatementPDF'

export default {
  components: {
    MultiSelect,
    Input,
    Button,
    Icon,
    DataTable,
    Column,
    ColumnGroup,
    Row,
    ButtonGroup
  },
  setup() {
    const dt = ref({ processedData: [] })
    const expandedRows = ref([])

    const { billingAccounts, error, load } = getBillingAccounts({ showArchived: true, treeView: false })
    load()

    const { downloadCount, downloadProgress, downloadPDF } = downloadStatement()

    const selectedAccounts = ref([])

    const billingAccountsComputed = computed(() => {
      billingAccounts.value.forEach(ba => {
        let contactsSorted = []
        ba.contacts.forEach(contact => {
          contactsSorted.push(contact.email)
        })
        // console.log(contactsSorted)
        ba.contactsSorted = contactsSorted
      })

      return billingAccounts.value
    })

    const accountTotals = computed(() => {
      let sumOutstanding = 0
      let sumOverdue = 0
      if (selectedAccounts.value.length === 0) {
        billingAccounts.value.forEach(account => {
          sumOutstanding += parseFloat(account.outstandingInvoices)
          sumOverdue += parseFloat(account.overdueInvoices)
        })
      } else {
        selectedAccounts.value.forEach(account => {
          sumOutstanding += parseFloat(account.outstandingInvoices)
          sumOverdue += parseFloat(account.overdueInvoices)
        })
      }
      return { sumOutstanding: sumOutstanding, sumOverdue: sumOverdue }
    })

    const dtRendered = computed(() => {
      let count = 0
      let sumOutstanding = 0
      let sumOverdue = 0

      if (dt.value.processedData.length > 0) {
        count = dt.value.processedData.length
        dt.value.processedData.forEach(account => {
          sumOutstanding += parseFloat(account.outstandingInvoices)
          sumOverdue += parseFloat(account.overdueInvoices)
        })
      }

      return { count: count, sumOutstanding: sumOutstanding, sumOverdue: sumOverdue }
    })

    function downloadSelectedPDF() {
      let arr = []
      selectedAccounts.value.forEach(account => {
        arr.push({
          id: account.id,
          fileName: 'Statement - ' + account.name + ' ' + dayjs().format('DD MMM YY')
        })
      })

      downloadPDF(arr)
    }

    const paymentMethodOptions = ref(['Bank Transfer', 'Direct Debit'])

    const filters = ref()
    const initFilters = () => {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        paymentMethod: { value: null, matchMode: FilterMatchMode.IN },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS }
      }
    }
    initFilters()

    const clearFilter = () => {
      // console.log('Clearing Filter')
      initFilters()
    }

    return {
      dt,
      paymentMethodOptions,
      filters,
      clearFilter,
      billingAccounts,
      billingAccountsComputed,
      selectedAccounts,
      dtRendered,
      accountTotals,
      downloadSelectedPDF,
      downloadProgress,
      expandedRows
    }
  }
}
</script>

<style>
.p-datatable-row-expansion td {
  background-color: #fbfbfb;
  padding: 30px 50px !important;
  box-shadow: inset 0px 15px 20px -10px rgb(0, 0, 0, 0.05);
}
</style>
