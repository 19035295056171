<template>
  <Steps
    ref="formWizard"
    :steps="formSteps"
    :is-submitting="purchaseNumberApiRequest.isLoading.value"
    @before-move="validateStep"
    @on-complete="submitNewNumber"
  >
    <template #step-1>
      <FormGroup label="Display Name" :is-required="v$.name.required">
        <div class="text-quaternary text-sm mb-1">Format: Group Manufacturer Location</div>
        <Input v-model="newNumber.name" :has-error="v$.name.$error" placeholder="Vindis Audi Cambridge" />
        <InputError :has-error="v$.name.$error">{{ v$.name.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup label="Text-to-Speech Name" :is-required="v$.textToSpeechName.required">
        <div class="text-quaternary text-sm">E.g. SEAT should be 'Seyut' for correct pronounciation.</div>
        <div class="text-quaternary text-sm mb-1">Always carry out a test call to ensure the text-to-speech sounds correct.</div>

        <Input
          v-model="newNumber.textToSpeechName"
          :has-error="v$.textToSpeechName.$error"
          icon="comment"
          placeholder="Group Seyut Maidenhead"
        />
        <InputError :has-error="v$.textToSpeechName.$error">{{ v$.textToSpeechName.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup :label="$t('ui.entities.dealership', 2)" :is-required="v$.dealerships.required">
        <MultiSelect
          v-model="newNumber.dealerships"
          :options="dealershipsList"
          display="chip"
          placeholder="Select dealerships..."
          filter
          option-value="value"
          option-label="label"
          class="w-full"
          :class="{ 'has-error': v$.dealerships.$error }"
        >
          <template #option="{ option }">
            <img :src="option.manufacturerLogo" width="20" class="mr-3" />
            {{ option.label }}
          </template>
        </MultiSelect>

        <InputError :has-error="v$.dealerships.$error">{{ v$.dealerships.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup label="Approved Bundle SID" :is-required="v$.bundleSid.required">
        <Select
          v-model="newNumber.bundleSid"
          :items="bundleOptions"
          :has-error="v$.bundleSid.$error"
          :is-object="true"
          :search="true"
          :can-clear="true"
        >
          <template #value="{ value }">
            <div class="flex items-center gap-2">
              {{ value.name }}
            </div>
          </template>
          <template #option="{ option }">
            <div>
              {{ option.name }}
              <div class="text-sm text-quaternary">{{ option.sid }}</div>
            </div>
          </template>
        </Select>
        <InputError :has-error="v$.bundleSid.$error">{{ v$.bundleSid.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup label="Address SID" :is-required="v$.addressSid.required">
        <Select
          v-model="newNumber.addressSid"
          :has-error="v$.addressSid.$error"
          :items="addressOptions"
          :is-object="true"
          :search="true"
          :can-clear="true"
        >
          <template #value="{ value }">
            <div>{{ value.name }}</div>
          </template>
          <template #option="{ option }">
            <div>{{ option.name }}</div>
            <div class="text-sm text-quaternary">({{ option.address }})</div>
            <div class="text-sm text-quaternary">{{ option.sid }}</div>
          </template>
        </Select>
        <InputError :has-error="v$.addressSid.$error">{{ v$.addressSid.$errors[0]?.$message }}</InputError>
      </FormGroup>
    </template>

    <template #step-2>
      <div class="flex items-start gap-4 mb-6">
        <FormGroup :label="$t('ui.entities.address.country')" :is-required="v$Search.country.required" no-margin class="w-[50%]">
          <Select v-model="searchData.country" :items="countriesList" :is-object="true" :search="true" :can-clear="true">
            <template #value="{ value }">
              <div class="flex items-center gap-2">
                <img :src="value.image" width="20" />
                (+{{ value.dialCode }}) {{ value.name }}
              </div>
            </template>
            <template #option="{ option }">
              <div class="flex items-center gap-2">
                <img :src="option.image" width="20" />
                (+{{ option.dialCode }}) {{ option.name }}
              </div>
            </template>
          </Select>
          <InputError :has-error="v$Search.country.$error">{{ v$Search.country.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup label="Number Starts With" no-margin :is-required="v$Search.number.required">
          <Input v-model="searchData.number" :has-error="v$Search.number.$error" />
          <InputError :has-error="v$Search.number.$error">{{ v$Search.number.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <div class="mt-7">
          <Button
            :label="$t('ui.actions.search')"
            @click="searchNumber"
            :is-loading="purchasableNumbersApiRequest.isLoading.value"
            outlined
          />
        </div>
      </div>
      <div class="w-full">
        <ListGroup>
          <ListGroupItem v-if="purchasableNumbersApiRequest.isLoading.value">
            <div class="flex flex-col justify-center items-center p-4">
              <Icon type="spinner-third" class="btn-icon fa-spin" fa-style="far" />
              <div class="text-quaternary">Loading results...</div>
            </div>
          </ListGroupItem>
          <div v-else-if="purchasablePhoneNumbers">
            <ListGroupItem
              v-for="(result, index) in purchasablePhoneNumbers"
              :key="index"
              class="flex gap-2 items-center link-hover"
              :class="{ 'text-brand-500': selectedNumberIndex == index }"
              @click="selectNumber(index)"
            >
              <Icon v-if="selectedNumberIndex == index" type="circle-check" />
              <Icon v-else type="circle" class="text-quaternary" />

              <div class="flex w-full items-center justify-between">
                <div>{{ result.friendlyName }}</div>
                <div class="text-sm">{{ result.locality }}</div>
              </div>
            </ListGroupItem>
          </div>

          <ListGroupItem v-else>
            <div class="flex flex-col justify-center items-center p-4">
              <Icon type="search" class="text-xl" />
              <div class="text-quaternary">No results</div>
            </div>
          </ListGroupItem>
        </ListGroup>

        <div v-if="purchasablePhoneNumbers" class="link flex gap-2 items-center justify-center mt-4 w-full" @click="searchNumber">
          <Icon type="arrows-rotate" />
          Refresh results
        </div>

        <InputError :has-error="v$.phoneNumberIso.$error">{{ v$.phoneNumberIso.$errors[0]?.$message }}</InputError>
      </div>
    </template>

    <template #step-3>
      <div class="text-xl mb-2">{{ newNumber.name }}</div>
      <div class="flex justify-between items-center mb-6">
        <Chip>
          <img :src="countriesList.find(country => country.dialCode == newNumber.dialingCode).image" width="20" class="mr-2" />
          {{ newNumber.location }}, {{ countriesList.find(country => country.dialCode == newNumber.dialingCode).country }}
        </Chip>
        <div class="text-xl">{{ newNumber.phoneNumberIso }}</div>
      </div>

      <div class="max-w-96">
        <div class="text-sm mb-2">{{ $t('ui.entities.dealership', 2) }}</div>
        <div class="flex flex-wrap gap-2">
          <div v-for="(dealership, index) in newNumber.dealerships" :key="index">
            <Chip size="sm">{{ dealershipsList.find(d => d.value == dealership)?.label }}</Chip>
          </div>
        </div>
      </div>
    </template>
  </Steps>
</template>

<script setup>
import { ref, computed, watch } from 'vue'

import Steps from '@/components/steps/Steps.vue'

import Icon from '@/components/icon/Icon.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import alertToast from '@/utilities/alertToast'
import Select from '@/components/forms/Select.vue'
import Button from '@/components/button/Button.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
import Chip from '@/components/chip/Chip.vue'

import useVuelidate from '@vuelidate/core'
import { required, numeric, helpers, minValue } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import Swal from 'sweetalert2'

import MultiSelect from 'primevue/multiselect'

const emit = defineEmits(['form-updated', 'is-submitting', 'completed'])

const userStore = useUserStore()

const dealershipsApiRequest = useApiRequest()
const purchasableNumbersApiRequest = useApiRequest()
const purchaseNumberApiRequest = useApiRequest()

const formWizard = ref(null) // Reference to the FormWizard component

const dealershipsList = ref(null)
const dealershipsAllowed = userStore.accessAllowed.dealerships.map(dealership => dealership.id)

dealershipsApiRequest.send({ method: 'GET', endpoint: '/v1/dealerships?ids=' + dealershipsAllowed }).then(response => {
  dealershipsList.value = response.data.map(dealership => {
    return { value: dealership.id, label: dealership.displayName, manufacturerLogo: dealership.manufacturerLogo }
  })
})

const formSteps = [
  {
    label: 'Basic Details',
    icon: null
  },
  {
    label: 'Search Number',
    icon: null
  },
  {
    label: 'Summary',
    icon: null
  }
]

const newNumber = ref({
  name: null,
  textToSpeechName: null,
  dealerships: null,
  dialingCode: null,
  country: null,
  number: null,
  phoneNumberIso: null,
  location: null,
  addressSid: null,
  bundleSid: null
})

const purchasablePhoneNumbers = ref(null)
const selectedNumberIndex = ref(null)

const inputRules = {
  formStep1: {
    name: { required },
    textToSpeechName: { required },
    dealerships: { required },
    bundleSid: { required },
    addressSid: { required }
  },
  formStep2: {
    phoneNumberIso: { required }
  },
  formStep3: {}
}

const searchInputRules = {
  country: { required },
  number: {
    numeric,
    required: helpers.withMessage('Required', required),
    numberPrefix: helpers.withMessage('Must not start with 0', value => {
      return value.split('')[0] != 0
    }),
    numberLength: helpers.withMessage('Must be minimum two characters', value => {
      return value.length > 1
    })
  }
}

const searchData = ref({
  country: null,
  number: null
})

const stepValidationRules = computed(() => {
  if (formWizard.value?.currentStep) {
    return inputRules[`formStep${formWizard.value?.currentStep + 1}`]
  }
  return inputRules.formStep1
})

const v$ = useVuelidate(stepValidationRules, newNumber)
const v$Search = useVuelidate(searchInputRules, searchData)

async function validateStep(step) {
  const isStepCorrect = await v$.value.$validate()
  if (isStepCorrect) {
    formWizard.value.move(1) // Move to the next step
  }
}

async function searchNumber() {
  newNumber.value.dialingCode = searchData.value.country.dialCode
  newNumber.value.countryCode = searchData.value.country.code

  let dataToPost = {
    isoCountry: searchData.value.country.code,
    dialingCode: searchData.value.country.dialCode,
    number: parseInt(searchData.value.number)
  }

  const isStepCorrect = await v$Search.value.$validate()
  if (isStepCorrect) {
    try {
      purchasableNumbersApiRequest
        .send({ method: 'GET', endpoint: '/v1/comms/phone-numbers/purchasable', params: dataToPost })
        .then(response => {
          if (response.success) {
            purchasablePhoneNumbers.value = response.data.slice(0, 5)
          } else {
            alertToast('Failed to fetch numbers', response.data.message, 'error')
          }
        })
    } catch (err) {
      alertToast('Failed to fetch numbers', err.message, 'error')
    }
  }
}

function selectNumber(index) {
  selectedNumberIndex.value = index
  newNumber.value.phoneNumberIso = purchasablePhoneNumbers.value[index].phoneNumber
  newNumber.value.location = purchasablePhoneNumbers.value[index].locality
}

const countriesList = [
  {
    name: useI18n().t('meta.countries.gb.label'),
    country: 'UK',
    code: 'GB',
    emoji: '🇬🇧',
    dialCode: 44,
    unicode: 'U+1F1EC U+1F1E7',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'
  }
  // {
  //   name: useI18n().t('meta.countries.us.label'),
  //   country: 'US',
  //   code: 'US',
  //   emoji: '🇺🇸',
  //   dialCode: 1,
  //   unicode: 'U+1F1FA U+1F1F8',
  //   image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'
  // }
]

const bundleOptions = [
  {
    name: 'Retain Automotive Ltd',
    sid: 'BU5ffa6487ddae7a650b45a14a8635cff4'
  }
]

const addressOptions = [
  {
    name: 'Retain Automotive Ltd',
    address: '37 Station Road, Harleston, Norfolk, United Kingdom, IP209ES',
    sid: 'ADc6705cb7c76370ed863d0550c3ffbd39'
  }
]

async function submitNewNumber() {
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) {
    return
  }

  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    reverseButtons: true,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: 'Yes, purchase this number',
    confirmButtonColor: 'var(--bg-brand-solid)',
    cancelButtonText: 'Cancel'
  }).then(function (response) {
    if (response.isConfirmed) {
      let dataToPost = {
        name: newNumber.value.name,
        location: newNumber.value.location,
        countryCode: newNumber.value.countryCode,
        textToSpeechName: newNumber.value.textToSpeechName,
        dealerships: newNumber.value.dealerships,
        dialingCode: newNumber.value.dialingCode,
        number: newNumber.value.phoneNumberIso.slice(newNumber.value.dialingCode.toString().length + 1),
        phoneNumberIso: newNumber.value.phoneNumberIso,
        addressSid: newNumber.value.addressSid.sid,
        emergencyAddressSid: newNumber.value.addressSid.sid,
        bundleSid: newNumber.value.bundleSid.sid
      }

      try {
        purchaseNumberApiRequest
          .send({
            method: 'POST',
            endpoint: '/v1/comms/phone-numbers/purchase',
            data: JSON.stringify(dataToPost)
          })
          .then(response => {
            if (response.success) {
              alertToast('Added', null, 'success')
              emit('completed')
            }
          })
      } catch (err) {
        alertToast('Failed to purchase number', err.message, 'error')
      }
    }
  })
}

watch(
  () => newNumber.value,
  () => {
    emit('form-updated')
  },
  { deep: true }
)
</script>

<style></style>
