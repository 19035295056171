import { ref } from 'vue'

import { getDashboardsForRoles, hasMultipleDashboards } from '@/utilities/dashboards'

// Initialize navItems as an empty array
const navItems = ref([])

// Create a function which will accept an array of role ids, and return the navItems which the user has access to
export const getNavItems = roles => {
  // Reset navItems to an empty array
  navItems.value = []

  let homeItem = {
    title: 'Home ',
    route: '/home',
    icon: 'home',
    localeKey: 'ui.common.home'
  }

  let dashboardsItem

  if (hasMultipleDashboards(roles)) {
    homeItem = null
    dashboardsItem = {
      title: 'Dashboards',
      icon: 'gauge',
      localeKey: 'ui.entities.dashboard',
      localePluralization: 2,
      children: getDashboardsForRoles(roles)
    }
  }

  // ! If the item has an ID, DO NOT CHANGE as it is relied upon by the badge counters emitters

  // Conditionally add homeItem and dashboardsItem
  if (homeItem) {
    navItems.value.push(homeItem)
  }
  if (dashboardsItem) {
    navItems.value.push(dashboardsItem)
  }

  navItems.value.push(
    {
      title: 'To-do',
      route: '/tasks/to-do',
      icon: 'check-double',
      id: 'todo',
      localeKey: 'ui.entities.to-do',
      localePluralization: 2,
      canGate: 'view_tasks'
    },
    {
      title: 'Customers',
      route: '/customers',
      icon: 'users',
      localeKey: 'ui.entities.customer',
      localePluralization: 2,
      canGate: 'view_customers',
      children: [
        {
          title: 'All',
          route: '/customers',
          icon: 'wind',
          localeKey: 'ui.common.all',
          localePluralization: 1,
          canGate: 'view_customers_list'
        },
        {
          title: 'Flow',
          route: '/customers/flow',
          icon: 'wind',
          localeKey: 'ui.entities.flow.label',
          localePluralization: 1,
          canGate: 'view_flow'
        },
        {
          title: 'Leads',
          route: '/customers/leads',
          icon: 'bolt',
          localeKey: 'ui.entities.lead',
          localePluralization: 2,
          canGate: 'view_leads'
        },
        {
          title: 'Campaigns',
          route: '/customers/campaigns',
          localeKey: 'ui.entities.campaign',
          localePluralization: 2,
          canGate: 'view_campaigns'
        }
      ]
    },
    {
      title: 'Appointments',
      route: '/appointments',
      icon: 'calendar',
      localeKey: 'ui.entities.appointment.label',
      localePluralization: 2,
      canGate: 'view_appointments',
      children: [
        {
          title: 'Manager',
          route: '/appointments/manager',
          localeKey: 'ui.entities.user.role.manager', // TODO: change locale key as this is not the correct one to use
          localePluralization: 1,
          canGate: 'assign_appointments'
        },
        {
          title: 'Calendar',
          route: '/appointments/calendar',
          localeKey: 'ui.entities.calendar',
          localePluralization: 1
        },
        {
          title: 'Availability',
          route: '/appointments/availability',
          localeKey: 'ui.entities.availability',
          localePluralization: 1,
          canGate: 'view_availability'
        }
      ]
    },
    {
      title: 'Conversations',
      route: '/conversations',
      icon: 'comments',
      localeKey: 'ui.entities.conversation',
      localePluralization: 2,
      canGate: 'view_conversations',
      children: [
        // {
        //   title: 'Inbox',
        //   route: '/inbox',
        //   localeKey: 'ui.entities.inbox',
        //   localePluralization: 1
        // },
        // {
        //   title: 'All Conversations',
        //   route: '/conversations/all',
        //   localePluralization: 1,
        //   localeKey: 'ui.common.all'
        // },
        {
          title: 'Missed Conversations',
          route: '/conversations/missed',
          localePluralization: 1,
          localeKey: 'ui.common.missed'
        }
      ]
    },
    {
      title: 'Vehicles',
      route: '/vehicles',
      icon: 'car',
      localeKey: 'ui.entities.vehicle.label',
      localePluralization: 2,
      canGate: 'view_vehicles',
      children: [
        {
          title: 'Valuations',
          route: '/vehicles/valuations',
          localeKey: 'ui.entities.valuation',
          localePluralization: 2,
          canGate: 'view_valuations'
        },
        {
          title: 'ReAuction',
          route: '/vehicles/reauction',
          localeKey: 'ui.entities.reauction.label',
          localePluralization: 1
        },
        // {
        //   title: 'Used Stock',
        //   route: '/vehicles/stock/used',
        //   localeKey: 'ui.entities.used-stock',
        //   localePluralization: 1,
        //   canGate: 'view_vehicles_used_stock'
        // },
        {
          title: 'Models',
          route: '/vehicles/models',
          localeKey: 'ui.entities.vehicle.model',
          localePluralization: 2,
          canGate: 'view_new_vehicle_models'
        }
      ]
    },
    {
      title: 'Reports',
      route: '/reports',
      icon: 'chart-column',
      localeKey: 'ui.entities.report.label',
      localePluralization: 2,
      canGate: 'view_reports'
    },
    {
      title: 'Invoicing',
      route: '/finance/invoicing',
      icon: 'file-invoice-dollar',
      localeKey: 'ui.entities.finance.invoicing',
      localePluralization: 2,
      canGate: 'view_finances'
      // children: [
      //   {
      //     title: 'Dashboard',
      //     route: '/finance',
      //     localeKey: 'ui.entities.dashboard',
      //     localePluralization: 1
      //   },
      //   {
      //     title: 'Invoicing',
      //     route: '/finance/invoicing',
      //     localeKey: 'ui.entities.finance.invoice',
      //     localePluralization: 2
      //   }
      // ]
    },
    {
      title: 'Settings',
      route: '/settings',
      icon: 'gear',
      localeKey: 'ui.entities.setting',
      localePluralization: 2,
      canGate: 'view_settings'
    }
  )
}

export { navItems }
