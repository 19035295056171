<template>
  <Card class="h-full" full-height no-margin>
    <div class="full-calendar-wrapper">
      <!-- Menu -->
      <div class="calendar-left h-full dark:border-darkmode-2 dark:bg-darkmode-1 overflow-y-auto" :class="{ open: menuOpen }">
        <h3 class="p-5">{{ $t('ui.common.filter', 2) }}</h3>
        <div class="p-5 pt-0">
          <Label for="status">{{ $t('ui.common.status', 2) }}</Label>
          <div v-for="status in filterArray.statuses" :key="status" class="mb-1">
            <Checkbox v-model="status.isChecked" :label="status.label" :checkbox-color="status.color" />
          </div>
        </div>
        <div class="p-5 pt-0 pb-0">
          <FormGroup :label="$t('ui.entities.dealership', 2)" label-for="dealerships">
            <Multiselect
              v-model="filterArray.dealershipsSelected"
              mode="multiple"
              label="name"
              value-prop="id"
              track-by="name"
              :placeholder="$t('ui.common.all', 2)"
              :multiple-label="
                values => {
                  return values.length + ' selected'
                }
              "
              :close-on-select="false"
              :searchable="true"
              :hide-selected="false"
              :groups="true"
              group-label="name"
              group-options="dealerships"
              :options="filterArray.dealershipItems"
            />
            <!-- {{ filterArray.dealershipItems }}
              {{ filterArray.dealershipsSelected }} -->
            <!-- <InputError :hasError="v$.tags.$error">{{ v$.tags.$errors[0].$message }}</InputError> -->
          </FormGroup>
        </div>
        <div class="p-5 pt-0">
          <FormGroup :label="$t('ui.entities.appointment.booked-by')" label-for="created-by">
            <Multiselect
              id="created-by"
              v-model="filterArray.createdBySelected"
              mode="multiple"
              label="fullName"
              value-prop="id"
              track-by="fullName"
              :placeholder="$t('ui.common.all', 2)"
              :multiple-label="
                values => {
                  return values.length + ' selected'
                }
              "
              :close-on-select="false"
              :searchable="true"
              :hide-selected="false"
              :options="filterArray.createdByItems"
            />
            <!-- {{ filterArray.createdByItems }} -->
            <!-- {{ filterArray.createdBySelected }} -->
          </FormGroup>
        </div>
        <div class="p-5 pt-0">
          <Button :label="$t('ui.actions.clear', 2)" severity="secondary" icon="filter-slash" outlined @click="clearFilters()" />
        </div>
      </div>
      <!-- Main Calendar -->
      <div class="calendar-right">
        <FullCalendar ref="fullCalendarRef" :options="filteredCalendar" />
        <!-- {{ events }} -->
      </div>
      <div class="calendar-menu-overlay" :class="{ open: menuOpen }" @click="toggleMenu"></div>
    </div>
  </Card>
</template>

<script>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'

import dayjs from 'dayjs'

import Button from '@/components/button/Button.vue'
import Card from '@/components/card/Card.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Label from '@/components/forms/Label.vue'
import Multiselect from '@vueform/multiselect'
import { useTippy } from 'vue-tippy'
import { useRoute, useRouter } from 'vue-router'
import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

import { getProperties } from '@/utilities/dataMapper'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    Card,
    Checkbox,
    FormGroup,
    Label,
    Multiselect,
    Button
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const userStore = useUserStore()

    const dealershipsApiRequest = useApiRequest()
    const users = useApiRequest()

    const fullCalendarRef = ref(null)

    const menuOpen = ref(false)

    //Toggle menu logic
    function toggleMenu() {
      menuOpen.value = !menuOpen.value
      // console.log('menu open: ' + menuOpen.value);
    }

    //Set Filter array
    let filterArray = ref([])
    filterArray.value.statuses = [
      {
        name: 'unassigned',
        label: t('ui.entities.appointment.status.unassigned'),
        isChecked: true,
        color: 'utility-gray-600'
      },
      {
        name: 'assigned',
        label: t('ui.entities.appointment.status.assigned'),
        isChecked: true,
        color: 'utility-blue-900'
      },
      {
        name: 'cancelled',
        label: t('ui.entities.appointment.status.cancelled'),
        isChecked: true,
        color: 'utility-error-600'
      },
      {
        name: 'no-show',
        label: t('ui.entities.appointment.status.no-show'),
        isChecked: true,
        color: 'utility-error-600'
      },
      {
        name: 'completed',
        label: t('ui.entities.appointment.status.completed'),
        isChecked: true,
        color: 'utility-success-600'
      },
      {
        name: 'ongoing',
        label: t('ui.entities.appointment.status.ongoing'),
        isChecked: true,
        color: 'utility-purple-600'
      }
    ]

    // let filterDealershipItems = ref([])

    filterArray.value.dealershipItems = ref([])
    filterArray.value.dealershipsSelected = ref([])
    filterArray.value.createdByItems = ref([])
    filterArray.value.createdBySelected = ref([])

    // Get users from DB to add to 'Made By' dropdown filter
    try {
      users
        .send({
          endpoint: '/v1/users',
          params: { userType: 'retainUsers', groupByGroup: true, forList: true }
        })
        .then(response => {
          filterArray.value.createdByItems = response.data
        })
    } catch (err) {
      if (err.response) {
        console.log(err.response.status)
        console.log(err.response.data)
      }
    }

    // Get dealerships to use in filter
    const dealerships = JSON.parse(localStorage.getItem('accessSelected')).dealerships.join()

    if (dealerships) {
      try {
        dealershipsApiRequest
          .send({
            endpoint: '/v1/dealerships',
            params: { ids: dealerships, groupByGroup: true }
          })
          .then(response => {
            filterArray.value.dealershipItems = response.data
          })
      } catch (err) {
        if (err.response) {
          console.log('ERROR: ' + err.response.status)
          console.log('ERROR: ' + err.response.data)
        }
      }
    }

    //Set default view and date
    let calendarInitView = 'dayGridMonth'
    let calendarInitDate = dayjs().format('YYYY-MM-DD')
    //Check if view and dates are in localstorage
    if (localStorage.getItem('calendarInitView')) {
      calendarInitView = localStorage.getItem('calendarInitView')
      calendarInitDate = localStorage.getItem('calendarInitDate')
    }

    //If view and/or date isset in the route query, override previous values
    if (route.query.view) {
      calendarInitView = route.query.view
    }
    if (route.query.date) {
      calendarInitDate = route.query.date
    }
    if (route.query.statuses) {
      const calendarInitStatuses = route.query.statuses.split(',')

      filterArray.value.statuses.forEach(filter => {
        filter.isChecked = calendarInitStatuses.includes(filter.name)
      })
    }

    //Set ls calendarStartDate to null to force getEvent on first load
    localStorage.setItem('calendarStartDate', '')

    let events = ref([])

    let calendarOptions = ref({
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
      // height: 10,
      contentHeight: 100,
      views: {
        listDay: {
          type: 'list',
          duration: { days: 1 },
          buttonText: 'list day'
        },
        listTwoMonths: {
          type: 'list',
          duration: { months: 2 },
          buttonText: 'list next 2 months'
        }
      },
      initialView: calendarInitView,
      initialDate: calendarInitDate,
      datesSet: function (dateInfo) {
        // console.log('datesSet Ran')

        //If start and end dates do NOT match ones in Local Stroage, fetch events again for new date range
        if (dateInfo.start.toISOString() !== localStorage.getItem('calendarStartDate') && dealerships) {
          // console.log('Must fetch events')
          getEvents(dateInfo.start.toISOString(), dateInfo.end.toISOString())
        }

        // console.log(dateInfo.start.toISOString() + " = " + dateInfo.end.toISOString(), dateInfo.view.type);
        localStorage.setItem('calendarInitView', dateInfo.view.type)
        localStorage.setItem('calendarInitDate', dateInfo.start.toISOString())
        localStorage.setItem('calendarStartDate', dateInfo.start.toISOString())
        localStorage.setItem('calendarEndDate', dateInfo.end.toISOString())

        // getEvents(dateInfo.start.toISOString(), dateInfo.end.toISOString())
      },
      customButtons: {
        menuToggle: {
          text: useI18n().t('ui.common.filter'),
          click: function () {
            // alert('clicked the custom button!');
            toggleMenu()
          }
        }
      },
      headerToolbar: {
        left: 'menuToggle prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      showNonCurrentDates: false,
      eventOverlap: false,
      slotEventOverlap: false,
      locales: allLocales,
      locale: localStorage.getItem('locale'),
      firstDay: 1,
      navLinks: true, // can click day/week names to navigate views
      editable: false, //Disallow moving of events
      nowIndicator: true,
      dayMaxEvents: true, // allow "more" link when too many events
      eventTimeFormat: {
        // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false
      },
      fixedWeekCount: false,
      events: events.value,
      eventClick: function (info) {
        info.jsEvent.preventDefault()
        // console.log(info.event.path)
        router.push(info.event.url)
      },
      eventDidMount: function (info) {
        // console.log(JSON.stringify(info))

        useTippy(info.el, {
          content: 'loading...',
          allowHTML: true,
          theme: 'light',
          delay: 300,
          onShow(instance) {
            // console.log(info.event.id)

            //Check if content does not equal 'loading...'
            // console.log(instance.props.content)

            if (instance.props.content != 'loading...') {
              return
            }

            // Fetch appointment details from API
            try {
              useApiRequest()
                .send({
                  endpoint: '/v1/appointments/' + info.event.id
                })
                .then(response => {
                  // instance.setContent(JSON.stringify(response.data))
                  let data = response.data
                  let html = `
                    <div class="appointment-popout-status popout-${data['status']}">
                      <div class="font-medium">${data['statusText']}</div>
                      <div class="text-sm opacity-70">${data['outcome']['decision'] ?? ''}</div>
                    </div>
                    <div class="mt-1"><span class="fad fa-${data['methodIcon']} fa-fw mr-2"></span> ${data['methodText']}</div>
                    <div class="mt-1"><span class="fad fa-location-dot fa-fw mr-2"></span> ${data['dealershipName']}</div>
                    ${
                      data['assignedTo']
                        ? `<div class="mt-1"><span class="fad fa-user-tie fa-fw mr-2"></span> ${data['assignedToName']}</div>`
                        : ''
                    }
                    <!--<div class="mt-1"><span class="fad fa-user-check fa-fw mr-2"></span> ${
                      data['customerConfirmed'] == 0 ? 'Customer not confirmed' : 'Customer Confirmed'
                    }</div>-->
                    `
                  if (data['outcome']['reason'] != '') {
                    html += `<div class="italic mt-5"><span class="fad fa-comment-dots fa-fw mr-2"></span> "${data['outcome']['reason']}"</div>`
                  }
                  html = `<div class="p-1">${html}</div>`
                  instance.setContent(html)
                })
            } catch (err) {
              if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
              }
            }
          }
        })
      }
    })

    // Function to fetch events within the current view
    function getEvents(start, end) {
      // console.log('Running getEvents()')

      let urlParams = {
        dealerships: dealerships,
        start: dayjs(start).format('YYYY-MM-DD'),
        end: dayjs(end).format('YYYY-MM-DD')
      }

      if (userStore.roles.includes(10) && userStore.roles.length === 1) {
        // If Sales Exec
        urlParams.assignedTo = userStore.details.id
      }

      //Get events within the specified date range
      try {
        useApiRequest()
          .send({
            endpoint: '/v1/appointments',
            params: urlParams
          })
          .then(response => {
            //Format response data into fullcalendar format
            let formattedEvents = response.data.map(app => {
              let mappedStatus = getProperties('appointmentStatus', app.status)
              return {
                id: app.id,
                title: app.customerName,
                start: app.time,
                url: '/agreement/' + app.agreementId + '/appointments/' + app.id,
                status: app.status,
                dealershipId: app.dealershipId,
                createdBy: app.createdBy,
                statusColor: mappedStatus.color,
                classNames: ['style-appointment', app.status]
              }
            })
            events.value = formattedEvents
          })
      } catch (err) {
        if (err.response) {
          console.log(err.response.status)
          console.log(err.response.data)
        }
      }
    }

    function clearFilters() {
      //Loop through statuses and set isChecked to true
      filterArray.value.statuses.forEach(status => {
        status.isChecked = true
      })

      //Set dealerships selected to []
      filterArray.value.dealershipsSelected = []

      router.replace({ query: {} })
    }

    const filteredCalendar = computed(() => {
      // console.log('computed')

      // console.log(calendarOptions.value.events)

      //Get statuses that should be applied
      let filteredStatuses = filterArray.value.statuses.filter(status => status.isChecked)
      // console.log('Active Statuses: ' + JSON.stringify(filteredStatuses))

      let statusesToApply = ref([])
      filteredStatuses.forEach(status => {
        statusesToApply.value.push(status.name.toLowerCase())
      })
      // console.log('Statuses To Apply: ' + JSON.stringify(statusesToApply.value))

      //Filter based on statuses to apply
      let filteredEvents = []
      filteredEvents = events.value.filter(event => statusesToApply.value.includes(event.status))

      //Filter based on dealerships selected
      // console.log('Dealerships Selected: ' + filterArray.value.dealershipsSelected)
      if (filterArray.value.dealershipsSelected.length != 0) {
        // console.log(filterArray.value.dealershipsSelected)
        filteredEvents = filteredEvents.filter(event => filterArray.value.dealershipsSelected.includes(event.dealershipId))
      }

      //Filter based on created by
      // console.log('Dealerships Selected: ' + filterArray.value.dealershipsSelected)
      if (filterArray.value.createdBySelected.length != 0) {
        // console.log('CreatedBySelected: ' + filterArray.value.createdBySelected)
        // console.log(filterArray.value.createdBySelected)
        filteredEvents = filteredEvents.filter(event => filterArray.value.createdBySelected.includes(event.createdBy))
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      calendarOptions.value.events = filteredEvents

      return calendarOptions.value
    })

    const changeView = (viewName, date) => {
      if (fullCalendarRef.value) {
        const calendarApi = fullCalendarRef.value.getApi()
        calendarApi.changeView(viewName, date)
      } else {
        console.error('FullCalendar instance is not available')
      }
    }

    return {
      fullCalendarRef,
      menuOpen,
      toggleMenu,
      filterArray,
      filteredCalendar,
      clearFilters,
      events,
      changeView
    }
  }
}
</script>

<style>
.calendar-left {
  width: 250px;
  flex-shrink: 0;
  border-right: 1px solid var(--border-secondary);
  background: #fff;
  z-index: 11;
  transition: all 0.5s ease-in-out;
  border-radius: 0.428rem 0 0 0.428rem;
}

.calendar-left {
  position: absolute;
  left: -251px;
}

.calendar-left.open {
  left: 0;
}

.calendar-right {
  width: 100%;
}

.calendar-menu-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0.428rem;
  background-color: #00000033;
  visibility: hidden;
  opacity: 0;
  z-index: 10;

  transition: all 0.6s ease-in-out;
}

.calendar-menu-overlay.open {
  visibility: visible;
  opacity: 1;
}

/* DayGrid Custom Styling */
.style-appointment {
  padding-left: 5px;
  font-size: 11px;
  font-weight: 500;
}

.style-appointment * {
  color: var(--black);
}

/* Appointment Styles */
.style-appointment.unassigned > .fc-daygrid-event-dot,
.style-appointment.unassigned.fc-list-event .fc-list-event-dot {
  border-color: var(--bg-tertiary) !important;
}

.style-appointment.unassigned.fc-timegrid-event {
  background-color: var(--bg-tertiary) !important;
}

.style-appointment.assigned > .fc-daygrid-event-dot,
.style-appointment.assigned.fc-list-event .fc-list-event-dot {
  border-color: var(--utility-blue-800) !important;
}

.style-appointment.assigned.fc-timegrid-event {
  background-color: var(--utility-blue-800) !important;
}

.style-appointment.cancelled > .fc-daygrid-event-dot,
.style-appointment.cancelled.fc-list-event .fc-list-event-dot {
  border-color: var(--utility-error-600) !important;
}

.style-appointment.cancelled,
.style-appointment.cancelled.fc-list-event {
  color: var(--text-secondary) !important;
  text-decoration: line-through;
}

.style-appointment.cancelled.fc-timegrid-event {
  background-color: var(--utility-error-300) !important;
  color: var(--text-secondary) !important;
  text-decoration: line-through;
}

.style-appointment.cancelled.fc-timegrid-event .fc-event-main {
  color: var(--text-secondary) !important;
}

.style-appointment.completed > .fc-daygrid-event-dot,
.style-appointment.completed.fc-list-event .fc-list-event-dot {
  border-color: var(--utility-success-600) !important;
}

.style-appointment.completed.fc-timegrid-event {
  background-color: var(--utility-success-300) !important;
}

.style-appointment.no-show > .fc-daygrid-event-dot,
.style-appointment.no-show.fc-list-event .fc-list-event-dot {
  border-color: var(--utility-error-600) !important;
}

.style-appointment.no-show.fc-timegrid-event {
  background-color: var(--utility-error-300) !important;
}

.style-appointment.not-dealt > .fc-daygrid-event-dot,
.style-appointment.not-dealt.fc-list-event .fc-list-event-dot {
  border-color: var(--utility-warning-600) !important;
}

.style-appointment.not-dealt.fc-timegrid-event {
  background-color: var(--utility-warning-300) !important;
}

.style-appointment.ongoing > .fc-daygrid-event-dot,
.style-appointment.ongoing.fc-list-event .fc-list-event-dot {
  border-color: var(--utility-purple-600) !important;
}

.style-appointment.ongoing.fc-timegrid-event {
  background-color: var(--utility-purple-300) !important;
}

.fc-v-event {
  border: none;
}

.fc .fc-timegrid-col.fc-day-today {
  background: none;
}

.fc-col-header-cell.fc-day-today .fc-col-header-cell-cushion {
  background: var(--blue);
  color: white;
  border-radius: 20px;
  width: 100px;
}

.fc-popover {
  border-radius: 5px;
  box-shadow: 0 2px 17px rgb(0 0 0 / 35%) !important;
}

.fc-popover-header {
  padding: 10px 13px !important;
  font-weight: 500;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: transparent;
  margin-top: 12px;
}

.appointment-popout-status {
  background: var(--fg-secondary);
  color: white;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
}

.appointment-popout-status.popout-unassigned {
  background-color: var(--utility-gray-600);
}
.appointment-popout-status.popout-assigned {
  background-color: var(--utility-blue-900);
}
.appointment-popout-status.popout-cancelled {
  background-color: var(--utility-error-600);
}
.appointment-popout-status.popout-no-show {
  background-color: var(--utility-error-600);
}
.appointment-popout-status.popout-not-dealt {
  background-color: var(--utility-warning-600);
}
.appointment-popout-status.popout-completed {
  background-color: var(--utility-success-600);
}
.appointment-popout-status.popout-ongoing {
  background-color: var(--utility-purple-600);
}
</style>
