<template>
  <div v-for="period in groupedAppointments" :key="period.date" class="mb-5">
    <div class="mb-2 text-gray-400 text-sm">{{ dayjs(period.date).format('dddd, D MMMM YYYY') }}</div>
    <ListGroup>
      <ListGroupItem
        v-for="appointment in period.appointments"
        :key="appointment.id"
        :route="`/agreement/${appointment.agreementId}/appointments/${appointment.id}`"
        :class="{ 'line-through': appointment.status === 'cancelled', 'text-gray-400': dayjs(appointment.time).isBefore(dayjs()) }"
        class="items-center justify-center"
      >
        <div class="grid grid-cols-4 text-sm">
          <div class="flex items-center">
            <Avatar
              v-tippy="{ content: appointment.mappedMethod?.localeKey ? $t(appointment.mappedMethod?.localeKey) : 'Unknown Method' }"
              :icon="appointment.mappedMethod?.icon"
            >
            </Avatar>
            <div class="ml-5">
              <div class="text-gray-400 text-xs -mb-1">{{ appointment.reason }}Finance Review</div>
              <h6>{{ dayjs(appointment.time).format('h:mma') }}</h6>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center">
            <div><Icon type="location-dot" class="mr-1"></Icon>{{ appointment.dealershipName }}</div>
          </div>
          <div class="flex flex-col items-center justify-center">
            <div v-if="appointment.assignedToName != null" class="flex items-center justify-center">
              <Icon type="user-tie" class="mr-1"></Icon>
              {{ appointment.assignedToName }}
            </div>
          </div>
          <div class="flex flex-col items-end justify-center text-md">
            <div
              v-if="appointment.status != ''"
              class="ml-5 capitalize text-right opacity-100"
              :style="[`color: var(--${appointment.mappedStatus?.color});`]"
            >
              {{ $t(appointment.mappedStatus?.localeKey) }}
            </div>
            <div v-else class="text-gray-400">No Outcome</div>
          </div>
        </div>
      </ListGroupItem>
    </ListGroup>
  </div>
</template>

<script>
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Icon from '@/components/icon/Icon.vue'

import { computed } from 'vue'

import { getProperties } from '@/utilities/dataMapper'

import dayjs from 'dayjs'

export default {
  components: {
    ListGroup,
    ListGroupItem,
    Avatar,
    Icon
  },
  props: {
    appointmentsList: {
      type: Array,
      default: null
    }
  },
  setup(props) {
    const groupedAppointments = computed(() => {
      //Filter the array of appointments
      let dates = []

      props.appointmentsList.forEach(appointment => {
        appointment.date = dayjs(appointment.time).format('YYYY-MM-DD')
        appointment.mappedStatus = getProperties('appointmentStatus', appointment.status)
        appointment.mappedMethod = getProperties('appointmentMethod', appointment.method)
        dates.push(appointment.date)
      })

      //Remove duplicate dates
      let uniqueDates = [...new Set(dates)]
      uniqueDates.sort().reverse()

      //Create a new array with the appointments nested under the unique date
      let grouped = []
      uniqueDates.forEach(date => {
        //Get appointments with the same date
        grouped.push({
          date: date,
          appointments: props.appointmentsList.filter(app => {
            return app.date === date
          })
        })
      })

      // console.log(props.appointmentsList)

      return grouped
    })

    return { groupedAppointments }
  }
}
</script>

<style></style>
