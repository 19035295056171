<template>
  <PageHeader
    :title="$t('ui.reports.dealership-statistics')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.dealership', 2) },
      { label: $t('ui.common.statistic', 2) }
    ]"
  >
    <template #right>
      <div class="flex gap-2 items-center flex-col lg:flex-row md:gap-4">
        <div class="flex gap-2">
          <MultiSelect
            v-model="dealershipsSelected"
            :options="dealershipsList"
            display="chip"
            filter
            option-value="value"
            option-label="label"
            placeholder="Select a Dealership"
            :show-toggle-all="userStore.roles.includes(2) && userStore.roles.length == 1 ? false : true"
            :max-range="userStore.roles.includes(2) && userStore.roles.length == 1 ? 30 : null"
            class="max-w-[350px]"
          >
          </MultiSelect>
        </div>
        <div class="flex gap-2">
          <DatePicker v-model="dateRange" selection-mode="range" class="w-full" position="bottom-right" :number-of-months="2" />
        </div>
        <Button label="Update" :is-loading="dataIsLoading" @click="onDateRangeChange" />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="computedData"
      export-filename="Dealerships Stats"
      removable-sort
      sort-field="dealershipName"
      :sort-order="-1"
      :rows="50"
      data-key="dealershipName"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-reports-dealerships-stats"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['dealershipName']"
      scrollable
      :loading="dataIsLoading"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex w-full justify-between">
            <div class="flex gap-4">
              <span>
                <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
              </span>
              <Button
                :label="$t('ui.actions.clear', 1)"
                severity="secondary"
                type="button"
                outlined
                icon="filter-slash"
                @click="clearFilters()"
              />
            </div>
            <MultiSelect
              v-model="columnsSelected"
              :options="columns"
              option-label="header"
              display="chip"
              class="max-w-[200px]"
              placeholder="Select Columns"
              @update:model-value="onToggle"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="dealershipName" header="Dealership" :sortable="true" frozen>
        <template #body="{ data }">
          {{ data.dealershipName }}
        </template>
      </Column>
      <Column
        v-if="columnsSelected.find(col => col.field == 'targets.activeCustomers')"
        field="targets.activeCustomers"
        header="Active Cust"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'targets.callsAttempted')"
        field="targets.callsAttempted"
        header="Tar Attmpt"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'calls.attempted')"
        field="calls.attempted"
        header="Attempted"
        :sortable="true"
        class="whitespace-nowrap"
      >
        <template #body="{ data }">
          <span>{{ data.calls.attempted }}</span>
          <Chip
            v-if="userStore.details.type == 1"
            v-tippy="'% Attempted vs Tar.'"
            class="ml-2"
            size="sm"
            :severity="data.differences.callsAttempted >= 1 ? 'success' : 'danger'"
          >
            {{ numberFormatter(data.differences.callsAttempted * 100) }}%
          </Chip>
        </template>
      </Column>
      <Column field="differences.callsAttempted" header="% Attmpt" :sortable="true" hidden>
        <template #body="{ data }">
          <span> {{ numberFormatter(data.differences.callsAttempted * 100) }}% </span>
        </template>
      </Column>
      <Column
        v-if="columnsSelected.find(col => col.field == 'targets.callsAnswered')"
        field="targets.callsAnswered"
        header="Tar Answ"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'calls.answered')"
        field="calls.answered"
        header="Answered"
        :sortable="true"
        class="whitespace-nowrap"
      >
        <template #body="{ data }">
          <span>{{ data.calls.answered }}</span>
          <Chip
            v-if="userStore.details.type == 1"
            v-tippy="'% Answered vs Attempted'"
            class="ml-2"
            size="sm"
            :severity="data.differences.callsAnswered >= 1 ? 'success' : 'danger'"
          >
            {{ numberFormatter(data.differences.callsAnswered * 100) }}%
          </Chip>
        </template>
      </Column>
      <Column field="differences.callsAnswered" header="% Answ" hidden :sortable="true">
        <template #body="{ data }">
          <span> {{ numberFormatter(data.differences.callsAnswered * 100) }}% </span>
        </template>
      </Column>
      <Column
        v-if="columnsSelected.find(col => col.field == 'targets.callsOpportunity')"
        field="targets.callsOpportunity"
        header="Tar Oppt"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'calls.opportunities')"
        field="calls.opportunities"
        header="Oppt"
        :sortable="true"
        class="whitespace-nowrap"
      >
        <template #body="{ data }">
          <span>{{ data.calls.attempted }}</span>
          <Chip
            v-if="userStore.details.type == 1"
            v-tippy="'% Opportunity vs Answered'"
            class="ml-2"
            size="sm"
            :severity="data.differences.callsOpportunity > 1 ? 'success' : 'danger'"
          >
            {{ numberFormatter(data.differences.callsOpportunity * 100) }}%
          </Chip>
        </template>
      </Column>
      <Column field="differences.callsOpportunity" header="% Oppt" :sortable="true" hidden>
        <template #body="{ data }">
          <span> {{ numberFormatter(data.differences.callsOpportunity * 100) }}% </span>
        </template>
      </Column>
      <Column
        v-if="columnsSelected.find(col => col.field == 'targets.appointmentsBooked')"
        field="targets.appointmentsBooked"
        header="Tar Booked"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'appointments.booked')"
        field="appointments.booked"
        header="Booked"
        :sortable="true"
        class="whitespace-nowrap"
      >
        <template #body="{ data }">
          <span>{{ data.appointments.booked }}</span>
          <Chip
            v-if="userStore.details.type == 1"
            v-tippy="'% Booked vs Opportunity'"
            class="ml-2"
            size="sm"
            :severity="data.differences.appointmentsBooked >= 1 ? 'success' : 'danger'"
          >
            {{ numberFormatter(data.differences.appointmentsBooked * 100) }}%
          </Chip>
        </template>
      </Column>
      <Column field="differences.appointmentsBooked" header="% Booked" :sortable="true" hidden>
        <template #body="{ data }">
          <span> {{ numberFormatter(data.differences.appointmentsBooked * 100) }}% </span>
        </template>
      </Column>
      <Column
        v-if="columnsSelected.find(col => col.field == 'targets.appointmentsOccurring')"
        field="targets.appointmentsOccurring"
        header="Tar Occur"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'appointments.occurring')"
        field="appointments.occurring"
        header="Occurred"
        :sortable="true"
        class="whitespace-nowrap"
      >
        <template #body="{ data }">
          <span>{{ data.appointments.occurring }}</span>
          <Chip
            v-if="userStore.details.type == 1"
            v-tippy="'% Occurred vs Booked'"
            class="ml-2"
            size="sm"
            :severity="data.differences.appointmentsOccurring >= 1 ? 'success' : 'danger'"
          >
            {{ numberFormatter(data.differences.appointmentsOccurring * 100) }}%
          </Chip>
        </template>
      </Column>
      <Column field="differences.appointmentsOccurring" header="% Occur" :sortable="true" hidden>
        <template #body="{ data }">
          <span> {{ numberFormatter(data.differences.appointmentsOccurring * 100) }}% </span>
        </template>
      </Column>
      <Column
        v-if="columnsSelected.find(col => col.field == 'targets.appointmentsAttendances')"
        field="targets.appointmentsAttendances"
        header="Tar Attd"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'appointments.attended')"
        field="appointments.attended"
        header="Attd"
        :sortable="true"
        class="whitespace-nowrap"
      >
        <template #body="{ data }">
          <span>{{ data.appointments.attended }}</span>
          <Chip
            v-if="userStore.details.type == 1"
            v-tippy="'% Attended vs Occurred'"
            class="ml-2"
            size="sm"
            :severity="data.differences.appointmentsAttended >= 1 ? 'success' : 'danger'"
          >
            {{ numberFormatter(data.differences.appointmentsAttended * 100) }}%
          </Chip>
        </template>
      </Column>
      <Column field="differences.appointmentsAttended" header="% Attd" :sortable="true" hidden>
        <template #body="{ data }">
          <span> {{ numberFormatter(data.differences.appointmentsAttended * 100) }}% </span>
        </template>
      </Column>
      <Column
        v-if="columnsSelected.find(col => col.field == 'appointments.noShows')"
        field="appointments.noShows"
        header="No-Show"
        :sortable="true"
      />
      <Column
        v-if="columnsSelected.find(col => col.field == 'appointments.dealt')"
        field="appointments.dealt"
        header="Dealt"
        :sortable="true"
      />
      <Column field="differences.appointmentsDealt" header="% Dealt" :sortable="true" hidden>
        <template #body="{ data }">
          <span> {{ numberFormatter(data.differences.appointmentsDealt * 100) }}% </span>
        </template>
      </Column>

      <ColumnGroup type="footer">
        <Row>
          <Column :colspan="1" footer-style="text-align:left; padding: 14px 21px;">
            <template #footer>
              {{ $t('ui.common.total', 1) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'targets.activeCustomers')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.targets.activeCustomers || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'targets.callsAttempted')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.targets.callsAttempted || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'calls.attempted')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.calls.attempted || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'targets.callsAnswered')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.targets.callsAnswered || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'calls.answered')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.calls.answered || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'targets.callsOpportunity')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.targets.callsOpportunity || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'calls.opportunities')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.calls.opportunities || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'targets.appointmentsBooked')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.targets.appointmentsBooked || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'appointments.booked')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.appointments.booked || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'targets.appointmentsOccurring')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.targets.appointmentsOccurring || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'appointments.occurring')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.appointments.occurring || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'appointments.attended')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.appointments.attended || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'appointments.noShows')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.appointments.noShows || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="columnsSelected.find(col => col.field == 'appointments.dealt')" footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.appointments.dealt || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
        </Row>
      </ColumnGroup>
    </DataTable>
  </Card>
</template>

<script>
import { ref, computed } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'
import Card from '@/components/card/Card.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import dayjs from 'dayjs'
import Chip from '@/components/chip/Chip.vue'

export default {
  components: {
    PageHeader,
    MultiSelect,
    DatePicker,
    Card,
    Input,
    Button,
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Chip
  },
  setup() {
    const dt = ref({ processedData: [] })
    const userStore = useUserStore()
    // const dealershipsSelected = ref([
    //   JSON.parse(localStorage.getItem('accessSelected')).dealerships[0] ?? userStore.accessAllowed.dealerships[0].id
    // ])
    const dealershipsSelected = ref([])
    const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
    const dealershipsList = computed(() => {
      return dealershipsAllowed.value.map(d => {
        return {
          value: d.id,
          label: d.name
        }
      })
    })

    const columns = ref([
      { field: 'dealershipName', header: 'Dealership', allowedUserTypes: [1, 2], hideByDefault: false },
      { field: 'targets.activeCustomers', header: 'Active Customers', allowedUserTypes: [1, 2], hideByDefault: false },
      { field: 'targets.callsAttempted', header: 'Tar. Attempted', allowedUserTypes: [1], hideByDefault: true },
      { field: 'calls.attempted', header: 'Attempted', allowedUserTypes: [1, 2], hideByDefault: false },
      {
        field: 'targets.callsAnswered',
        header: 'Tar. Answered',
        allowedUserTypes: [1],
        hideByDefault: true
      },
      {
        field: 'calls.answered',
        header: 'Answered',
        allowedUserTypes: [1, 2],
        hideByDefault: false
      },
      {
        field: 'targets.callsOpportunity',
        header: 'Tar. Opportunities',
        allowedUserTypes: [1],
        hideByDefault: true
      },
      {
        field: 'calls.opportunities',
        header: 'Opportunities',
        allowedUserTypes: [1, 2],
        hideByDefault: true
      },
      {
        field: 'targets.appointmentsBooked',
        header: 'Tar. Booked',
        allowedUserTypes: [1],
        hideByDefault: true
      },
      {
        field: 'appointments.booked',
        header: 'Booked',
        allowedUserTypes: [1, 2],
        hideByDefault: false
      },
      {
        field: 'targets.appointmentsOccurring',
        header: 'Tar. Occurred',
        allowedUserTypes: [1],
        hideByDefault: true
      },
      {
        field: 'appointments.occurring',
        header: 'Occurred',
        allowedUserTypes: [1, 2],
        hideByDefault: false
      },
      {
        field: 'targets.appointmentsAttendances',
        header: 'Tar. Attended',
        allowedUserTypes: [1],
        hideByDefault: true
      },
      {
        field: 'appointments.attended',
        header: 'Attended',
        allowedUserTypes: [1, 2],
        hideByDefault: false
      },
      {
        field: 'appointments.noShows',
        header: 'No-Show',
        allowedUserTypes: [1, 2],
        hideByDefault: false
      },
      {
        field: 'appointments.dealt',
        header: 'Dealt',
        allowedUserTypes: [1, 2],
        hideByDefault: true
      }
    ])

    columns.value = columns.value.filter(col => col.allowedUserTypes.includes(userStore.details.type))

    const columnsSelected = ref([])
    columnsSelected.value = columns.value.filter(col => col.hideByDefault == false)

    const onToggle = val => {
      columnsSelected.value = columns.value.filter(col => val.includes(col))
    }

    const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()])

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dealershipName: { value: null, matchMode: FilterMatchMode.IN }
    })

    const clearFilters = () => {
      // console.log('Clearing Filter')
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dealershipName: { value: null, matchMode: FilterMatchMode.IN }
      }
    }

    const dealershipStats = ref([])

    const apiRequest = useApiRequest()
    const dataIsLoading = ref(false)
    function fetchData() {
      dataIsLoading.value = true

      apiRequest
        .send({
          endpoint: '/v1/reports/dealerships/statistics',
          params: {
            dealerships: dealershipsSelected.value.join(','),
            start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
            end: dayjs(dateRange.value[1]).format('YYYY-MM-DD')
          }
        })
        .then(response => {
          dealershipStats.value = response.data
          dataIsLoading.value = false
          console.log(dealershipStats.value)
        })
    }

    function onDateRangeChange() {
      console.log('onDateRangeChange', dateRange.value)
      // Refetch reloops
      if (dealershipsSelected.value.length && dateRange.value[0] && dateRange.value[1]) {
        fetchData()
      }
    }

    const tableData = computed(() => {
      return dt.value.processedData
    })

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const actualVsTarget = (actual, target) => {
      return (target ? actual / target : 0).toFixed(2)
    }

    const computedData = computed(() => {
      let dealershipsData = []
      // Loop through each dealership
      dealershipStats.value.forEach(dealership => {
        let dealershipData = {
          dealershipName: dealership.dealershipName,
          targets: dealership.targets,
          calls: dealership.calls,
          appointments: dealership.appointments,
          differences: {
            callsAttempted: actualVsTarget(dealership.calls.attempted, dealership.targets.callsAttempted),
            callsAnswered: actualVsTarget(dealership.calls.answered, dealership.targets.callsAnswered),
            callsOpportunity: actualVsTarget(dealership.calls.opportunities, dealership.targets.callsOpportunity),
            appointmentsBooked: actualVsTarget(dealership.appointments.booked, dealership.targets.appointmentsBooked),
            appointmentsOccurring: actualVsTarget(dealership.appointments.occurring, dealership.targets.appointmentsOccurring),
            appointmentsAttended: actualVsTarget(dealership.appointments.attended, dealership.targets.appointmentsAttendances),
            appointmentsDealt: actualVsTarget(dealership.appointments.dealt, dealership.appointments.attended)
          }
        }
        dealershipsData.push(dealershipData)
      })
      return dealershipsData
    })

    return {
      dt,
      exportCSV,
      dateRange,
      dealershipsList,
      dealershipsSelected,
      dataIsLoading,
      dealershipStats,
      computedData,
      clearFilters,
      filters,
      onDateRangeChange,
      columnsSelected,
      columns,
      onToggle,
      tableData,
      actualVsTarget,
      userStore
    }
  }
}
</script>

<style></style>
