<template>
  <div class="carousel-default">
    <Carousel :value="vehicles" :num-scroll="5" :num-visible="5" :responsive-options="responsiveOptions">
      <template #item="slotProps">
        <div class="card rounded-md m-2">
          <div class="carousel-contained mb-3">
            <Carousel :value="slotProps.data.images">
              <template #item="imagesProp">
                <img :src="imagesProp.data.url" :alt="slotProps.data.make + ' ' + slotProps.data.model" class="rounded-t" />
              </template>
            </Carousel>
          </div>
          <div class="pb-4 px-6">
            <div class="flex items-center mb-2">
              <!-- <Avatar :is-logo="true" url="https://cdn.retainauto.com/img/logos/manufacturers/triumph-square.svg" /> -->
              <img src="https://cdn.retainauto.com/img/logos/manufacturers/triumph-square.svg" width="25" />

              <div class="ml-2 w-full">
                <div class="text-sm text-secondary">{{ slotProps.data.make }}</div>
                <div class="-mt-[0.4rem] text-md">{{ slotProps.data.model }}</div>
              </div>
              <div>
                <Icon
                  class="cursor-pointer text-lg"
                  type="heart"
                  :fa-style="isFavourited(slotProps.data.id) ? 'fas' : 'far'"
                  :class="isFavourited(slotProps.data.id) ? 'text-danger' : 'far'"
                  @click="toggleFavourite(slotProps.data.id)"
                />
              </div>
            </div>
            <div class="mb-4">
              <Chip size="sm" class="mr-1">Petrol</Chip>
              <Chip size="sm">Manual</Chip>
            </div>
            <div class="flex justify-between">
              <div class="">
                <div class="text-sm text-secondary -mb-1">Cash</div>
                <h5>{{ $n(slotProps.data.price, 'currency', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}</h5>
              </div>
              <div v-if="slotProps.data.finance" class="text-right">
                <div class="text-sm text-secondary -mb-1">Monthly ({{ slotProps.data.finance.type }})</div>
                <h5>{{ $n(slotProps.data.finance.amount, 'currency', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}</h5>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Carousel>
  </div>
</template>

<script>
import Carousel from 'primevue/carousel'

import { ref, toRefs } from 'vue'

import Chip from '@/components/chip/Chip.vue'
import Icon from '@/components/icon/Icon.vue'

export default {
  name: 'DeskingSheet',
  components: { Carousel, Chip, Icon },
  props: {
    vehicles: {
      type: Array,
      default: () => []
    },
    favourites: {
      type: Array,
      default: () => []
    },
    vehicleCondition: {
      type: String,
      default: 'used'
    }
  },
  emits: ['update:favourites'],
  setup(props, { emit }) {
    const { favourites } = toRefs(props)

    const responsiveOptions = ref([
      {
        breakpoint: '1600px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '1500px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '900px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      }
    ])

    const isFavourited = itemID => {
      return props.favourites.includes(itemID)
    }

    function toggleFavourite(itemID) {
      // let action = isFavourited(itemID) ? 'remove' : 'add'
      // emit('favouriteToggled', { itemID, action })

      const favouritesCopy = [...favourites.value]

      if (favouritesCopy.includes(itemID)) {
        const index = favouritesCopy.indexOf(itemID)
        if (index > -1) {
          favouritesCopy.splice(index, 1)
        }
      } else {
        favouritesCopy.push(itemID)
      }

      emit('update:favourites', favouritesCopy)
    }

    return { responsiveOptions, isFavourited, toggleFavourite }
  }
}
</script>

<style>
/* Default Styling */
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  border-radius: 10px;
  height: 10px;
  border-radius: 20px;
}

/* Contained Style */
.carousel-contained .p-carousel-content {
  position: relative;
}
.carousel-contained .p-carousel-container {
  position: relative;
}
.carousel-contained .p-carousel .p-carousel-content .p-carousel-prev,
.carousel-contained .p-carousel .p-carousel-content .p-carousel-next {
  position: absolute;
  top: 36%;
  z-index: 1;
  background-color: white;
  height: 1.5rem;
  width: 1.5rem;
}
.carousel-contained .p-carousel .p-carousel-content .p-carousel-prev {
  left: 0;
}
.carousel-contained .p-carousel .p-carousel-content .p-carousel-next {
  right: 0;
}
.carousel-contained .p-carousel .p-carousel-container > button {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
}
.carousel-contained .p-carousel:hover .p-carousel-container > button {
  opacity: 100;
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
}
.carousel-contained .p-carousel .p-carousel-indicators {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.carousel-contained .p-carousel .p-carousel-indicators .p-carousel-indicator button {
  border-radius: 10px;
  height: 8px;
  width: 8px;
  border-radius: 20px;
  opacity: 50%;
}
.carousel-contained .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  opacity: 100%;
  background: white;
}
</style>
