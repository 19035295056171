import { io } from 'socket.io-client'
import { ref } from 'vue'

import * as Sentry from '@sentry/vue'

import { useUserStore } from '@/stores/UserStore'

import alertToast from '@/utilities/alertToast'
import notification from '@/utilities/notification.js'

import ability from '@/config/ability.js'

// console.log('Running Socket Service File')
// console.log('Socket Server: ' + import.meta.env.VITE_SOCKET_IO_SERVER_URL)

// const socket = io("http://localhost:30000", { transports: ["websocket"] }) //INFO: use this for dev server testing
const socket = io(import.meta.env.VITE_SOCKET_IO_SERVER_URL, {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 50
})

let usersPresence = ref([])
let socketIsConnected = ref(false)

socket.on('connect', () => {
  // console.log(`Socket IO Connected! SocketID: ${socket.id}`)

  const userStore = useUserStore()

  //Update socket status in user store
  userStore.updateSocketStatus(socket.connected)

  // If is reconnecting
  if (!socketIsConnected.value) {
    userStore.registerUserSocket()

    // emit user-update
    let dealershipIds = userStore.accessAllowed.dealerships.map(dealership => dealership.id)

    socket.emit('user-update', {
      uid: userStore.details.id,
      ut: userStore.details.type,
      dealershipIds,
      canMakeCalls: ability.can('make_calls'),
      canReceiveCalls: ability.can('receive_calls')
    })
  }

  socketIsConnected.value = socket.connected

  //Listen to the socket.io heartbeat for debugging
  // socket.io.engine.on('heartbeat', function () {
  //   console.log('heartbeat')
  // })

  socket.on('users-all', users => {
    console.log('User Presence EMITTED from server')
    console.log(users)

    usersPresence.value = users
    // console.log('SERVICE: ' + usersPresence.value)
    userStore.updateAllUsers(usersPresence.value)
  })

  socket.on('user-updated', user => {
    // console.log('User Updated EMITTED from server')
    // console.log(user)
    userStore.updateSingleUser(user)
  })

  socket.on('user-clear-localstorage', () => {
    // console.log('User Clear Local Storage request received')

    localStorage.clear()
    window.location.reload()
  })

  socket.on('user-signout', () => {
    // console.log('User SignOut request received')

    userStore.signOut()
  })

  socket.on('new-notification', data => {
    console.log('New Notification received')

    notification({}, data)
  })
})

socket.on('disconnect', reason => {
  console.log(`Socket IO disconnected! Reason: ${reason}`)
  // Sentry.captureMessage(`Socket IO disconnected! Reason: ${reason}`, 'debug')

  const userStore = useUserStore()

  //Remove all users from user store
  userStore.removeAllUsers()

  // Update socket status in user store
  userStore.updateSocketStatus(socket.connected)
  socketIsConnected.value = socket.connected

  if (userStore.details.type === 1) {
    alertToast('Disconnected', 'Reconnecting...', 'info')
  } else {
    // alertToast('Disconnected', 'Please refresh the page', 'info')
  }
})

export default socket
