import { useToast } from 'vue-toastification'
import CustomToast from '@/components/notifications/NotificationToast.vue'
import NotificationToastCloseButton from '@/components/notifications/NotificationToastCloseButton.vue'

import dayjs from 'dayjs'
import router from '@/router'

/**
 * Displays a notification toast with the given parameters.
 *
 * @param {Object} params - The parameters for the notification toast.
 * @param {Object} notificationData - Additional data to be passed to the toast. (eg name, titles, images, etc)
 */
export default function notification(params, notificationData) {
  let defaultParams = {
    design: 'info', // alert, info
    title: 'Notification',
    text: 'This is a notification',
    severity: null,
    icon: 'info',
    avatarUrl: null,
    avatarText: null,
    position: 'top-right',
    timeout: 5000,
    showDismissButton: true,
    showCloseButton: true,
    showActionButton: false,
    actionButtonText: 'View',
    actionButtonLink: null,
    hideProgressBar: true
  }

  const props = { ...defaultParams, ...params }

  if (notificationData?.notificationId) {
    console.log('notificationData', notificationData.data)

    if (notificationData.notificationId === 1) {
      props.design = 'info'
      props.title = 'New Appointment'
      props.text = `Booked for ${notificationData.data.customerName} at ${dayjs(notificationData.data.dateTime).format('ll')}`
      props.severity = 'info'
      props.icon = 'calendar-plus'
      props.showDismissButton = true
      props.showActionButton = true
      props.actionButtonLink = `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
    }
    if (notificationData.notificationId === 2) {
      props.design = 'info'
      props.title = 'Appointment Updated'
      props.text = `Appointment for ${notificationData.data.customerName} has changed`
      props.severity = 'info'
      props.icon = 'calendar-pen'
      props.showDismissButton = true
      props.showActionButton = true
      props.actionButtonLink = `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
    }
    if (notificationData.notificationId === 3) {
      props.design = 'info'
      props.title = 'Appointment Cancelled'
      props.text = `${notificationData.data.customerName} cancelled their appointment`
      props.severity = 'danger'
      props.icon = 'calendar-times'
      props.showDismissButton = true
      props.showActionButton = true
      props.actionButtonLink = `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
    }
    if (notificationData.notificationId === 4) {
      props.design = 'info'
      props.title = 'New Appointment Assigned'
      props.text = `You will be seeing ${notificationData.data.customerName} at ${dayjs(notificationData.data.dateTime).format('LT')} on ${dayjs(notificationData.data.dateTime).format('ll')}`
      props.severity = 'info'
      props.icon = 'calendar-circle-user'
      props.showDismissButton = true
      props.showActionButton = true
      props.actionButtonLink = `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
    }

    // Valuations
    if (notificationData.notificationId === 14) {
      props.design = 'info'
      props.title = 'New Valuation'
      props.text = `${notificationData.data.customerName} requires their vehicle to be valued`
      props.severity = 'info'
      props.icon = 'car'
      props.showDismissButton = true
      props.showActionButton = true
      props.actionButtonLink = `/vehicles/valuations/${notificationData.data.valuationId}`
    }

    // ReAuction
    if (notificationData.notificationId === 14) {
      props.design = 'info'
      props.title = 'New ReAuction'
      props.text = `A new vehicle is up for auction`
      props.severity = 'secondary'
      props.icon = 'car'
      props.showDismissButton = true
      props.showActionButton = true
      props.actionButtonLink = `/vehicles/reauction/${notificationData.data.valuationId}`
    }
  }

  function redirectTo(url) {
    // determine if url or route
    if (url.includes('http')) {
      console.log('Opening in new tab')

      window.open(url, '_blank')
    } else {
      console.log('Navigating to route: ', url)

      router.push(url).catch(err => console.error('Router error:', err))
    }
  }

  const toast = useToast()

  const config = {
    position: props.position,
    timeout: props.timeout,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: props.hideProgressBar,
    closeButton: props.showCloseButton ? NotificationToastCloseButton : false,
    icon: false,
    rtl: false,
    transition: 'Vue-Toastification__fade'
  }

  const toastId = toast(
    {
      component: CustomToast,
      props: props,
      listeners: {
        click: () => console.log('Clicked!'),
        dismissBtnClick: () => toast.dismiss(toastId),
        viewBtnClick: () => redirectTo(props.actionButtonLink)
      }
    },
    config
  )
}
