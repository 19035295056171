import axios from 'axios'
import { ref } from 'vue'
import alertToast from '@/utilities/alertToast'

const getInvoices = status => {
  let invoices = ref([])
  let error = ref(null)

  const load = async () => {
    // console.log('Getting Data Axios')
    let url = import.meta.env.VITE_API_BASE_URL + '/v1/finance/invoices'

    if (status) {
      url = import.meta.env.VITE_API_BASE_URL + '/v1/finance/invoices?status=' + status
    }

    try {
      let data = await axios.get(url, { withCredentials: true })
      // console.log(data.data.data)

      if (data.status != 200) {
        if (data.http.status === 401) {
          error.value = '401'
          throw Error('401 Unauthorized')
        } else {
          error.value = 'Error fetching data'
          throw Error('Error fetching data')
        }
      }
      //If everything is fine
      invoices.value = data.data.data

      // console.log(chartRef);
    } catch (err) {
      console.log(err.message)
      alertToast('Error', err.message, 'error')
    }
  }

  return { invoices, error, load }
}
export default getInvoices
