<template>
  <PageHeader
    title="Appointments"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.appointment.label', 2), to: '/reports/appointments/list' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <div class="px-4 border border-secondary rounded-md flex items-center gap-2 h-[35px] whitespace-nowrap">
          <div>Based on date</div>
          <Dropdown>
            <template #triggerContent>
              <div class="link">{{ dateBasis }} <Icon type="chevron-down" /></div>
            </template>
            <DropdownItem @click="dateBasisChange('occurring')">Occurring</DropdownItem>
            <DropdownItem @click="dateBasisChange('created')">Created</DropdownItem>
          </Dropdown>
        </div>
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          position="bottom-right"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Appointments List"
      :value="appointments"
      removable-sort
      sort-field="start"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['customerName', 'dealershipName', 'createdByName', 'assignedToName']"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-appointment-list"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="appointmentsIsLoading"
      :export-function="formatExportData"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="time" header="Date" :sortable="true">
        <template #body="{ data }">
          <a class="link" :href="'/agreement/' + data.agreementId + '/appointments/' + data.id">
            <div class="whitespace-nowrap">{{ dayjs(data.time).format('Do MMM YY H:mm') }}</div>
          </a>
        </template>
      </Column>

      <Column field="customerName" header="Customer" :sortable="true">
        <template #body="{ data }">
          {{ data.customerName }}
        </template>
      </Column>

      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div>{{ data.dealershipName }}</div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="status" header="Status" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div :style="[`color: var(--${getProperties('appointmentStatus', data.status)?.color});`]">
            {{ $t(getProperties('appointmentStatus', data.status)?.localeKey) }}
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: 'ongoing', label: 'Ongoing' },
              { value: 'completed', label: 'Completed' },
              { value: 'unassigned', label: 'Unassigned' },
              { value: 'assigned', label: 'Assigned' },
              { value: 'cancelled', label: 'Cancelled' },
              { value: 'no-show', label: 'No-Show' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="method" header="Method" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Icon
            v-if="data.method"
            v-tippy="$t(getProperties('appointmentMethod', data.method)?.localeKey)"
            :type="$t(getProperties('appointmentMethod', data.method)?.icon)"
          />
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: 'physical', label: 'At Dealership' },
              { value: 'phone', label: 'Phone Call' },
              { value: 'video', label: 'Video Call' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="outcome" header="Outcome" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Icon
            v-if="data.outcome"
            v-tippy="$t(getProperties('appointmentOutcome', data.outcome)?.localeKey)"
            :type="$t(getProperties('appointmentOutcome', data.outcome)?.icon)"
            :style="[`color: var(--${getProperties('appointmentStatus', data.status)?.color});`]"
          />
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: 'no-show', label: 'No-Show' },
              { value: 'completed', label: 'Completed' },
              { value: 'ongoing', label: 'Ongoing' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="decision" header="Decision" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="data.decision">{{ $t(getProperties('appointmentDecision', data.decision)?.localeKey) }}</div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: 'not_ready', label: 'Not Ready' },
              { value: 'undecided', label: 'Undecided' },
              { value: 'part_exchanged', label: 'Part Exchanged' },
              { value: 'poor_offer', label: 'Poor Offer' },
              { value: 'refinanced', label: 'Refinanced' },
              { value: 'settled', label: 'Settled' },
              { value: 'handed_back', label: 'Handed Back' },
              { value: 'buy_back', label: 'Buy Back' },
              { value: 'new_lease', label: 'New Lease' },
              { value: 'extended', label: 'Extended' },
              { value: 'keeping', label: 'Keeping' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="note" header="Note">
        <template #body="{ data }">
          <Icon v-tippy="{ content: data.note }" type="eye" />
        </template>
      </Column>

      <Column field="createdBy" header="Booked By" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          {{ data.createdByName }}
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              <Avatar :url="slotProps.option.profilePicUrl" :text="slotProps.option.initials" size="sm" class="mr-3" />
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="assignedTo" header="Assigned To" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="data.assignedToName">{{ data.assignedToName }}</div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              <Avatar :url="slotProps.option.profilePicUrl" :text="slotProps.option.initials" size="sm" class="mr-3" />
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="createdAt" header="Date Created" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="{ content: data.createdAt + ' GMT' }">
            {{ dayjs(data.createdAt).format('ll') }}
          </div>
        </template>
      </Column>

      <Column v-if="$can('delete_appointments')">
        <template #body="{ data }">
          <Dropdown position="bottom-right">
            <template #triggerContent>
              <Button icon="ellipsis-vertical" plain severity="secondary"></Button>
            </template>
            <DropdownItem @item-clicked="deleteAppointment(data.id)">
              <Icon type="trash" class="text-error" />
              <span class="w-full">{{ $t('ui.actions.delete') }}</span>
            </DropdownItem>
          </Dropdown>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import Swal from 'sweetalert2'
import alertToast from '@/utilities/alertToast'
import dayjs from 'dayjs'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import { useBreakPoints } from '@/composables/useWindowSize'
import { getProperties } from '@/utilities/dataMapper'

import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    Card,
    PageHeader,
    DataTable,
    Icon,
    Avatar,
    Dropdown,
    DropdownItem,
    Column,
    MultiSelect,
    DatePicker,
    Input,
    Button
  },
  setup() {
    const dt = ref()

    const route = useRoute()
    const router = useRouter()

    const dateBasis = ref(localStorage.getItem('report-appointment-list-datebasis') || 'occurring')
    const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
    const appointmentsApiRequest = useApiRequest()
    const deleteAppointmentApiRequest = useApiRequest()
    const userStore = useUserStore()

    const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
    const appointments = ref([])
    const users = ref([])
    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dealershipId: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.IN },
        method: { value: null, matchMode: FilterMatchMode.IN },
        outcome: { value: null, matchMode: FilterMatchMode.IN },
        decision: { value: null, matchMode: FilterMatchMode.IN },
        createdBy: { value: null, matchMode: FilterMatchMode.IN },
        assignedTo: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    initFilters()

    function clearFilters() {
      //remove from session storage
      sessionStorage.removeItem('dt-report-appointment-list')
      initFilters()
    }

    function setRouteFilters() {
      if (route.query && Object.keys(route.query).length > 0) {
        clearFilters()
        for (const [key, value] of Object.entries(route.query)) {
          if (key === 'dealerships') {
            filters.value.dealershipId.value = value.split(',').map(Number)
            console.log('set dealerships', filters.value.dealershipId.value)
          }
          if (key === 'status') {
            filters.value.status.value = value.split(',')
            console.log('status', filters.value.status.value)
          }
          if (key === 'outcome') {
            filters.value.outcome.value = value.split(',')
            console.log('outcome', filters.value.outcome.value)
          }
          if (key === 'dateBasis') {
            dateBasis.value = value
            // Save in local storage for persistence
            localStorage.setItem('report-appointment-list-datebasis', value)
            console.log('dateBasis', dateBasis.value)
          }
          if (key === 'dateRange') {
            dateRange.value = value.split(',').map(d => new Date(d))
            console.log('dateRange', dateRange.value)
          }
        }
      }
      // Remove url query params  using router replace
      router.replace({ query: {} })
    }

    setRouteFilters()

    function fetchAppointments() {
      appointmentsApiRequest
        .send({
          endpoint: '/v1/appointments',
          params: {
            view: 'full',
            dateBasis: dateBasis.value,
            start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
            end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
            dealerships: dealershipsAllowed.value.map(d => d.id).join(',')
          }
        })
        .then(response => {
          if (response.success) {
            appointments.value = response.data
          }
        })
    }
    fetchAppointments()

    function deleteAppointment(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This action cannot be undone - not even by an admin!',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete this appointment',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel'
      }).then(function (swalResponse) {
        if (swalResponse.value.isConfirmed && id) {
          try {
            deleteAppointmentApiRequest
              .send({
                endpoint: `/v1/appointments/${id}`,
                method: 'DELETE'
              })
              .then(response => {
                if (response.success) {
                  alertToast('Deleted', null, 'success')

                  // Remove appointment from table data
                  let appointment = appointments.value.find(appointment => appointment.id == id)
                  let index = appointments.value.indexOf(appointment)

                  if (index) {
                    appointments.value.splice(index, 1)
                  }
                } else {
                  alertToast('Failed to delete', response.data?.message, 'error')
                }
              })
          } catch (err) {
            alertToast('Failed to delete', err.message, 'error')
          }
        }
      })
    }

    function onDateRangeChange() {
      if (dateRange.value[0] && dateRange.value[1]) {
        fetchAppointments()
      }
    }

    function dateBasisChange(value) {
      dateBasis.value = value
      //set ls for persistence
      localStorage.setItem('report-appointment-list-datebasis', value)
      fetchAppointments()
    }

    // Users List
    const usersApiRequest = useApiRequest()
    usersApiRequest.send({ endpoint: '/v1/users?userType=all' }).then(response => {
      console.log(response)
      if (response.success) {
        users.value = response.data
      }
    })

    const formatDecisionText = str => {
      let splitString = str.split('_')
      return splitString.map(s => s[0].toUpperCase() + s.slice(1)).join(' ')
    }

    const dealershipsList = computed(() => {
      return dealershipsAllowed.value.map(d => {
        return {
          value: d.id,
          label: d.name
        }
      })
    })

    const usersList = computed(() => {
      // if no users, return empty array
      if (!users.value) return []

      return users.value.map(u => {
        return {
          value: u.id,
          label: u.fullName,
          profilePicUrl: u.profilePicURL,
          initials: u.initials
        }
      })
    })

    function formatExportData({ data, field }) {
      const stripQuotes = text => text.replace(/"/g, '')

      switch (field) {
        case 'dealershipId':
          return dealershipsList.value.find(d => d.value === data)?.label ?? 'error'
        case 'createdBy':
          return usersList.value.find(u => u.value === data)?.label ?? ''
        case 'assignedTo':
          return usersList.value.find(u => u.value === data)?.label ?? ''
        case 'note':
          return stripQuotes(data)
        default:
          return data
      }
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    return {
      dt,
      useBreakPoints,
      dateBasis,
      dateBasisChange,
      dateRange,
      appointmentsIsLoading: appointmentsApiRequest.isLoading,
      appointments,
      formatDecisionText,
      onDateRangeChange,
      deleteAppointment,
      filters,
      dealershipsList,
      formatExportData,
      exportCSV,
      clearFilters,
      getProperties,
      usersList
    }
  }
}
</script>

<style></style>
