<template>
  <PageHeader
    :title="$t('ui.entities.manufacturer', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.entities.manufacturer', 2), to: '/settings/manufacturers' }
    ]"
  >
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Manufacturers"
      :value="manufacturers"
      :loading="isLoading"
      removable-sort
      sort-field="id"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-manufacturers"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'name']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
      </template>

      <Column field="id" header="Id" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.id }}</div>
        </template>
      </Column>

      <Column field="name" header="Name" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.name }}</div>
        </template>
      </Column>

      <Column field="squareLogoUrl" header="Square logo">
        <template #body="{ data }">
          <Image :src="data.squareLogoUrl" image-class="max-w-8" />
        </template>
      </Column>

      <Column field="logoUrl" header="Logo">
        <template #body="{ data }">
          <Image :src="data.logoUrl" image-class="h-8 max-w-16" />
        </template>
      </Column>

      <Column field="accentColor" header="Brand Colour">
        <template #body="{ data }">
          <div class="flex items-center gap-2">
            <Chip v-if="data.accentColor" :color="data.accentColor">{{ _toUpper(data.accentColor) }}</Chip>
          </div>
        </template>
      </Column>

      <Column field="regions" header="Regions">
        <template #body="{ data }">
          <div class="flex gap-2">
            <Chip v-for="(region, index) in data.regions" :key="index">
              <img :src="regionsList.find(r => r.value == region.toLowerCase()).image" class="w-5 mr-2" />
              {{ region.toUpperCase() }}</Chip
            >
          </div>
        </template>
      </Column>

      <Column field="isActive" header="Active" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Chip v-if="data.isActive" label outlined size="sm" dot severity="success">Active</Chip>
          <Chip v-else label outlined size="sm" dot severity="danger">Disabled</Chip>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: 1, label: 'Active' },
              { value: 0, label: 'Disabled' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'
import Chip from '@/components/chip/Chip.vue'
import Image from '@/components/image/Image.vue'

import useApiRequest from '@/composables/useApiRequest'
import alertToast from '@/utilities/alertToast'

import { FilterMatchMode } from 'primevue/api'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import MultiSelect from 'primevue/multiselect'
import _toUpper from 'lodash/toUpper'
import { useI18n } from 'vue-i18n'

const manufacturers = ref(null)
const manufacturersApiRequest = useApiRequest()
const isLoading = manufacturersApiRequest.isLoading

const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    isActive: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  //remove from session storage
  sessionStorage.removeItem('dt-settings-manufacturers')
  initFilters()
}

const regionsList = [
  {
    label: useI18n().t('meta.countries.gb.label'),
    value: 'gb',
    emoji: '🇬🇧',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'
  },
  {
    label: useI18n().t('meta.countries.us.label'),
    value: 'us',
    emoji: '🇺🇸',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'
  },
  {
    label: useI18n().t('meta.countries.es.label'),
    value: 'es',
    emoji: '🇪🇸',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg'
  },
  {
    label: useI18n().t('meta.countries.mx.label'),
    value: 'mx',
    emoji: '🇲🇽',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg'
  },
  {
    label: useI18n().t('meta.countries.fr.label'),
    value: 'fr',
    emoji: '🇫🇷',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg'
  }
]

function fetchManufacturers() {
  manufacturersApiRequest
    .send({
      endpoint: '/v1/manufacturers'
    })
    .then(response => {
      console.log(response)
      if (response.success) {
        manufacturers.value = response.data
      } else {
        alertToast('Error', 'Failed to fetch manufacturers', 'error')
      }
    })
}
fetchManufacturers()
</script>

<style></style>
