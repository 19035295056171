<template>
  <Sidebar v-model:is-open="sidebarIsOpen" :has-changes="hasUnsavedChanges" @close-sidebar="closeSidebar">
    <SidebarHeader
      icon="calendar-plus"
      :title="
        isNewAppointment ? $t('ui.crud.new', { item: $t('ui.entities.appointment.label') }) : $t('ui.entities.appointment.reschedule')
      "
      description="Book a new appointment"
    />
    <SidebarContent>
      <form ref="form" @submit.prevent="appointmentHandlerSubmit">
        <FormGroup v-if="userStore?.details.type == 1" label="Booked By">
          <RadioTabbed
            v-model="state.source"
            :options="[
              { value: 'retain', label: 'Retain' },
              { value: 'dealer', label: 'Dealer' }
            ]"
            full-width
            :disabled="userStore?.details.type != 1 ? true : false"
            @option-change="setAvailableMethods"
          />
        </FormGroup>
        <FormGroup label="Type">
          <Multiselect v-model="state.type" mode="single" :can-clear="false" :can-deselect="false" :options="typeIntentOptions" />
        </FormGroup>
        <FormGroup label="Intent">
          <Multiselect
            v-model="state.intent"
            mode="single"
            :can-clear="false"
            :can-deselect="false"
            searchable
            :options="intentsComputed"
            :class="{ 'has-error': v$.intent.$error }"
          />
          <InputError :has-error="v$.intent.$error">{{ v$.intent.$errors[0].$message }}</InputError>
        </FormGroup>
        <FormGroup label="Dealership">
          <Multiselect
            v-model="state.dealershipID"
            mode="single"
            :can-clear="false"
            :can-deselect="false"
            :searchable="true"
            label="displayName"
            value-prop="id"
            track-by="name"
            :options="dealerships.data.value"
            :class="{ 'has-error': v$.dealershipID.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.dealershipID.$error">{{ v$.dealershipID.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.task.assigned-to')" label-for="assignedTo" :class="{ hidden: !$can('assign_appointments') }">
          <Multiselect
            ref="assignmentMultiselect"
            v-model="state.assignedTo"
            mode="single"
            label="fullName"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="assignableUsers.data.value"
            track-by="fullName"
            value-prop="id"
            placeholder="Select a User"
          >
            <template #placeholder="{}">
              <div class="multiselect-placeholder"><Icon type="user" size="sm" class="mr-3" /> Select a user</div>
            </template>
            <!-- <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <Avatar :url="value.profilePicURL" :text="value.initials" size="sm" class="mr-3" /> {{ value.fullName }}
              </div>
            </template>

            <template #option="{ option }">
              <Avatar :url="option.profilePicURL" :text="option.initials" size="sm" class="mr-3" /> {{ option.fullName }}
            </template> -->
          </Multiselect>
          <InputError :has-error="v$.assignedTo.$error">{{ v$.assignedTo.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup label="Method">
          <RadioBoxed v-model="state.method" :options="appMethodOptions" />
          <InputError :has-error="v$.method.$error">{{ v$.method.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup v-if="state.source == 'retain'" :label="$t('ui.common.date-time.label')" label-for="time">
          <Dropdown class="w-full" :is-disabled="!state.method" v-tippy="!state.method ? 'Select a method first' : ''">
            <template #triggerContent>
              <Input v-model="computedPrettyDateTime" icon="calendar-clock" :has-error="v$.time.$error" :readonly="!state.method" />
              <InputError :has-error="v$.time.$error">{{ v$.time.$errors[0].$message }}</InputError>
            </template>
            <div class="py-2 px-4">
              <AppointmentPicker
                :key="state.time"
                v-model="state.time"
                v-model:available-dates="filteredAvailability"
                :availability-mode="bookAnyTime ? 'any' : 'available'"
                @date-range-updated="onDateRangeUpdated"
                @on-confirm="onPickerConfirm()"
              />
            </div>
          </Dropdown>
          <div v-if="state.customerTimeZone != state.timeZone" class="bg-extra-light px-4 py-2 rounded mt-2">
            <div class="text-sm text-warning">
              <Icon type="warning" class="mr-1" />
              <span>Customer is in a different time zone</span>
            </div>
            <div class="mt-1 text-sm text-secondary flex justify-between">
              <span v-tippy="'Customer'"><Icon type="user" /> {{ state.customerTimeZone }}</span>
              <span v-if="state.time">{{ computedTimezones.timeAtCustomer }}</span>
            </div>
            <div class="text-sm text-secondary flex justify-between">
              <span v-tippy="'Dealership'"><Icon type="car-building" /> {{ state.timeZone }}</span>
              <span v-if="state.time">{{ computedTimezones.timeAtDealership }}</span>
            </div>
          </div>
          <!-- <Checkbox v-model="bookAnyTime" class="mt-2" label="Allow me to book any time and method" @change="onBookAnyTimeChange" /> -->
        </FormGroup>

        <FormGroup v-else :label="$t('ui.common.date-time.label')" label-for="dateTime">
          <DatePicker v-model="state.time" :config="dateTimeConfig" :has-error="v$.time.$error" />
          <InputError :has-error="v$.time.$error">{{ v$.time.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.common.note')" label-for="note">
          <TextArea id="note" v-model="state.note" :value="state.note" :has-error="v$.note.$error"></TextArea>
          <InputError :has-error="v$.note.$error">{{ v$.note.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.task.created-by')" label-for="createdBy">
          <Chip v-if="!$can('set_appointment_creator')" :avatar="createdByData[0].profilePicURL" :avatar-text="createdByData[0].initials">{{
            createdByData[0].fullName
          }}</Chip>
          <Multiselect
            v-else
            ref="assignmentMultiselect"
            v-model="state.createdByUserID"
            mode="single"
            label="fullName"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="users.data.value"
            track-by="fullName"
            value-prop="id"
            placeholder="Select a User"
            :class="{ 'has-error': v$.createdByUserID.$error }"
          >
            <template #placeholder="{}">
              <div class="multiselect-placeholder"><Icon type="user" size="sm" class="mr-3" /> Select a user</div>
            </template>
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <Avatar :url="value.profilePicURL" :text="value.initials" size="sm" class="mr-3" /> {{ value.fullName }}
              </div>
            </template>

            <template #option="{ option }">
              <Avatar :url="option.profilePicURL" :text="option.initials" size="sm" class="mr-3" /> {{ option.fullName }}
            </template>
          </Multiselect>
          <InputError :has-error="v$.createdByUserID.$error">{{ v$.createdByUserID.$errors[0].$message }}</InputError>
        </FormGroup>
        <FormGroup label-for="dueDate">
          <Checkbox v-model="state.notifyCustomer" label="Send notification to customer"></Checkbox>
        </FormGroup>
        <FormGroup class="mb-6" label-for="dueDate">
          <Checkbox v-model="state.notifyDealership" label="Send notification to dealership"></Checkbox>
        </FormGroup>
      </form>
    </SidebarContent>
    <SidebarFooter>
      <div class="flex justify-end gap-2">
        <Button
          :label="$t('ui.actions.cancel')"
          severity="secondary"
          outlined
          type="button"
          :disabled="appointmentRequestIsLoading"
          @click="closeSidebar"
        />
        <Button
          :label="isNewAppointment ? $t('ui.actions.create') : $t('ui.actions.update')"
          :is-loading="appointmentRequestIsLoading"
          @click="appointmentHandlerSubmit"
        />
      </div>
    </SidebarFooter>
  </Sidebar>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import AppointmentPicker from '@/components/appointment-picker/AppointmentPicker.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

import Icon from '@/components/icon/Icon.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import SidebarHeader from '@/components/sidebar/SidebarHeader.vue'
import SidebarContent from '@/components/sidebar/SidebarContent.vue'
import SidebarFooter from '@/components/sidebar/SidebarFooter.vue'

import FormGroup from '@/components/forms/FormGroup.vue'
import TextArea from '@/components/forms/TextArea.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Chip from '@/components/chip/Chip.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import DatePicker from '@/components/forms/DatePicker.vue'

import Multiselect from '@vueform/multiselect'
import Swal from 'sweetalert2'

import alertToast from '@/utilities/alertToast'

import dayjs from 'dayjs'
import RadioBoxed from '@/components/forms/RadioBoxed.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'

export default {
  components: {
    Icon,
    Sidebar,
    SidebarHeader,
    SidebarContent,
    SidebarFooter,
    FormGroup,
    TextArea,
    Input,
    InputError,
    Button,
    Avatar,
    Chip,
    Checkbox,
    DatePicker,
    AppointmentPicker,
    Dropdown,
    Multiselect,
    RadioBoxed,
    RadioTabbed
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    appointment: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['updateAppointmentSubmit', 'closeAppointmentHandler', 'newAppointmentSubmit', 'appointmentUpdated'],
  setup(props, { emit }) {
    const sidebarIsOpen = ref(props.isOpen)
    const userStore = useUserStore()
    const sessionUserID = userStore.details.id

    const isNewAppointment = ref(true)

    const bookAnyTime = ref(false)

    const availableDatesArray = ref([])
    const getAvailabilityApiRequest = useApiRequest()

    const minTime = '08:00'
    const maxTime = '19:00'
    const interval = 15 // in minutes

    const adjustMinutesToNearestInterval = date => {
      const minutes = date.getMinutes()
      const remainder = minutes % interval
      if (remainder === 0) return date // already at a correct interval

      // Adjust to the nearest interval
      const difference = interval - remainder
      const adjustedMinutes = minutes + difference
      date.setMinutes(adjustedMinutes)
      return date
    }

    const dateTimeConfig = {
      enableTime: true,
      minTime: minTime,
      maxTime: maxTime,
      dateFormat: 'Y-m-d H:i:S',
      altInput: true,
      altFormat: 'h:iK \\- D J M Y',
      time_24hr: true,

      // Set default hour and minute on instantiation
      defaultHour: 10,
      defaultMinute: 0,

      // Set the minute increment
      minuteIncrement: interval,

      onOpen: function (selectedDates, dateStr, instance) {
        if (selectedDates.length === 0) {
          instance.setDate(adjustMinutesToNearestInterval(new Date()), false)
        }
      },

      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length > 0) {
          const adjustedDate = adjustMinutesToNearestInterval(selectedDates[0])
          instance.setDate(adjustedDate, false)
        }
      }
    }

    // Default state of the form
    const defaultState = {
      id: null,
      agreementID: null,
      type: 0,
      intent: null,
      source: userStore.details.type == 1 ? 'retain' : 'dealer',
      method: null,
      time: null,
      timeZone: null,
      timeUtc: null,
      customerTimeZone: null,
      note: null,
      dealershipID: null,
      assignedTo: null,
      createdByUserType: userStore.details.type,
      createdByUserID: userStore.details.id,
      notifyCustomer: true,
      notifyDealership: true
    }

    // State of the form
    const state = ref({ ...defaultState })

    // Users List
    const createdByData = ref([
      {
        fullName: null,
        profilePicURL: null
      }
    ])
    const users = useApiRequest()
    users.send({ endpoint: '/v1/users?userType=all' }).then(() => {
      createdByData.value = users.data.value.filter(user => parseInt(user.id) === parseInt(state.value.createdByUserID))
    })

    const hasUnsavedChanges = ref(false)

    function watchForChanges() {
      watch(
        state,
        newValue => {
          hasUnsavedChanges.value = true
        },
        { deep: true } // Deep watch to handle nested properties inside the state
      )
    }

    if (props.appointment) {
      if (props.appointment.id) {
        isNewAppointment.value = false
      }

      // Iterate over the properties of props.appointment and set them to the state reference
      Object.keys(props.appointment).forEach(key => {
        if (props.appointment[key] !== undefined) {
          state.value[key] = ref(props.appointment[key])
        }
      })

      watchForChanges()
    }

    // Assignable Users List
    const assignmentMultiselect = ref()
    const assignableUsers = useApiRequest()
    //Get all dealers for this dealership

    assignableUsers
      .send({
        endpoint:
          '/v1/users?showDisabled=true&userType=externalUsers&forAppointmentAssignment=true&dealerships=' + state.value.dealershipID,
        method: 'GET'
      })
      .then(() => {
        if (assignableUsers.data.value && assignableUsers.data.value.length) {
          assignableUsers.data.value = assignableUsers.data.value.filter(user => {
            // Update 'disabled' property
            user.disabled = !user.active

            // Filter out disabled users, unless it's the one assigned to
            return !user.disabled || user.id === state.value.assignedTo
          })
        }

        if (state.value.assignedTo != null && state.value.assignedTo > 0) {
          let isWithinAssignableUsers = assignableUsers.data.value.find(user => user.id === state.value.assignedTo)

          if (!isWithinAssignableUsers) {
            assignableUsers.data.value.push({
              id: state.value.assignedTo,
              fullName: state.value.assignedToName,
              disabled: true
            })
          }

          let assignedUser = assignableUsers.data.value.find(obj => obj.id == state.value.assignedTo)
          assignmentMultiselect.value?.select(assignedUser)
        }
      })

    //Get dealerships
    const dealerships = useApiRequest()
    const viewAllowed = userStore.accessAllowed
    const dealershipsAccessible = viewAllowed.dealerships.map(dealership => dealership.id)
    dealerships.send({ endpoint: '/v1/dealerships?ids=' + dealershipsAccessible, method: 'GET' })

    function getAvailability(start, end) {
      // console.log('Getting Availability for: ' + start + ' to ' + end)

      return new Promise((resolve, reject) => {
        getAvailabilityApiRequest
          .send({
            endpoint: '/v1/availability',
            method: 'GET',
            params: {
              dealershipId: state.value.dealershipID,
              startDate: start,
              endDate: end,
              ignoreAppointments: state.value.id
            }
          })
          .then(response => {
            availableDatesArray.value = response.data
            resolve(response.data)
          })
          .catch(err => {
            console.error('Error in getAvailability:', err)
            reject(err)
          })
      })
    }

    function onDateRangeUpdated({ start, end }) {
      //Fetch data for the new date range
      getAvailability(start, end)
    }

    // Watch for changes in state.source
    watch(
      () => state.value.source,
      () => {
        // Reset the time and method if source is changed
        state.value.time = null
        state.value.method = null
      }
    )

    // Watch for changes in state.method
    watch(
      () => state.value.method,
      () => {
        // Reset the time if method is changed
        state.value.time = null
      }
    )

    const appMethodOptions = computed(() => {
      let options = [
        { value: 'physical', label: 'Physical', icon: 'map-marker', description: 'At dealership', disabled: false },
        { value: 'video_external', label: 'Video', icon: 'video', description: 'Dealer will host', disabled: false },
        { value: 'phone', label: 'Phone', icon: 'phone', description: 'Dealer will call', disabled: false }
      ]

      if (state.value.source == 'retain') {
        // For each method, check if any of the dates contain slots which allow that method, if not, default to disabled
        options = options.map(option => {
          let methodAllowed = availableDatesArray.value.some(date => {
            return date.slots.some(slot => {
              return slot.methodsAllowed[option.value]
            })
          })

          return { ...option, disabled: !methodAllowed }
        })
      }

      return options
    })

    // Computed function to filter out availability based on the method selected
    const filteredAvailability = computed(() => {
      let methodSelected = state.value.method

      // return a version of availableDatesArray that is filtered based on the method selected.
      if (availableDatesArray.value.length) {
        let filteredDates = availableDatesArray.value.map(date => {
          let filteredSlots = date.slots.filter(slot => {
            return slot.methodsAllowed[methodSelected]
          })

          return { ...date, slots: filteredSlots }
        })

        return filteredDates
      }
    })

    //Get availability for this month
    if (props.isOpen) {
      getAvailability(dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD'))
    }

    function onPickerConfirm() {}

    function onBookAnyTimeChange() {}

    const vuelidateRules = {
      agreementID: { required },
      intent: { required },
      method: { required },
      dealershipID: { required },
      assignedTo: {},
      time: { required },
      note: { required },
      createdByUserID: { required }
    }
    const v$ = useVuelidate(vuelidateRules, state, { $stopPropagation: true })

    function closeSidebar() {
      // check that no unsaved changes
      if (hasUnsavedChanges.value) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'Changes made will not be saved if you continue',
          icon: 'warning',
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Discard',
          confirmButtonColor: 'var(--bg-error-solid)',
          cancelButtonText: 'Cancel'
        }).then(function (response) {
          if (response.isConfirmed) {
            sidebarIsOpen.value = false
            emit('closeAppointmentHandler')
          }
        })
      } else {
        sidebarIsOpen.value = false
        emit('closeAppointmentHandler')
      }
    }

    const { isLoading: appointmentRequestIsLoading, send: appointmentSendRequest } = useApiRequest()

    async function appointmentHandlerSubmit() {
      const isFormCorrect = await v$.value.$validate()

      if (isFormCorrect) {
        const jsonData = JSON.stringify({
          agreementID: state.value.agreementID,
          type: state.value.type,
          intent: state.value.intent,
          source: state.value.source,
          method: state.value.method,
          time: state.value.time,
          timeZone: state.value.timeZone,
          timeUtc: dayjs(dayjs.tz(state.value.time, state.value.timeZone)).utc().format('YYYY-MM-DD HH:mm:ss'),
          note: state.value.note,
          dealershipID: state.value.dealershipID,
          assignedTo: state.value.assignedTo,
          createdByUserType: state.value.createdByUserType,
          createdByUserID: state.value.createdByUserID,
          updateSource: userStore.details.type == 1 ? 'retain' : 'dealer',
          updatedByUserID: sessionUserID,
          notifyCustomer: state.value.notifyCustomer,
          notifyDealership: state.value.notifyDealership
        })

        if (isNewAppointment.value) {
          appointmentSendRequest({ endpoint: '/v1/appointments', method: 'POST', data: jsonData }).then(response => {
            if (!response.success) {
              alertToast('Failed to create appointment', response.data.message, 'error')
            }
            //Check that the status from the API was successful
            if (response.success) {
              let newAppointmentID = response.data.newAppointmentID
              //Make sure the Appointment ID is received in the response
              if (!newAppointmentID) {
                alertToast('Warning', 'Appointment might not have been added', 'warning')
              } else {
                //Add the new ID to the state
                state.value.id = newAppointmentID
                //If response has a message, means there was a partial issue.
                alertToast('Appointment Booked!', response.data.message, 'warning')

                // Emit event to todo page with json, which will then add the appointment to the DOM
                emit('newAppointmentSubmit', state.value)
                // set hasUnsavedChanges to false
                hasUnsavedChanges.value = false

                closeSidebar()
              }
            }
          })
        } else {
          // Update existing appointment
          appointmentSendRequest({ endpoint: '/v1/appointments/' + state.value.id + '/reschedule', method: 'PATCH', data: jsonData }).then(
            response => {
              if (response.success) {
                alertToast('Appointment Updated!', response.data.message, 'success')

                // Emit event to todo page with json, which will then add the appointment to the DOM
                emit('appointmentUpdated', state.value)
                hasUnsavedChanges.value = false
                closeSidebar()
              } else {
                alertToast('Failed to update appointment', response.data.message, 'error')
              }
            }
          )
        }
      }
    }

    function deleteAppointment(shouldDelete) {
      state.value.isDeleted = shouldDelete

      appointmentHandlerSubmit()
    }

    const typeIntentOptions = [
      {
        value: 0,
        label: 'Finance Review',
        intents: ['Unsure', 'Part-Exchange', 'Refinance', 'Settle', 'Hand Back', 'Buy-Back']
      }
      // {
      //   value: 2,
      //   label: 'Service',
      //   intents: ['None', 'Full Service', 'Mid Service', 'Health Checkup', 'Specific Issue']
      // }
    ]

    const intentsComputed = computed(() => {
      let intentOptions = []

      typeIntentOptions.forEach(type => {
        if (type.value === state.value.type) {
          intentOptions = type.intents
        }
      })

      return intentOptions
    })

    watch(
      () => state.value.type,
      () => {
        state.value.intent = null
      }
    )

    const computedTimezones = computed(() => {
      if (!state.value.time || !state.value.timeZone || !state.value.customerTimeZone)
        return {
          timeAtDealership: null,
          timeAtCustomer: null
        }

      let timeAtDealership = dayjs.tz(state.value.time, state.value.timeZone)
      let timeAtCustomer = dayjs.tz(timeAtDealership, state.value.customerTimeZone)
      return {
        timeAtDealership: timeAtDealership.format('lll'),
        timeAtCustomer: timeAtCustomer.format('lll')
      }
    })

    const computedPrettyDateTime = computed(() => {
      if (state.value.time) {
        return dayjs(state.value.time).format('lll')
      }
      return ''
    })

    return {
      sidebarIsOpen,
      userStore,
      sessionUserID,
      closeSidebar,
      isNewAppointment,
      appointmentHandlerSubmit,
      v$,
      state,
      users,
      assignableUsers,
      assignmentMultiselect,
      dateTimeConfig,
      deleteAppointment,
      dealerships,
      appointmentRequestIsLoading,
      hasUnsavedChanges,
      createdByData,
      typeIntentOptions,
      intentsComputed,
      onDateRangeUpdated,
      availableDatesArray,
      onPickerConfirm,
      computedTimezones,
      computedPrettyDateTime,
      appMethodOptions,
      bookAnyTime,
      onBookAnyTimeChange,
      filteredAvailability
    }
  }
}
</script>
