<template>
  <div class="dropdown-wrapper">
    <div v-if="!isContextMenu" ref="triggerContainer" @click="toggleDropdown">
      <slot v-if="$slots['triggerContent']" name="triggerContent" />
      <Button v-else :label="text" :severity="variant" is-dropdown @click.prevent />
    </div>

    <teleport to="#dropdown-overlay">
      <div :style="[padding ? 'padding: 0.5rem 0;' : '']">
        <ul v-if="isOpen" ref="dropdownMenu" v-click-away="handleClickAway" class="dropdown-menu" :style="floatingStyles">
          <slot />
        </ul>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { computed, provide, ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/vue'
import Button from '@/components/button/Button.vue'

const props = defineProps({
  variant: { type: String, default: 'primary' },
  text: { type: String, default: null },
  position: { type: String, default: 'bottom-start' }, // left-start, left-end, right-start, right-end
  split: { type: Boolean, default: false },
  padding: { type: Boolean, default: true },
  closeOnClickAway: { type: Boolean, default: true },
  closeOnClickWithin: { type: Boolean, default: false },
  isDisabled: { type: Boolean, default: false },
  isContextMenu: { type: Boolean, default: false }
})

const emit = defineEmits(['close'])

const isOpen = ref(false)
const triggerContainer = ref(null)
const dropdownMenu = ref(null)
const mousePosition = ref({ x: 0, y: 0 })

// Custom reference object to dynamically switch between cursor and container
const reference = computed(() =>
  props.isContextMenu
    ? {
        getBoundingClientRect: () => ({
          top: mousePosition.value.y,
          left: mousePosition.value.x,
          right: mousePosition.value.x + 1, // simulate a small width
          bottom: mousePosition.value.y + 1, // simulate a small height
          width: 0,
          height: 0
        })
      }
    : triggerContainer.value
)

const { floatingStyles, update } = useFloating(reference, dropdownMenu, {
  placement: props.position,
  middleware: [offset(props.isContextMenu ? 10 : 5), flip(), shift()],
  strategy: 'fixed',
  whileElementsMounted: autoUpdate
})

function onContextMenu(event) {
  if (props.isContextMenu) {
    event.preventDefault()
    mousePosition.value = { x: event.clientX, y: event.clientY }
    nextTick(() => {
      isOpen.value = true
      update()
    })
  }
}

onMounted(() => {
  window.addEventListener('contextmenu', onContextMenu)
})

onBeforeUnmount(() => {
  window.removeEventListener('contextmenu', onContextMenu)
})

function toggleDropdown() {
  if (!props.isDisabled && !props.isContextMenu) {
    // Prevent normal toggle if context menu
    isOpen.value = !isOpen.value
    nextTick(update)
  }
}

function closeDropdown() {
  emit('close')
  isOpen.value = false
}

provide('isOpen', isOpen)
provide('closeDropdown', closeDropdown)

// Handle click inside and outside of dropdown
function handleClickAway(event) {
  if (props.closeOnClickAway && event.target.closest('#dropdown-overlay') === null) {
    closeDropdown()
  }
}

watch(isOpen, newVal => {
  if (!newVal) closeDropdown()
})
</script>

<style>
.dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  list-style: none;
  border-radius: var(--rounded-lg);
  box-shadow: var(--shadow-lg);
  background-color: var(--bg-primary);
  background-clip: padding-box;
  border: 1px solid var(--border-secondary);
  z-index: 10;
  min-width: 10rem;
  font-size: 1rem;
}
</style>
