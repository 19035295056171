<template>
  <div class="license-plate" :class="['lp-' + country, { 'lp-greyscale': greyscale }, design ? 'lp-' + design : '', 'lp-size-' + size]">
    {{ plate }}
  </div>
</template>

<script>
export default {
  props: {
    plate: {
      type: String,
      required: true
    },
    country: {
      type: String,
      default: 'uk',
      required: true
    },
    greyscale: {
      type: Boolean,
      default: false
    },
    design: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  setup() {
    return {}
  }
}
</script>

<style>
@font-face {
  font-family: 'UKNumberPlate';
  src: URL('https://cdn.retainauto.com/fonts/UKNumberPlate.ttf') format('truetype');
}
.license-plate {
  display: inline-block;
  white-space: nowrap;
}

.license-plate.lp-uk {
  background-color: #ffcc00;
  border-left: none;
  border-radius: 3px;
  font-family: 'UKNumberPlate';
}
.license-plate.lp-uk.lp-size-xs {
  font-size: 0.9rem;
  padding: 0px 5px;
}
.license-plate.lp-uk.lp-size-sm {
  font-size: 1.05rem;
  padding: 0px 7px;
}
.license-plate.lp-uk.lp-size-md {
  font-size: 1.25rem;
  padding: 0px 10px;
}
.license-plate.lp-uk.lp-size-lg {
  font-size: 1.4rem;
  padding: 0px 13px;
}
.license-plate.lp-uk.lp-ev {
  border-left: 10px solid #009f00;
}
.license-plate.lp-uk.lp-ev.lp-size-xs {
  border-left: 5px solid #009f00;
}
.license-plate.lp-uk.lp-greyscale {
  background-color: var(--bg-tertiary);
}
</style>
