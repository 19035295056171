<template>
  <Steps
    ref="formWizard"
    :steps="formSteps"
    :is-submitting="postNewCampaign.isLoading.value"
    @before-move="validateStep"
    @on-complete="submitNewCampaign"
  >
    <template #step-1>
      <FormGroup :label="$t('ui.common.name')" :is-required="v$.name.required">
        <Input v-model="newCampaign.name" :has-error="v$.name.$error" />
        <InputError :has-error="v$.name.$error">{{ v$.name.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup :label="$t('ui.entities.dealership', 2)" :is-required="v$.dealerships.required">
        <MultiSelect
          v-model="newCampaign.dealerships"
          :options="dealershipsList"
          display="chip"
          filter
          option-value="value"
          option-label="label"
          :max-range="userStore.roles.includes(2) && userStore.roles.length == 1 ? 30 : null"
          class="w-full"
          :class="{ 'has-error': v$.dealerships.$error }"
        >
          <template #option="{ option }">
            <img :src="option.manufacturerLogo" width="20" class="mr-3" />
            {{ option.label }}
          </template>
        </MultiSelect>
        <InputError :has-error="v$.dealerships.$error">{{ v$.dealerships.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <div class="grid grid-cols-2 gap-4">
        <FormGroup :label="$t('ui.entities.finance-agreement.start-date')" :is-required="v$.startDate.required">
          <DatePicker v-model="newCampaign.startDate" :config="datePickerConfig" class="w-full" :has-error="v$.startDate.$error" />
          <InputError :has-error="v$.startDate.$error">{{ v$.startDate.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.finance-agreement.end-date')" :is-required="v$.endDate.required">
          <DatePicker v-model="newCampaign.endDate" :config="datePickerConfig" class="w-full" :has-error="v$.endDate.$error" />
          <InputError :has-error="v$.endDate.$error">{{ v$.endDate.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </template>

    <template #step-2>
      <div class="grid grid-cols-2 gap-6">
        <FormGroup label="Months Had" :is-required="v$.filterMonthsHadMin.required && v$.filterMonthsHadMax.required">
          <div class="flex gap-2 items-center w-44">
            <div>
              <Input v-model="newCampaign.filterMonthsHadMin" placeholder="From" :has-error="v$.filterMonthsHadMin.$error" />
              <InputError :has-error="v$.filterMonthsHadMin.$error">{{ v$.filterMonthsHadMin.$errors[0]?.$message }}</InputError>
            </div>
            <Icon type="arrow-right" />
            <div>
              <Input v-model="newCampaign.filterMonthsHadMax" placeholder="To" :has-error="v$.filterMonthsHadMax.$error" />
              <InputError :has-error="v$.filterMonthsHadMax.$error">{{ v$.filterMonthsHadMax.$errors[0]?.$message }}</InputError>
            </div>
          </div>
        </FormGroup>
        <FormGroup label="Months Remaining" :is-required="v$.filterMonthsRemainingMin.required && v$.filterMonthsRemainingMax.required">
          <div class="flex gap-2 items-center w-44">
            <div>
              <Input v-model="newCampaign.filterMonthsRemainingMin" placeholder="From" :has-error="v$.filterMonthsRemainingMin.$error" />
              <InputError :has-error="v$.filterMonthsRemainingMin.$error">{{
                v$.filterMonthsRemainingMin.$errors[0]?.$message
              }}</InputError>
            </div>
            <Icon type="arrow-right" />
            <div>
              <Input v-model="newCampaign.filterMonthsRemainingMax" placeholder="To" :has-error="v$.filterMonthsRemainingMax.$error" />
              <InputError :has-error="v$.filterMonthsRemainingMax.$error">{{
                v$.filterMonthsRemainingMax.$errors[0]?.$message
              }}</InputError>
            </div>
          </div>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.vehicle.new-used')" :is-required="v$.filterNewUsed.required">
          <RadioTabbed
            v-model="newCampaign.filterNewUsed"
            :options="[
              { label: 'New', value: 'new' },
              { label: 'Used', value: 'used' },
              { label: 'Both', value: 'both' }
            ]"
            :selected-option="newCampaign.filterNewUsed"
            :class="{ 'has-error': v$.filterNewUsed.$error }"
          />
          <InputError :has-error="v$.filterNewUsed.$error">{{ v$.filterNewUsed.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.finance-agreement.finance-product')" :is-required="v$.filterFinanceProducts.required">
          <MultiSelect
            v-model="newCampaign.filterFinanceProducts"
            :options="['PCP', 'HP', 'CH']"
            display="chip"
            filter
            class="w-full"
            :class="{ 'has-error': v$.filterFinanceProducts.$error }"
          >
          </MultiSelect>
          <InputError :has-error="v$.filterFinanceProducts.$error">{{ v$.filterFinanceProducts.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </template>

    <template #step-3>
      <div class="flex flex-col gap-2 mb-6">
        <div class="text-2xl">{{ newCampaign.name }}</div>
        <div class="flex items-center gap-2">
          <span>{{ dayjs(newCampaign.startDate).format('Do MMM YYYY') }}</span>
          <Icon type="arrow-right" class="text-sm text-quaternary" />
          <span>{{ dayjs(newCampaign.endDate).format('Do MMM YYYY') }}</span>
        </div>
        <div class="flex gap-2 flex-wrap">
          <div v-for="(dealership, index) in newCampaign.dealerships" :key="index">
            <Chip size="sm">
              <img :src="dealershipsList.find(d => d.value == dealership).manufacturerLogo" width="20" class="mr-2" />
              {{ dealershipsList.find(d => d.value == dealership).label }}
            </Chip>
          </div>
        </div>
      </div>
      <ListGroup class="mb-4">
        <ListGroupItem class="flex justify-between">
          <div>Months Had</div>
          <div v-if="newCampaign.filterMonthsHadMin && newCampaign.filterMonthsHadMax">
            {{ newCampaign.filterMonthsHadMin }} - {{ newCampaign.filterMonthsHadMax }} months
          </div>
          <div v-else-if="newCampaign.filterMonthsHadMin">{{ newCampaign.filterMonthsHadMin }} months or more</div>
          <div v-else-if="newCampaign.filterMonthsHadMax">{{ newCampaign.filterMonthsHadMax }} months or less</div>
          <div v-else>Any</div>
        </ListGroupItem>
        <ListGroupItem class="flex justify-between">
          <div>Months Remaining</div>
          <div v-if="newCampaign.filterMonthsRemainingMin && newCampaign.filterMonthsRemainingMax">
            {{ newCampaign.filterMonthsRemainingMin }} - {{ newCampaign.filterMonthsRemainingMax }} months
          </div>
          <div v-else-if="newCampaign.filterMonthsRemainingMin">{{ newCampaign.filterMonthsRemainingMin }} months or more</div>
          <div v-else-if="newCampaign.filterMonthsRemainingMax">{{ newCampaign.filterMonthsRemainingMax }} months or less</div>
          <div v-else>Any</div>
        </ListGroupItem>
        <ListGroupItem class="flex justify-between">
          <div>{{ $t('ui.entities.vehicle.new-used') }}</div>
          <div :class="{ capitalize: !newCampaign.filterNewUsed == 'both' }">
            {{ newCampaign.filterNewUsed == 'both' ? 'New & Used' : newCampaign.filterNewUsed }}
          </div>
        </ListGroupItem>
        <ListGroupItem class="flex justify-between">
          <div>{{ $t('ui.entities.finance-agreement.finance-product') }}</div>
          <div v-if="newCampaign.filterFinanceProducts" class="flex gap-2">
            <div v-for="(type, index) in newCampaign.filterFinanceProducts" :key="index">
              {{ type }}
            </div>
          </div>
          <div v-else>Any</div>
        </ListGroupItem>
      </ListGroup>
      <div class="flex flex-col gap-2 items-end">
        <div class="text-quaternary">Customers Matched</div>
        <Icon v-if="filterResultsApiRequest.isLoading.value" type="spinner-third" class="btn-icon fa-spin" fa-style="far" />
        <div v-else class="text-2xl font-medium -mt-2">{{ matchedCustomersCount }}</div>
      </div>
    </template>
  </Steps>
  <!-- {{ newCampaign }} -->
</template>

<script setup>
import { ref, computed, watch } from 'vue'

import alertToast from '@/utilities/alertToast'

import Steps from '@/components/steps/Steps.vue'

import Icon from '@/components/icon/Icon.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'
import Chip from '@/components/chip/Chip.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
import InputError from '@/components/forms/InputError.vue'
import MultiSelect from 'primevue/multiselect'
import DatePicker from '@/components/forms/DatePicker.vue'

import useVuelidate from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

const postNewCampaign = useApiRequest()
const filterResultsApiRequest = useApiRequest()
const userStore = useUserStore()
const formWizard = ref(null) // Reference to the FormWizard component

const dealershipsApiRequest = useApiRequest()
const dealershipsList = ref(null)
const dealershipsAllowed = userStore.accessAllowed.dealerships.map(dealership => dealership.id)

const matchedCustomersCount = ref(null)

const emit = defineEmits(['form-updated', 'is-submitting', 'completed'])

const newCampaign = ref({
  name: null,
  dealerships: [],
  startDate: null,
  endDate: null,
  filterMonthsHadMin: null,
  filterMonthsHadMax: null,
  filterMonthsRemainingMin: null,
  filterMonthsRemainingMax: null,
  filterNewUsed: 'both',
  filterFinanceProducts: []
})

const datePickerConfig = {
  enableTime: false,
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'd/m/Y'
}

const formSteps = [
  {
    label: 'Basic Details',
    title: 'Basic Details',
    icon: null
  },
  {
    label: 'Filters',
    title: 'Filters',
    icon: null
  },
  {
    label: 'Summary',
    title: 'Summary',
    icon: null
  }
]

const inputRules = {
  formStep1: {
    name: { required },
    dealerships: { required },
    startDate: { required },
    endDate: { required }
  },
  formStep2: {
    filterMonthsHadMin: { numeric },
    filterMonthsHadMax: { numeric },
    filterMonthsRemainingMin: { numeric },
    filterMonthsRemainingMax: { numeric },
    filterNewUsed: { required },
    filterFinanceProducts: { required }
  }
}

const stepValidationRules = computed(() => {
  console.log(`stepValidationRules ${formWizard.value?.currentStep + 1}`)
  if (formWizard.value?.currentStep) {
    // console.log(`formStep${formWizard.value?.currentStep}`)
    return inputRules[`formStep${formWizard.value?.currentStep + 1}`]
  }
  return inputRules.formStep1
})

const v$ = useVuelidate(stepValidationRules, newCampaign)

async function validateStep() {
  console.log('Validating Step: ', stepValidationRules.value)
  const isStepCorrect = await v$.value.$validate()
  console.log('Is Valid: ' + isStepCorrect)
  if (isStepCorrect) {
    if (formWizard.value.currentStep == 1) {
      console.log('fetching customers')
      fetchMatchedCustomers()
    }
    formWizard.value.move(1) // Move to the next step
  } else {
    console.log(v$.value)
  }
}

function fetchMatchedCustomers() {
  let dataToPost = {
    ...newCampaign.value,
    filterMonthsHadMin: newCampaign.value.filterMonthsHadMin || null,
    filterMonthsHadMax: newCampaign.value.filterMonthsHadMax || null,
    filterMonthsRemainingMin: newCampaign.value.filterMonthsRemainingMin || null,
    filterMonthsRemainingMax: newCampaign.value.filterMonthsRemainingMax || null
  }

  try {
    filterResultsApiRequest
      .send({ method: 'POST', endpoint: '/v1/campaigns/new-matched-customers', data: JSON.stringify(dataToPost) })
      .then(response => {
        if (response.success) {
          matchedCustomersCount.value = response.data.metadata.pagination.total
        } else {
          alertToast('Error', 'Failed to fetch matched customers', 'error')
        }
      })
  } catch (err) {
    alertToast('Error', err.message, 'error')
  }
}

dealershipsApiRequest.send({ method: 'GET', endpoint: '/v1/dealerships?ids=' + dealershipsAllowed }).then(response => {
  dealershipsList.value = response.data.map(dealership => {
    return { value: dealership.id, label: dealership.displayName, manufacturerLogo: dealership.manufacturerLogo }
  })
})

function submitNewCampaign() {
  if (matchedCustomersCount.value == 0) {
    alertToast('No customers matched', null, 'info')
    return
  }

  if (matchedCustomersCount.value > 800) {
    alertToast('Too many customers', 'Keep campaigns to under 800 customers', 'warning')
    return
  }

  emit('is-submitting', true)

  let dataToPost = {
    ...newCampaign.value,
    filterMonthsHadMin: newCampaign.value.filterMonthsHadMin || null,
    filterMonthsHadMax: newCampaign.value.filterMonthsHadMax || null,
    filterMonthsRemainingMin: newCampaign.value.filterMonthsRemainingMin || null,
    filterMonthsRemainingMax: newCampaign.value.filterMonthsRemainingMax || null
  }

  try {
    postNewCampaign.send({ method: 'POST', endpoint: '/v1/campaigns', data: JSON.stringify(dataToPost) }).then(response => {
      if (response.success) {
        emit('is-submitting', true)
        emit('completed')
        alertToast('Campaign added', null, 'success')
      } else {
        alertToast('Failed to add new campaign', response.data.message, 'error')
      }
    })
  } catch (err) {
    alertToast('Error', err.message, 'error')
  }
}

watch(
  () => newCampaign.value,
  () => {
    emit('form-updated')
  },
  { deep: true }
)
</script>

<style lang="scss" scoped></style>
