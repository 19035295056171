<template>
  <PageHeader
    :title="$t('ui.entities.group', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.entities.group', 2), to: '/settings/groups' }
    ]"
  >
    <template #right>
      <Button :label="$t('ui.crud.new', { item: $t('ui.entities.group') })" severity="primary" icon="plus" @click="newGroup" />
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Manufacturers"
      :value="groups"
      :loading="isLoading"
      removable-sort
      sort-field="id"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-groups"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'name']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
      </template>

      <Column field="id" header="Id" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.id }}</div>
        </template>
      </Column>

      <Column field="name" header="Name" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.name }}</div>
        </template>
      </Column>

      <Column field="manufacturers" header="Manufacturers">
        <template #body="{ data }">
          <div class="flex gap-2">
            <Chip v-for="(manufacturer, index) in data.manufacturers" :key="index">
              {{ manufacturersList.find(m => m.value == manufacturer)?.label }}</Chip
            >
          </div>
        </template>
      </Column>

      <Column field="logoUrl" header="Logo">
        <template #body="{ data }">
          <Image :src="data.logoUrl" image-class="h-8 max-w-16" />
        </template>
      </Column>

      <Column field="regions" header="Regions">
        <template #body="{ data }">
          <div class="flex gap-2">
            <Chip v-for="(region, index) in data.regions" :key="index">
              <img :src="regionsList.find(r => r.value == region.toLowerCase())?.image" class="w-5 mr-2" />
              {{ region.toUpperCase() }}</Chip
            >
          </div>
        </template>
      </Column>

      <Column field="isActive" header="Active" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Chip v-if="data.isActive" label outlined size="sm" dot severity="success">Active</Chip>
          <Chip v-else label outlined size="sm" dot severity="danger">Disabled</Chip>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: true, label: 'Active' },
              { value: false, label: 'Disabled' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column :sortable="false">
        <template #body="{ data }">
          <div>
            <Button v-tippy="$t('ui.actions.edit')" icon="cog" plain severity="secondary" @click="editGroupSettings(data.id)"></Button>
          </div>
        </template>
      </Column>
    </DataTable>
  </Card>

  <teleport to="#modals-container">
    <Modal
      :is-open="editGroupModalIsOpen"
      title="Edit Group"
      icon="briefcase-blank"
      :buttons="['ok', 'close']"
      :has-unsaved-changes="editGroupHasUnsavedChanges"
      close-text="Cancel"
      ok-text="Save"
      :show-loader-on-confirm="true"
      :is-confirming="false"
      min-width="600"
      overflow-behaviour="visible"
      @close-modal="(editGroupModalIsOpen = false), (editGroupHasUnsavedChanges = false)"
      @ok-modal="updateGroupSettings()"
    >
      <div class="grid grid-cols-2 gap-x-6">
        <!-- <FormGroup label="Active" :is-required="v$.isActive.required?.$invalid" class="col-span-2">
          <Switch v-model="selectedGroupState.isActive" />
        </FormGroup> -->
        <FormGroup :label="$t('ui.common.name')" :is-required="v$.name.required?.$invalid">
          <Input v-model="selectedGroupState.name" :has-error="v$.name.$error" />
          <InputError :has-error="v$.name.$error">{{ v$.name.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup label="Logo Url" :is-required="v$.logoUrl.required?.$invalid">
          <div class="w-full">
            <Input v-model="selectedGroupState.logoUrl" :has-error="v$.logoUrl.$error" />
            <InputError :has-error="v$.logoUrl.$error">{{ v$.logoUrl.$errors[0]?.$message }}</InputError>
          </div>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.manufacturer', 2)" :is-required="v$.manufacturers.required?.$invalid">
          <MultiSelect
            v-model="selectedGroupState.manufacturers"
            :options="manufacturersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Select"
            class="w-full"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
          <InputError :has-error="v$.manufacturers.$error">{{ v$.manufacturers.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.location-selection.region', 2)" :is-required="v$.regions.required?.$invalid">
          <MultiSelect
            v-model="selectedGroupState.regions"
            :options="regionsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Select"
            class="w-full"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
          <InputError :has-error="v$.regions.$error">{{ v$.regions.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </Modal>

    <Modal
      :is-open="newGroupModalIsOpen"
      title="New Group"
      icon="briefcase-blank"
      :buttons="['ok', 'close']"
      :has-unsaved-changes="newGroupHasUnsavedChanges"
      close-text="Cancel"
      ok-text="Submit"
      :show-loader-on-confirm="true"
      :is-confirming="false"
      min-width="600"
      overflow-behaviour="visible"
      @close-modal="(newGroupModalIsOpen = false), (newGroupHasUnsavedChanges = false)"
      @ok-modal="submitNewGroup()"
    >
      <div class="grid grid-cols-2 gap-x-6">
        <FormGroup label="Active" :is-required="v$new.isActive.required?.$invalid" class="col-span-2">
          <Switch v-model="newGroupState.isActive" />
        </FormGroup>
        <FormGroup :label="$t('ui.common.name')" :is-required="v$new.name.required?.$invalid">
          <Input v-model="newGroupState.name" :has-error="v$new.name.$error" />
          <InputError :has-error="v$new.name.$error">{{ v$new.name.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup label="Logo Url" :is-required="v$new.logoUrl.required?.$invalid" class="col-span-2">
          <div class="flex gap-4 items-center">
            <div class="w-full">
              <Input v-model="newGroupState.logoUrl" :has-error="v$new.logoUrl.$error" />
              <InputError :has-error="v$new.logoUrl.$error">{{ v$new.logoUrl.$errors[0]?.$message }}</InputError>
            </div>
            <div v-if="newGroupState.logoUrl" class="h-9 w-9 flex justify-center items-center">
              <img :src="newGroupState.logoUrl" />
            </div>
          </div>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.manufacturer', 2)" :is-required="v$new.manufacturers.required?.$invalid">
          <MultiSelect
            v-model="newGroupState.manufacturers"
            :options="manufacturersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Select"
            class="w-full"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
          <InputError :has-error="v$new.manufacturers.$error">{{ v$new.manufacturers.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.location-selection.region')" :is-required="v$new.regions.required?.$invalid">
          <MultiSelect
            v-model="newGroupState.regions"
            :options="regionsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Select"
            class="w-full"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
          <InputError :has-error="v$new.regions.$error">{{ v$new.regions.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </Modal>
  </teleport>
</template>

<script setup>
import { ref, watch } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Modal from '@/components/modal/Modal.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import Switch from '@/components/forms/Switch.vue'
import Chip from '@/components/chip/Chip.vue'
import MultiSelect from 'primevue/multiselect'
import Image from '@/components/image/Image.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import alertToast from '@/utilities/alertToast'

import useApiRequest from '@/composables/useApiRequest'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

const groups = ref(null)
const groupsApiRequest = useApiRequest()
const editGroupApiRequest = useApiRequest()
const newGroupApiRequest = useApiRequest()
const isLoading = groupsApiRequest.isLoading

const editGroupModalIsOpen = ref(false)
const editGroupHasUnsavedChanges = ref(false)
const selectedGroupInitState = ref(null)
const selectedGroupState = ref(null)

const newGroupModalIsOpen = ref(false)
const newGroupHasUnsavedChanges = ref(false)
const newGroupInitState = ref(null)
const newGroupState = ref(null)
const filters = ref([])

const manufacturers = useApiRequest()
const manufacturersList = ref()

manufacturers.send({ endpoint: '/v1/manufacturers' }).then(response => {
  manufacturersList.value = response.data.map(manufacturer => ({
    value: manufacturer.id,
    label: manufacturer.name,
    color: manufacturer.accentColor
  }))
})

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.IN },
    isActive: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  //remove from session storage
  sessionStorage.removeItem('dt-settings-groups')
  initFilters()
}

const regionsList = [
  {
    label: useI18n().t('meta.countries.gb.label'),
    value: 'gb',
    emoji: '🇬🇧',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'
  },
  {
    label: useI18n().t('meta.countries.us.label'),
    value: 'us',
    emoji: '🇺🇸',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'
  },
  {
    label: useI18n().t('meta.countries.es.label'),
    value: 'es',
    emoji: '🇪🇸',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg'
  },
  {
    label: useI18n().t('meta.countries.mx.label'),
    value: 'mx',
    emoji: '🇲🇽',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg'
  },
  {
    label: useI18n().t('meta.countries.fr.label'),
    value: 'fr',
    emoji: '🇫🇷',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg'
  }
]

function fetchGroups() {
  groupsApiRequest
    .send({
      endpoint: '/v1/groups'
    })
    .then(response => {
      console.log(response)
      if (response.success) {
        groups.value = response.data
      } else {
        alertToast('Error', 'Failed to fetch groups', 'error')
      }
    })
}
fetchGroups()

function editGroupSettings(id) {
  selectedGroupInitState.value = groups.value.find(group => group.id == id)
  let logoFileName = selectedGroupInitState.value.logoUrl?.split('/').slice(-1)[0]
  selectedGroupInitState.value.logoUrl = logoFileName
  selectedGroupState.value = { ...selectedGroupInitState.value }
  if (selectedGroupState.value) {
    editGroupModalIsOpen.value = true
  } else {
    alertToast('Error', 'Could not find group', 'error')
  }
}

function newGroup() {
  newGroupInitState.value = {
    accentColor: null,
    isActive: true,
    name: null,
    logoUrl: null,
    squareLogoUrl: null,
    manufacturers: null,
    regions: null
  }

  newGroupState.value = { ...newGroupInitState.value }
  newGroupModalIsOpen.value = true
}

const InputRules = {
  id: {},
  isActive: {},
  logoUrl: { required },
  name: { required },
  squareLogoUrl: {},
  regions: { required },
  manufacturers: {}
}

const v$ = useVuelidate(InputRules, selectedGroupState, { $stopPropagation: true })
const v$new = useVuelidate(InputRules, newGroupState)

async function updateGroupSettings() {
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) {
    return
  }
  // console.log(selectedGroupState.value)
  let dataToPost = { ...selectedGroupState.value }
  try {
    editGroupApiRequest
      .send({
        method: 'PATCH',
        endpoint: `/v1/groups/${dataToPost.id}`,
        data: JSON.stringify(dataToPost)
      })
      .then(response => {
        if (!response.success) {
          alertToast('Error', 'Failed to update group', 'error')
        } else {
          alertToast('Success!', 'Group details updated', 'success')

          editGroupModalIsOpen.value = false
          editGroupHasUnsavedChanges.value = false
          fetchGroups()
        }
      })
  } catch (err) {
    alertToast('Error', err.message, 'error')
  }
  v$.value.$reset()
}

async function submitNewGroup() {
  const isFormCorrect = await v$new.value.$validate()
  if (!isFormCorrect) {
    return
  }

  let dataToPost = { ...newGroupState.value }
  try {
    newGroupApiRequest
      .send({
        method: 'POST',
        endpoint: '/v1/groups',
        data: JSON.stringify(dataToPost)
      })
      .then(response => {
        if (!response.success) {
          alertToast('Error', 'Failed to add group', 'error')
        } else {
          alertToast('Success!', 'Group added', 'success')
          newGroupModalIsOpen.value = false
          newGroupHasUnsavedChanges.value = false
          fetchGroups()
        }
      })
  } catch (err) {
    alertToast('Error', err.message, 'error')
  }
  v$.value.$reset()
}

watch(
  selectedGroupState,
  newValue => {
    // Compare the current state with the initial state
    editGroupHasUnsavedChanges.value = JSON.stringify(newValue) !== JSON.stringify(selectedGroupInitState.value)
  },
  { deep: true } // Deep watch to handle nested properties inside the state
)

watch(
  newGroupState,
  newValue => {
    // Compare the current state with the initial state
    newGroupHasUnsavedChanges.value = JSON.stringify(newValue) !== JSON.stringify(newGroupInitState.value)
  },
  { deep: true } // Deep watch to handle nested properties inside the state
)
</script>

<style></style>
