<template>
  <PageHeader
    title="Summary"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: 'Engagement' },
      { label: 'Summary', to: '/reports/engagement/summary' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          max-range="31"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Engagement Summary"
      :value="engagementData"
      removable-sort
      sort-field="timestamp"
      :sort-order="-1"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipId', 'dealershipName']"
      :rows="20"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-engagement-summary"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="engagementApiRequest.isLoading.value"
    >
      <template #empty> No records found </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <ColumnGroup type="header">
        <Row>
          <Column />
          <Column header="Outbound Calls" :colspan="2" />
          <Column header="Inbound Calls" :colspan="2" />
          <Column header="Emails" :colspan="2" />
          <Column header="SMS" :colspan="2" />
          <Column :colspan="2" />
        </Row>
        <Row>
          <Column field="id" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
            <!-- <template #filter="{ filterModel }">
              <MultiSelect
                v-model="filterModel.value"
                :options="dealershipsList"
                filter
                display="chip"
                option-value="value"
                option-label="label"
                placeholder="Any"
                class="max-w-[300px]"
              >
                <template #option="slotProps">
                  {{ slotProps.option.label }}
                </template>
              </MultiSelect>
            </template> -->
          </Column>
          <Column header="Answered" />
          <Column header="Total" />
          <Column header="Answered" />
          <Column header="Total" />

          <Column header="Outbound" />
          <Column header="Inbound" />
          <Column header="Outbound" />
          <Column header="Inbound" />
          <Column header="Total Comms" />
          <!-- <Column header="Appts Booked" /> -->
        </Row>
      </ColumnGroup>

      <Column field="name" header="Dealership" hidden />
      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <AvatarLabelGroup :label="data.name" :avatar-url="data.manufacturerLogo" avatar-is-logo />
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="outboundCallsAnswered" :sortable="true">
        <template #body="{ data }"> {{ data.outboundCallsAnswered }} </template>
      </Column>
      <Column field="outboundCallsTotal" :sortable="true">
        <template #body="{ data }"> {{ data.outboundCallsTotal }} </template>
      </Column>
      <Column field="inboundCallsAnswered" :sortable="true">
        <template #body="{ data }"> {{ data.inboundCallsAnswered }} </template>
      </Column>
      <Column field="inboundCallsTotal" :sortable="true">
        <template #body="{ data }"> {{ data.inboundCallsTotal }} </template>
      </Column>

      <Column field="outboundEmails" :sortable="true">
        <template #body="{ data }"> {{ data.outboundEmails }} </template>
      </Column>
      <Column field="inboundEmails" :sortable="true">
        <template #body="{ data }"> {{ data.inboundEmails }} </template>
      </Column>
      <Column field="outboundSms" :sortable="true">
        <template #body="{ data }"> {{ data.outboundSms }} </template>
      </Column>
      <Column field="inboundSms" :sortable="true">
        <template #body="{ data }"> {{ data.inboundSms }} </template>
      </Column>
      <Column field="totalCommunications" :sortable="true">
        <template #body="{ data }"> {{ data.totalCommunications }} </template>
      </Column>
      <!-- <Column field="booked" :sortable="true">
        <template #body="{ data }"> {{ data.booked }} </template>
      </Column> -->
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'
import alertToast from '@/utilities/alertToast'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import dayjs from 'dayjs'

const dt = ref()
const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
const engagementApiRequest = useApiRequest()
const engagementData = ref([])
const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  initFilters()
}

function fetchEngagementData() {
  engagementApiRequest
    .send({
      endpoint: 'v1/reports/engagement/summary',
      params: {
        start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
        end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
        dealerships: dealershipsAllowed.value.map(d => d.id).join(',')
      }
    })
    .then(response => {
      if (response.success) {
        engagementData.value = response.data
      } else {
        alertToast('Failed to fetch data', response.data?.message, 'error')
      }
    })
}
fetchEngagementData()

function onDateRangeChange() {
  if (dateRange.value[0] && dateRange.value[1]) {
    fetchEngagementData()
  }
}

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
