<template>
  <PageHeader title="CronJobs" breadcrumb :items="[{ label: $t('ui.entities.setting', 2), to: '/settings' }]"> </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="cronjobs"
      removable-sort
      sort-field="id"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-admin-cronjobs"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'name']"
      :loading="cronjobsApiRequest.isLoading.value"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <!-- <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div> -->
      </template>

      <Column field="id" header="ID" :sortable="true">
        <template #body="{ data }">
          {{ data.id }}
        </template>
      </Column>
      <Column field="name" header="Name" :sortable="true">
        <template #body="{ data }">
          {{ data.name }}
        </template>
      </Column>
      <Column field="interval" header="Interval" :sortable="true">
        <template #body="{ data }">
          <div class="flex">
            <div class="w-[80px]">{{ data.interval }}</div>
            <div class="text-quaternary">{{ data.intervalHuman }}</div>
          </div>
        </template>
      </Column>
      <Column field="lastRun" header="Last Run" :sortable="true">
        <template #body="{ data }">
          <span v-tippy="dayjs(data.lastRun).format('lll')">{{ dayjs(data.lastRun).from(dayjs()) }}</span>
        </template>
      </Column>
      <Column field="isActive" header="Status" :sortable="true">
        <template #body="{ data }">
          <Switch v-model="data.isActive" @change="updateStatus(data)" />
        </template>
      </Column>
      <Column field="errors" header="Errors" :sortable="true">
        <template #body="{ data }">
          {{ data.errors.length || null }}
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref } from 'vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Input from '@/components/forms/Input.vue'
import Switch from '@/components/forms/Switch.vue'
import TableBasic from '@/components/tables/TableBasic.vue'
import useApiRequest from '@/composables/useApiRequest'
import alertToast from '@/utilities/alertToast'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'

const cronjobs = ref([])
const cronjobsApiRequest = useApiRequest()

const filters = ref(null)

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.IN },
    missingInfo: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

const clearFilters = () => {
  initFilters()
}

cronjobsApiRequest
  .send({
    endpoint: '/v1/admin/cronjobs'
  })
  .then(response => {
    console.log(response)
    cronjobs.value = response.data
  })

const updateStatus = async job => {
  console.log('job')

  await useApiRequest()
    .send({
      endpoint: `/v1/admin/cronjobs/${job.id}`,
      method: 'PATCH',
      data: {
        isActive: job.isActive
      }
    })
    .then(response => {
      console.log(response)

      if (response.success) {
        alertToast('CronJob status updated', null, 'success')
      }
    })
}
</script>
