<template>
  <teleport to="#modals-container">
    <TaskHandler
      :key="sidebarKey"
      :is-open="taskHandlerIsOpen"
      :task="taskHandlerData"
      @close-task-handler="taskHandlerToggle()"
      @new-task-submit="newTaskSubmit"
      @update-task-submit="updateTaskSubmit"
    />
  </teleport>

  <Card class="h-full" full-height no-margin>
    <div class="todo-app h-full rounded-xl" style="min-height: calc(100vh - 150px)">
      <!-- Menu -->
      <div class="todo-app-left" :class="{ open: menuOpen }">
        <div class="p-5">
          <Button
            :label="$t('ui.actions.add_item', { item: $t('ui.entities.task.label') })"
            block
            icon="plus"
            @click="taskHandlerToggle()"
          />
        </div>
        <ul class="todo-app-tabs">
          <li v-for="tab in updatedTabs" :key="tab.name" class="todo-app-tab">
            <router-link :to="tab.route" class="capitalize flex flex-row items-center" @click="menuOpen = false">
              <Icon :type="tab.icon" />
              <div class="flex-1">{{ $t(tab.localeKey, 2) }}</div>
              <!-- <div class="text-sm">{{ tab.counter > 0 ? tab.counter : '' }}</div> -->
              <Chip v-if="tab.counter" size="sm">{{ tab.counter > 0 ? tab.counter : '' }}</Chip>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="todo-app-right">
        <div class="todo-app-search-wrapper dark:border-darkmode-2 flex items-center">
          <Icon type="bars" class="todo-app-sidebar-toggle-btn md:hidden mr-3 text-xl cursor-pointer" @click="toggleMenu" />
          <Icon type="search" class="mr-2" />
          <input
            v-model="searchTerm"
            type="text"
            :placeholder="$t('ui.actions.search_item', { item: _lowerCase($t('ui.entities.task.label', 2)) })"
            class="w-full bg-transparent"
          />

          <Dropdown text="Dropdown" position="bottom-right">
            <template #triggerContent>
              <Icon type="sort" />
            </template>
            <DropdownItem @item-clicked="changeSorting('titleAsc')"> Sort A-Z </DropdownItem>
            <DropdownItem @item-clicked="changeSorting('titleDesc')"> Sort Z-A </DropdownItem>
            <DropdownItem @item-clicked="changeSorting('dueDateAsc')"> Sort Due Date Asc </DropdownItem>
            <DropdownItem @item-clicked="changeSorting('dueDateDesc')"> Sort Due Date Desc </DropdownItem>
          </Dropdown>
        </div>
        <ul class="todo-app-content">
          <TaskListItem
            v-for="task in filteredList"
            :key="task.id"
            :task="task"
            @task-clicked="taskHandlerToggle"
            @update-task="onTaskListItemChange"
          />
        </ul>
      </div>
    </div>
    <div class="todo-app-menu-overlay" :class="{ open: menuOpen }" @click="toggleMenu"></div>
  </Card>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { computed, watch } from 'vue'

import { inject } from 'vue'

import { useUserStore } from '@/stores/UserStore'

import Button from '@/components/button/Button.vue'
import Card from '@/components/card/Card.vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'

import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import TaskHandler from '@/components/to-do/TaskHandler.vue'
import { useAbility } from '@casl/vue'
import alertToast from '@/utilities/alertToast'
import useApiRequest from '@/composables/useApiRequest'

import TaskListItem from '@/components/to-do/TaskListItem.vue'

import _lowerCase from 'lodash/lowerCase'

export default {
  name: 'ToDo',
  components: {
    Button,
    Card,
    Icon,
    Chip,
    Dropdown,
    DropdownItem,
    TaskListItem,
    TaskHandler
  },
  setup() {
    const route = useRoute()
    const userStore = useUserStore()
    const currentTab = ref(route.params.tab)
    const searchTerm = ref('')
    const sorting = ref(route.query.sort || 'dueDateAsc')
    const menuOpen = ref(false)
    const todosTotalCounter = ref(0)
    const { can } = useAbility()
    const todosApiRequest = useApiRequest()

    const sidebarKey = ref(Math.random())

    const emitter = inject('emitter')

    const tabs = ref([
      {
        name: 'My Tasks',
        icon: 'home',
        route: '/tasks/to-do',
        isActive: false,
        localeKey: 'ui.entities.task.my-task'
      },
      {
        name: 'Important',
        icon: 'star',
        route: '/tasks/to-do/important',
        isActive: false,
        localeKey: 'ui.entities.task.important'
      },
      {
        name: 'Reminders',
        icon: 'bell',
        route: '/tasks/to-do/reminders',
        isActive: false,
        localeKey: 'ui.entities.task.reminder'
      },
      {
        name: 'Assigned to Me',
        icon: 'circle-down',
        route: '/tasks/to-do/assigned',
        isActive: false,
        localeKey: 'ui.entities.task.assigned-to-me'
      },
      {
        name: 'Assigned by Me',
        icon: 'circle-right',
        route: '/tasks/to-do/assigned-by-me',
        isActive: false,
        localeKey: 'ui.entities.task.assigned-by-me'
      },
      {
        name: 'Completed',
        icon: 'check',
        route: '/tasks/to-do/completed',
        isActive: false,
        localeKey: 'ui.entities.task.completed'
      },
      {
        name: 'Deleted',
        icon: 'trash',
        route: '/tasks/to-do/deleted',
        isActive: false,
        localeKey: 'ui.entities.task.deleted'
      }
    ])

    // console.log(tabs.value)

    const tasks = ref([])

    const getTodos = async () => {
      try {
        todosApiRequest.send({ method: 'GET', endpoint: '/v1/todos?userID=' + userStore.details.id }).then(response => {
          console.log(response.data)

          tasks.value = response.data
        })
      } catch (err) {
        console.log(err.message)
        alertToast('Failed to get To-Dos', err.message, 'error')
      }
    }
    getTodos()

    watch(
      () => todosTotalCounter.value,
      () => {
        // console.log('totals changed: ' + todosTotalCounter.value)
        emitter.emit('counter-todos-total', todosTotalCounter.value)
      }
    )

    function changeSorting(value) {
      sorting.value = value
    }

    watch(
      () => route.params.tab,
      (currentRoute, newRoute) => {
        menuOpen.value = false
        if (currentRoute != newRoute) {
          if (route.params.tab === '') {
            currentTab.value = 'all'
          } else {
            currentTab.value = route.params.tab
          }
          // console.log('Current Tab: ' + currentTab.value);
        }
      },
      {
        immediate: true
      }
    )

    const filteredList = computed(() => {
      const searchTermLower = _lowerCase(searchTerm.value)

      // Common filter function
      const commonFilter = task =>
        _lowerCase(task.title).includes(searchTermLower) ||
        _lowerCase(task.linkTitle).includes(searchTermLower) ||
        _lowerCase(task.description).includes(searchTermLower)

      // Tab specific filter conditions
      const tabConditions = {
        all: task => !task.isDeleted && !task.isCompleted && !task.isReminder && task.assignee == task.createdBy,
        important: task => !task.isDeleted && !task.isCompleted && task.isStarred && task.assignee == task.createdBy,
        assigned: task => !task.isDeleted && !task.isCompleted && task.assignee != task.createdBy && task.createdBy != userStore.details.id,
        'assigned-by-me': task =>
          !task.isDeleted && !task.isCompleted && task.assignee != task.createdBy && task.createdBy == userStore.details.id,
        reminders: task => !task.isDeleted && !task.isCompleted && task.isReminder && task.assignee == userStore.details.id,
        completed: task => !task.isDeleted && task.isCompleted,
        deleted: task => task.isDeleted
      }

      // Filter tasks based on current tab and common filter
      const filtered = tasks.value.filter(task => tabConditions[currentTab.value](task) && commonFilter(task))

      // Sorting logic
      const sortMapping = {
        titleDesc: (a, b) => (a.title < b.title ? 1 : -1),
        titleAsc: (a, b) => (a.title > b.title ? 1 : -1),
        dueDateDesc: (a, b) => (a.dueDate < b.dueDate ? 1 : -1),
        dueDateAsc: (a, b) => (a.dueDate > b.dueDate ? 1 : -1)
      }

      if (sortMapping[sorting.value]) {
        filtered.sort(sortMapping[sorting.value])
      }

      return filtered
    })

    const updatedTabs = computed(() => {
      const filtered = ref([])
      let totalsCounter = 0
      tabs.value.forEach(item => {
        if (item.name === 'My Tasks') {
          let count = tasks.value.filter(
            task => !task.isDeleted && !task.isCompleted && !task.isReminder && task.assignee == task.createdBy
          ).length
          let tabIndex = tabs.value.findIndex(tab => tab.name === 'My Tasks')
          tabs.value[tabIndex].counter = count
          totalsCounter = totalsCounter + count
        } else if (item.name === 'Important') {
          let count = tasks.value.filter(
            task => !task.isDeleted && !task.isCompleted && task.isStarred && task.assignee == task.createdBy
          ).length
          let tabIndex = tabs.value.findIndex(tab => tab.name === 'Important')
          tabs.value[tabIndex].counter = count
        } else if (item.name === 'Reminders') {
          let count = tasks.value.filter(
            task => !task.isDeleted && !task.isCompleted && task.isReminder && task.assignee == userStore.details.id
          ).length
          let tabIndex = tabs.value.findIndex(tab => tab.name === 'Reminders')
          tabs.value[tabIndex].counter = count
          totalsCounter = totalsCounter + count
        } else if (item.name === 'Assigned to Me') {
          let count = tasks.value.filter(
            task => !task.isDeleted && !task.isCompleted && task.assignee != task.createdBy && task.createdBy != userStore.details.id
          ).length
          let tabIndex = tabs.value.findIndex(tab => tab.name === 'Assigned to Me')
          tabs.value[tabIndex].counter = count
          totalsCounter = totalsCounter + count
        } else if (item.name === 'Assigned by Me') {
          // console.log('Can assign tasks: ' + can('assign_tasks'))
          if (can('assign_tasks')) {
            let count = tasks.value.filter(
              task => !task.isDeleted && !task.isCompleted && task.assignee != task.createdBy && task.createdBy == userStore.details.id
            ).length
            let tabIndex = tabs.value.findIndex(tab => tab.name === 'Assigned by Me')
            tabs.value[tabIndex].counter = count
            totalsCounter = totalsCounter + count
            item.isVisible = true
          } else {
            item.isVisible = false
          }
        }
        todosTotalCounter.value = totalsCounter
      })

      //Filter out any that have isVisible=false
      filtered.value = tabs.value.filter(tab => tab.isVisible != false)

      return filtered.value
    })

    //Toggle menu logic
    function toggleMenu() {
      menuOpen.value = !menuOpen.value
      // console.log('menu open: ' + menuOpen.value)
    }

    // Task Handler sidebar
    const taskHandlerIsOpen = ref(false)
    const taskHandlerData = ref()

    function taskHandlerToggle(task) {
      // console.log('TODO: ')
      // console.log(task)
      sidebarKey.value = Math.random() // Change key so sidebar will re-mount
      // console.log('DATA from Todo.vue ' + JSON.stringify(task))
      taskHandlerIsOpen.value = !taskHandlerIsOpen.value

      if (task) {
        //If update task
        taskHandlerData.value = task
      } else {
        // If New task
        taskHandlerData.value = null
      }
    }
    function newTaskSubmit(data) {
      // console.log('New Task to be added!', data)
      tasks.value.push(data)
      taskHandlerToggle()
    }
    function updateTaskSubmit(data) {
      // Update task in dom with new data
      const taskIndex = tasks.value.findIndex(x => x.id === data.id)
      tasks.value[taskIndex] = data
      taskHandlerToggle()
    }

    function onTaskListItemChange(task) {
      const taskIndex = tasks.value.findIndex(x => x.id === task.id)
      tasks.value[taskIndex] = task
    }

    return {
      currentTab,
      updatedTabs,
      filteredList,
      searchTerm,
      changeSorting,
      toggleMenu,
      menuOpen,
      todosTotalCounter,
      taskHandlerIsOpen,
      taskHandlerToggle,
      newTaskSubmit,
      taskHandlerData,
      updateTaskSubmit,
      sidebarKey,
      _lowerCase,
      onTaskListItemChange
    }
  }
}
</script>

<style>
.todo-app {
  display: flex;
  overflow: hidden;
  position: relative;
}

.todo-app-left {
  width: 230px;
  flex-shrink: 0;
  border-right: 1px solid var(--border-secondary);
  z-index: 1;
  transition: left 0.3s ease-in-out;
  border-radius: 0.428rem 0 0 0.428rem;
  min-height: 100%;
  background-color: var(--bg-primary_alt);
}
.todo-app-sidebar-toggle-btn {
  display: none;
}

.mobile .todo-app-left {
  position: absolute;
  left: -231px;
}
.mobile .todo-app-sidebar-toggle-btn {
  display: block;
}

.mobile .todo-app-left.open {
  left: 0;
}

.todo-app-tab > a {
  padding: 10px;
  /* display: block; */
  border-left: 3px solid transparent;
  font-weight: 500;
}

.todo-app-tab > a:hover {
  color: var(--fg-brand);
}

.todo-app-tab .icon {
  margin-left: 5px;
  margin-right: 10px;
}

.todo-app-tab > a.router-link-exact-active {
  border-left-color: var(--fg-brand);
  color: var(--text-brand);
}

.todo-app-menu-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0.428rem;
  background-color: #00000033;
  visibility: hidden;
  opacity: 0;

  transition: all 0.3s ease-in-out;
}

.todo-app-menu-overlay.open {
  visibility: visible;
  opacity: 1;
}

.todo-app-right {
  width: 100%;
}

.todo-app-search-wrapper {
  display: flex;
  /* background: lightblue; */
  padding: 13px 18px;
  border-bottom: 1px solid var(--border-secondary);
  /* transition: all 0.3s ease-in-out; */
}
</style>
