<template>
  <div v-if="design == 'alert'" class="flex gap-4 items-center">
    <div class="p-2">
      <Icon :type="icon" :severity="severity" featured />
    </div>
    <div class="flex flex-col">
      <div class="flex gap-2">
        <div class="text-md font-semibold text-fg-primary">{{ title }}</div>
      </div>
      <div class="text-sm text-fg-secondary">{{ text }}</div>
    </div>
  </div>

  <div v-if="design == 'info'" class="flex gap-4">
    <Avatar :url="avatarUrl" :text="avatarText" :icon="icon" :color="severity" />
    <div class="flex flex-col">
      <div class="flex gap-2">
        <div class="text-sm font-semibold text-fg-primary">{{ title }}</div>
      </div>
      <div class="text-sm text-fg-secondary">{{ text }}</div>
      <div v-if="showDismissButton || showActionButton" class="flex gap-2 mt-2">
        <Button v-if="showDismissButton" size="sm" label="Dismiss" text severity="secondary" @click.stop="dismissToast" />
        <Button v-if="showActionButton" size="sm" :label="actionButtonText" text severity="primary" @click.stop="viewNotification" />
      </div>
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/icon/Icon.vue'
import Avatar from '../avatar/Avatar.vue'
import Button from '../button/Button.vue'

const props = defineProps({
  design: {
    type: String,
    default: 'info' // alert, info
  },
  title: String,
  text: String,
  icon: {
    type: String,
    default: 'question-mark'
  },
  severity: {
    type: String,
    default: null
  },
  avatarUrl: {
    type: String,
    default: null
  },
  avatarText: {
    type: String,
    default: null
  },
  showDismissButton: {
    type: Boolean,
    default: true
  },
  showCloseButton: {
    type: Boolean,
    default: true
  },
  showActionButton: {
    type: Boolean,
    default: false
  },
  actionButtonText: {
    type: String,
    default: 'View'
  }
})

const emit = defineEmits(['dismissBtnClick', 'viewBtnClick'])

const dismissToast = () => {
  emit('dismissBtnClick')
  console.log('dismissing')
}

const viewNotification = () => {
  emit('viewBtnClick')
  console.log('viewing')
}
</script>

<style>
.Vue-Toastification__toast--default {
  align-items: start;
}
.Vue-Toastification__progress-bar {
  background-color: var(--bg-quaternary);
}
.notification-toast_icon {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: var(--bg-brand-solid);
}
</style>
