// TODO: Add jsdoc comments
// TODO: stop axios from catching 500 errors, as we want the invoking parent to catch them and handle
import { reactive, toRefs } from 'vue'
import axios from 'axios'
import alertToast from '@/utilities/alertToast'
import * as Sentry from '@sentry/vue'

export default function useApiRequest() {
  const state = reactive({
    response: null, // The full Axios response
    data: null, // The simplified data payload
    isLoading: false,
    error: null
  })

  const send = async ({
    baseURL = import.meta.env.VITE_API_BASE_URL,
    endpoint,
    method = 'GET',
    params = {},
    data = null,
    headers = {}
  }) => {
    if (!baseURL) {
      console.error(`Error: baseURL is undefined. Endpoint: ${endpoint}`)
      alertToast('Error', `baseURL is undefined for endpoint: ${endpoint}`, 'error')
      state.error = `baseURL is undefined for endpoint: ${endpoint}`
      return Promise.reject({ error: true, message: state.error })
    }

    state.isLoading = true

    try {
      const response = await axios({
        baseURL,
        url: endpoint,
        method,
        params,
        data,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // TODO: remove this temp fix for safari browsers not liking httpOnly cookies
          ...headers
        }
      })

      if (!response || !response.status) {
        state.error = 'Endpoint failed'
        throw new Error(`No response from API, endpoint failed: ${endpoint}`)
      }

      if (response.status !== 200) {
        if (response.status === 401) {
          state.error = '401 Unauthorized'
          throw new Error(state.error)
        } else {
          state.error = 'Error fetching data'
          throw new Error(state.error)
        }
      }

      if (response.data?.response?.status === 'error') {
        state.error = response.data.response.status
        alertToast('Error', response.data.response.message || response.data.message, 'error')
        throw new Error(state.error)
      }

      if (response.data.errors && response.data.errors.length > 0) {
        state.error = response.data.errors.join(', ')
        alertToast('Error', state.error, 'error')
        throw new Error(state.error)
      }

      // Store the entire response object
      state.response = response

      // Simplify the data payload
      state.data = response.data.data || response.data

      state.error = null

      // Handle old api response format
      let success = false
      if (response.data?.response?.status === 'success') {
        success = true
      } else {
        success = state.response.success
      }

      // Return both data and the full response
      return {
        data: state.data, // Actual payload's data key
        success: success,
        errors: state.response.errors,
        message: state.response.message,
        status: state.response.status,
        http: state.response
      }
    } catch (err) {
      alertToast('Error (API Request)', err.message, 'error')
      Sentry.captureException(err)
      state.error = err.message

      return Promise.reject({
        hasError: true,
        message: err.message,
        http: err.response || null
      })
    } finally {
      state.isLoading = false
    }
  }

  const hasErrors = () => !!state.error

  return {
    ...toRefs(state),
    send,
    hasErrors
  }
}
